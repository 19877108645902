import React, { useLayoutEffect, useState, useEffect } from "react";
import { StyleSheet, SafeAreaView, ScrollView, View, Modal, TouchableOpacity, Text, Dimensions} from "react-native";
import * as Colors from '../styles/Colors';
import {Searcher} from '../components/searcher.component';
import {Member} from '../components/member.component';
import {Loader} from '../components/loader.component';
import {Toast} from '../components/toast.component';
//import {ModalUsersGuests} from '../components/modalUsersGuests.component';
//import ActionSheet, { SheetManager } from "react-native-actions-sheet";
//import Ionicons from "react-native-vector-icons/Ionicons";
import {getMembersOfChannel, deleteMemberFromChannel, createPrivateChat} from '../services/data.service';
import {getChatDataFromId} from '../services/firestore.service';

import {translateTypeUser} from '../utils/translateTypeUser';
import {useParams, Link, useNavigate} from 'react-router-dom';
import typeHeaderStore from "../store/typeHeaderStore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ReactComponent as ButtonMenu } from '../assets/buttonMenu.svg';
import Trash from '../assets/trashGrey.svg';
import { normalizeString } from '../utils/formatters';

const {width} = Dimensions.get('window');

const MembersScreen = props => {
    const [user, setUser] = useState(null);
    const [allMembers, setAllMembers] = useState(null);
    const [members, setMembers] = useState(null);
    const [modalGuests, setModalGuests] = useState(false);
    const [search, setSearch] = useState('');
    const [toastNoChat, setToastNoChat] = useState(false);
    const [visibleMenuId, setVisibleMenuId] = useState(null);

    const setTypeMenu = typeHeaderStore(state => state.setTypeHeader);
    const openMenu = typeHeaderStore(state => state.openMenu);
    const setOpenMenu = typeHeaderStore(state => state.setOpenMenu);

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setOpenMenu(false);
        setToastNoChat(false);
    }, [toastNoChat])

    /*Al entrar a la vista*/
    useEffect(() => {
        /*Indica el tipo de cabecera que va a tener ka vista*/
        setTypeMenu('menu');

        /*LLama para recoger al usuario*/
        getUser();

        /*Recoge todos los miebros de un grupo*/
        getMembersOfChannel(id).then(res => {
            setAllMembers(res.data.users);
            setMembers(res.data.users);
        }).catch(err => {
            console.log('ERROR al recoger los miembros de un canal', err);
        })
    }, []);

    /*Recoge el usuario*/
    const getUser = () => {
        AsyncStorage.getItem('user').then(user => {
            setUser(JSON.parse(user));
        })
    }

    /*Filtra los usuarios*/
    const searchUsers = (value) => {
        if (value !== '') {
            let newUsers = members.filter(member => {
                console.log(member)
                if (normalizeString(member.full_name).includes(normalizeString(value))) return member;
            })
            if (newUsers.length > 0) setMembers(newUsers);
            else setMembers(allMembers);
        }
        else {
            setMembers(allMembers);
        }
    };

    const handleShowMenuButton = (memberId) => {
        setVisibleMenuId(visibleMenuId === memberId ? null : memberId);
    };

    const handleDeletePress = (memberId) => {
      if (window.confirm("¿Desea eliminar este miembro?")) {
        const idInt = parseInt(id, 10);

        deleteMemberFromChannel(idInt, memberId)
          .then((res) => {
            console.log("Usuario con id: ", memberId, " eliminado");
            const updatedMembers = members.filter(member => member.id !== memberId);
            setMembers(updatedMembers);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    return(
        <SafeAreaView style={styles.safeArea}>
            {(openMenu) &&
                (user?.user.user_type === 'doctor' &&
                    <div style={styles.dropdown}>
                        <div style={styles.optionMenu} onClick={() => navigate(`/addMembers/${id}`)}>Invitar miembros</div>
                    </div>
                )
            }
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    <Searcher
                        placeholder={'Busca miembros'}
                        value={search}
                        getValue={(value) => {
                            setSearch(value);
                            searchUsers(value);
                        }}
                    />
                    <View style={styles.containerMembers}>
                        {members !== null ? (
                            members.length > 0 ? (
                                members.map(member => (
                                    <View key={member.id} style={{ width: '90%', display: 'flex', flexDirection: "row", textDecoration: 'none' }}>
                                        {/* <Link style={{ width: '90%', display: 'flex', justifyContent: 'center', textDecoration: 'none' }} to={`/chatMember/${member.conversation}/CN/${member.id}`}> */}
                                            <Member
                                                image={member.image}
                                                name={member.name + ' ' + member.lastname}
                                                type={translateTypeUser(member.user_type)}
                                                time={member.joined}
                                                pressAll={async () => {
                                                    const data = await createPrivateChat(member.id);
                                                    const chatData = await getChatDataFromId(data.data.id);
                                                    if(chatData){
                                                        navigate(`/chats/${chatData.dbChatId}/${chatData.type}`, {
                                                            state: { chatData: chatData },
                                                          });
                                                    }
                                                }}
                                            />
                                        {/* </Link> */}
                                        {user?.user.user_type === 'doctor' && 
                                        <TouchableOpacity style={{alignSelf: "center", width: 25, height: 25, marginLeft: 5}} onPress={() => handleDeletePress(member.id)}>
                                            <img alt="delete" src={Trash}/>
                                        </TouchableOpacity>}
                                        {/*<ButtonMenu style={{ borderBottomWidth: 1, borderBottomColor: '#e1e1e1' }} width={20} color={Colors.default.COLORS.text_dark} onClick={() => handleShowMenuButton(member.id)} />*/}
                                        {/*visibleMenuId === member.id && (
                                            <View style={styles.div}>
                                                <Text>Contenido del Div</Text>
                                            </View>
                                        )*/}
                                    </View>
                                ))
                            ) : (
                                <Text>No existen miembros en este canal</Text>
                            )
                        ) : (
                            <Loader style={{flex: 1, height: 500}}/>
                        )}
                    </View>

                </View>
            </ScrollView>
            <Toast message={'Este usuario no permite mensajes privados.'} visible={toastNoChat}/>
        </SafeAreaView>
    )
}

export default MembersScreen;

const styles = StyleSheet.create({
    anchorButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        paddingBottom: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    containerMembers: {
        alignItems: 'center',
        width: '100%',
        marginBottom: 30
    },
    containerActionSheet: {
        width: '100%',
        padding: 20,
        paddingBottom: 0
    },
    buttonAddMembers: {
        width: '100%',
        padding: 20
    },
    textAddMembers: {
        color: Colors.default.COLORS.primary
    },
    dropdown: {
        position: 'absolute',
        zIndex: 9,
        right: '3%',
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: Colors.default.COLORS.background_light,
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
    },
    optionMenu: {
        color: Colors.default.COLORS.primary,
        marginTop: 15,
        marginBottom: 15,
        cursor: "pointer",
    },
    div: {
        position: 'absolute',
        zIndex: 10,
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 4,
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
});
