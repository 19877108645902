import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import { Formik, useField } from "formik";
import {Input} from './input.component';

export const FormikInput = ({name, ...props}) => {
    const [field, meta, helpers] = useField(name);

    return(
        <>
            <Input
                keyboardType={props.keyboardType}
                value={field.value}
                getValue={(value) => helpers.setValue(value)}
                {...props}
            />
        </>
    )
}

const styles = StyleSheet.create({
    containerError: {
        width: '90%'
    },
    error: {
        marginRight: 'auto',
        color: 'red'
    }
})
