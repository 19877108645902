import create from 'zustand';

/*Se utiliza para actualizar si el usuario entra a la aplicacion o se queda en el login*/
const loginStore = create((set, get) => ({
  /*Se inicializa el valo del store*/
  login: false,
  /*Se actualiza el store*/
  setLogin: login => set(state => ({login: login})),
}));

export default loginStore;
