import create from 'zustand';

const courseStore = create((set, get) => ({
  /*Se inicializa el valo del store*/
  workingCourse: 0,
  /*Se actualiza el store*/
  setWorkingCourse: course => set(state => ({workingCourse: course})),
}));

export default courseStore;
