import React, { useEffect, useLayoutEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View, TouchableOpacity, Text, Image, Alert, Dimensions} from "react-native";
import {sendFinalizedTask} from '../services/data.service';
import { Button } from '../components/button.component';
import { getLesson } from '../services/data.service';
import {ReactComponent as Start} from '../assets/startOutline.svg';
//import Back from '../assets/previous_arrow_blue.svg';
//import Next from '../assets/next_arrow_white.svg';
//import Ionicons from "react-native-vector-icons/Ionicons";
import * as Colors from "../styles/Colors";
import {useParams, Link, useNavigate} from 'react-router-dom';

const {width} = Dimensions.get('window');

const LessonScreen = () => {
    const [lesson, setLesson] = useState(null);
    const [quantityCompleted, setQuantityCompleted] = useState(0);
    const [completed, setCompleted] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate();

    /*LLama a la funcion al entrar en la vista*/
    useEffect(() => {
        getLessons();
    }, []);

    /*Recoge una leccion por id*/
    const getLessons = () => {
        getLesson(id).then(res => {
            console.log('res', res);
            setLesson(res);
            /*Recorre los contenidos de la leccion y guarda la cantidad de los contenido que estan finalizados*/
            res.contents.map(lesson => {
                if (lesson.completed === true) {
                    setQuantityCompleted(quantityCompleted + 1);
                }
            })
        }).catch(err => {
            console.log('ERROR al recoger la leccion', err);
        })
    }

    /*Finaliza una leccion*/
    const sendTaskProgress = () => {
        sendFinalizedTask(null, id, null).then(res => {
            setCompleted(true)
            alert('Bloque finalizado con exito.')
            console.log('respuesta enviada con exito', res);
        }). catch(err => {
            console.log('ERROR al enviar la respuesta', err);
        })
    }

    /*Muestra un alert de confirmacion para finalizar la leccion*/
    const confirmEndContent = () => {
        if (window.confirm("¿Desea finalizar la lección?")) {
            sendTaskProgress();
        } else {
            console.log('No has marcado la leccion como finalizada')
        }
    }

    return(
        lesson !== null &&
        <SafeAreaView style={styles.safeArea}>
            <ScrollView>
                <View style={styles.container}>
                    {lesson.image.url !== null && <Image style={{width: "100%", height: 170}} source={{uri: `https://admin.growpsy.com/${lesson.image.url}`}}/>}
                    <View style={{flexDirection: 'row', alignItems: 'center', width: '90%', marginTop:30, marginBottom: 20}}>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => navigate(`/course/${lesson.course_id}`)}>
                            <Text style={{fontWeight: 'bold'}}>{lesson.course_name} </Text>
                        </TouchableOpacity>
                         <Text>/</Text>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => navigate(`/course/${lesson.course_id}`)}>
                            <Text style={{fontWeight: 'bold'}}> {lesson.block_name} </Text>
                        </TouchableOpacity>
                         <Text>/</Text>
                        <TouchableOpacity activeOpacity={0.7}>
                            <Text style={{fontWeight: 'bold'}}> {lesson.name} </Text>
                        </TouchableOpacity>
                    </View>

                    <View style={{width: '90%', marginVertical: 20}}>
                        <Text>{lesson.description}</Text>
                    </View>
                    {lesson.contents.map((content, index) => {
                        return (
                            <Link style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', textDecoration: 'none'}} to={`/content/${content.id}`}>
                                <TouchableOpacity
                                    style={content.completed === true ? styles.containerContentCompleted : styles.containerContent}
                                    activeOpacity={0.7}>
                                    <Text style={{fontFamily: 'Montserrat', fontWeight: 600}}>{content.name}</Text>
                                    <Start width={30}/>
                                </TouchableOpacity>
                            </Link>
                        );
                    })}

                    {(lesson !== null && !lesson.completed && quantityCompleted < lesson.contents.length && completed === false) &&
                        <Button
                            style={styles.button}
                            text={"Marcar como finalizado"}
                            primary
                            press={() => confirmEndContent()}
                        />
                    }
                </View>

            </ScrollView>
        </SafeAreaView>
    )
}

export default LessonScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 30,
        backgroundColor: Colors.default.COLORS.background_light,
        position: 'relative'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginVertical: 20
    },
    containerContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginBottom: 15,
        paddingVertical: 12,
        paddingHorizontal: 15,
        borderRadius: 6,
        backgroundColor: Colors.default.COLORS.background_lightDark
    },
    containerContentCompleted: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginBottom: 15,
        paddingVertical: 12,
        paddingHorizontal: 15,
        borderRadius: 6,
        backgroundColor: Colors.default.COLORS.primary
    },
    button: {
        width: '90%',
        marginTop: 20
    },
    bottomButtonsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        width: '100%',
        padding: 10
    }
})
