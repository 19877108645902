import React, {useEffect, useState} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Dimensions, Text} from 'react-native';
import * as Colors from '../styles/Colors';
import {Searcher} from '../components/searcher.component';
import {Channel} from '../components/channel.component';
import {Loader} from '../components/loader.component';
import {getChannels} from '../services/data.service';
import {Link} from 'react-router-dom';
import { normalizeString } from '../utils/formatters';

const {width} = Dimensions.get('window');

const ChannelsScreen = props => {
    const [allChannels, setAllChannels] = useState(null);
    const [channels, setChannels] = useState(null);
    const [search, setSearch] = useState('');

    /*Recoge los grupos al entrara a la vista*/
    useEffect(() => {
        getChannels().then(res => {
            setChannels(res.data);
            setAllChannels(res.data);
        }).catch(err => {
            console.log('ERROR al recoger los canales', err);
        })
    }, []);

    /*Filtra los grupos*/
    const searchChannels = (value) => {
        if (value !== '') {
            let newChannels = channels.filter(channel => {
                if (normalizeString(channel.name).includes(normalizeString(value))) return channel;
            })

            if (newChannels.length > 0) setChannels(newChannels);
            else setChannels(allChannels);
        }
        else {
            setChannels(allChannels);
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>

                    {channels === null ?
                        <Loader style={{flex: 1, height: 500}}/>
                        :
                        <>
                            <Searcher
                                placeholder={'Busca tu grupo'}
                                value={search}
                                getValue={async (value) => {
                                    await setSearch(value);
                                    await searchChannels(value);
                                }}
                            />
                            {
                                channels.map(channel => {
                                    return(
                                        <Link style={{display: 'flex', justifyContent: 'center', width: '90%', textDecoration: 'none'}} to={`/channels/${channel.id}`}>
                                            <Channel title={channel.name} color={channel.color} /*press={() => props.navigation.navigate('ChannelOptions', {idChannel: channel.id, title: channel.name, channels: channel.channels})}*//>
                                        </Link>
                                    )
                                })
                            }
                        </>
                    }


                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default ChannelsScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    containerTypes: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    type: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
        borderBottomWidth: 1,
        borderBottomColor: Colors.default.COLORS.primary
    },
    typeNoSelect: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
    },
    typeText: {
        color: Colors.default.COLORS.primary
    },
    typeTextNoSelect: {
        color: Colors.default.COLORS.text_darkLight
    }
})
