import React, { useEffect, useState } from "react";
import { StyleSheet, SafeAreaView, ScrollView, View, Image, Text, Dimensions} from "react-native";
import {Input} from '../../components/input.component';
import {Button} from '../../components/button.component';
import {Toast} from '../../components/toast.component';
import {rememberPassword} from '../../services/user.service';
import * as Colors from '../../styles/Colors';
import {useNavigate} from "react-router-dom";

const {width} = Dimensions.get('window');

const RememberPasswordScreen = props => {
    const [email, setEmail] = useState('');
    const [send, setSend] = useState(false);
    const [toast, setToast] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setToast(false);
    }, [toast]);

    //Envia el correo para recuperar la contraseña
    const sendMail = () => {
        rememberPassword(email).then(res => {
            setSend(true);
        }).catch(err => {
            setToast(true)
            console.log('ERROR al recuperar la contraseña', err);
        })
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    <View style={styles.containerImage}>
                        <Image style={styles.image} source={require('../../assets/growpsyApaisado.png')}/>
                    </View>
                    { send === false ?
                        <>
                            <Text style={styles.text}>Introduce tu correo y recibiras indicaciones para conseguir una contraseña nueva.</Text>
                            <Input style={styles.input} title={'Email'} value={email} getValue={(value) => setEmail(value)}/>
                            <Button style={styles.button} text={'Recuperar contraseña'} primary={true} press={() => sendMail()}/>
                        </>
                        :
                        <>
                            <Text style={styles.text}>Revisa la bandeja de entrada de tu correo, en él estarán las instrucciones para conseguir tu contraseña.</Text>
                            <Text style={[styles.text, {marginTop: 7}]}>Si no encuentras el correo revisa tu bandeja de spam.</Text>
                            <Button style={[styles.button, styles.buttonBack]} text={'Volver a iniciar sesion'} primary={true} press={() => navigate('Login')}/>
                        </>
                    }

                </View>
            </ScrollView>
            <Toast message={'Dirección de correo incorrecta.'} visible={toast}/>
        </SafeAreaView>
    )
}

export default RememberPasswordScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',

    },
    containerImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 90,
        marginBottom: 30
    },
    image: {
        width: width <= 420 ? '90%' : (width <= 820 ? '50%' : '30%'),
        height: 80
    },
    text: {
        width: '90%'
    },
    input: {
        marginVertical: 30
    },
    button: {
        width: '90%'
    },
    buttonBack: {
        marginTop: 30
    }
})
