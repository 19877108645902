import React, { useEffect, useState } from "react";
import { StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity, Text, Image, Alert, Dimensions } from "react-native";
import * as Colors from '../styles/Colors';
import {Button} from '../components/button.component';
import userStore from '../store/userStore';
//import { InAppBrowser } from "react-native-inappbrowser-reborn";
//import * as RNIap from 'react-native-iap';
import { saveBuy } from "../services/data.service";
import {getUser} from '../services/user.service';
import {Toast} from '../components/toast.component';
import {buyStripe, rejectSubscription} from '../services/data.service';
//import InAppPurchase from "@mu29/react-native-in-app-purchase";

const {width} = Dimensions.get('window');

const MySubscriptionsScreen = () => {
    const [userActive, setUserActive] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [isBuy, setIsBuy] = useState(false);
    const [toastBuy, setToastBuy] = useState(false);
    const user = userStore(state => state.user);
    const setUser = userStore(state => state.setUser);
    const subscriptionItems = [
        'growpsy_monthly'
    ];

    /*Al entrar a la vista llama a la funcion para rrecoger el usuario*/
    useEffect(() => {
        requestUser();
    }, []);

    useEffect(() => {
        setToastBuy(false)
    }, [toastBuy]);

    /*Recoge el usuario*/
    const requestUser = () => {
        getUser().then(res => {
            setUser(res.data);
            setUserActive(res.data);
        }).catch(err => {
            console.log('ERROR AL RECOGER EL USUARIO', err);
        })
    }

    /*Compra la suscripción*/
    const buySubscription = () => {
        /*LLama al endpoint con el tipo de suscripcion que se va a comprar para que devuelva la url en la que se va a comprar la suscripcion*/
        buyStripe('growpsy_monthly', null).then(res => {
            /*Navega a la url en la que se va a comrpar la suscripción*/
            window.location.href = res.data.payment_link;
            console.log('Suscripcion realizada con exito', res);
        }).catch(err => {
            console.log('Error al realizar la suscripcion', err);
        })
    }

    /*Elimina la suscripcion*/
    const removeSubscription = () => {
        /*Muestra un alert para confirmar si quieres eliminar la suscripción*/
        if (window.confirm("¿Desea eliminar la suscripción?")) {
            /*LLama al endpoint para eliminar la suscripcion*/
            rejectSubscription().then(res => {
                /*Recoge el usuario*/
                requestUser();
                console.log('Suscripcion cancelada con exito', res);
            }).catch(err => {
                console.log('Error al cancelar la suscripcion', err);
            })
        } else {
            console.log('No has marcado la leccion como finalizada')
        }
    }

    return(
        userActive !== null &&
        <SafeAreaView style={styles.safeArea}>
            <ScrollView>
                <View style={styles.container}>
                    <Image style={{width: '90%', height: 120}} source={require('../assets/growpsyApaisado.png')} resizeMode={'contain'}/>
                    <Text style={[styles.text, {fontWeight: 'bold', fontSize: 20, marginBottom: 15}]}>Suscríbete a Growpsy</Text>
                    <Text style={styles.text}>Consigue acceso a todos los grupos y cursos dentro de la aplicación.</Text>

                    <View style={styles.containerTypeSubscription}>
                        <Text style={{fontFamily: 'Montserrat', fontWeight: 'bold'}}>Suscripción mesual</Text>
                    </View>

                    <View style={styles.containerPurchase}>
                        <View style={styles.containerPrice}>
                            {userActive.user.membership_id === 'growpsy_free' && <Text style={styles.price}>0€</Text>}
                            {(userActive.user.membership_id === 'growpsy_monthly' || userActive.user.membership_id === 'free') && <Text style={styles.price}>12,99€</Text>}
                            <Text style={styles.perTime}>/ Mes</Text>
                        </View>

                        {userActive.user.membership_id === 'free' && <Button style={styles.buttonSubscription} text={"Suscribirme"} primary press={() => buySubscription()} />}
                        {(userActive.user.membership_id === 'growpsy_monthly' || userActive.user.membership_id === 'growpsy_free') &&
                        <TouchableOpacity style={styles.buttonSubscribed}>
                            <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Suscrito</Text>
                        </TouchableOpacity>
                        }
                    </View>

                    {(userActive.user.membership_id === 'growpsy_monthly' || userActive.user.membership_id === 'growpsy_free') &&
                        <Button style={styles.buttonRestore} text={'Eliminar suscripción'} press={() => removeSubscription()}/>
                    }

                    <View style={styles.containerPolicy}>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => window.open('https://www.growpsy.com/condiciones-de-uso/')}>
                            <Text style={styles.policy}>Términos de uso</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => window.open('https://www.growpsy.com/politica-de-privacidad%ef%bf%bc/')}>
                            <Text style={styles.policy}>Políticas de privacidad</Text>
                        </TouchableOpacity>
                    </View>

                    {userActive.user.membership_id === 'free' && <Text style={styles.text}>Esta suscripción se renueva automáticamente cada 30 días por un importe de 12,99€.</Text>}
                    <Text style={styles.text}>Puedes cancelar en el momento que quieras.</Text>
                </View>
            </ScrollView>
            <Toast message={'Suscripción comprada con exito.'} visible={toastBuy}/>
        </SafeAreaView>
    )
}

export default  MySubscriptionsScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginVertical: 20
    },
    containerTypeSubscription: {
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginRight: 'auto',
        marginLeft: '8%',
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 30,
        borderWidth: 1,
        borderColor: Colors.default.COLORS.primary,
        backgroundColor: Colors.default.COLORS.background_lightDark
    },
    containerPurchase: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '90%',
        marginTop: -20,
        marginBottom: 20,
        paddingTop: 30,
        paddingBottom: 15,
        paddingHorizontal: 7,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: Colors.default.COLORS.primary,
        backgroundColor: Colors.default.COLORS.background_lightDark
    },
    containerPrice: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    price: {
        fontSize: 23,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    },
    perTime:{
        fontSize: 20,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    },
    buttonSubscription: {
        width: '40%',
    },
    buttonRestore: {
        width: '90%'
    },
    containerPolicy: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '90%',
        marginVertical: 20
    },
    policy: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    },
    text: {
        width: '90%',
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    buttonSubscribed: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%',
        height: 40,
        borderRadius: 30,
        backgroundColor: '#C3FFEA'
    }
})
