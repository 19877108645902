import React, {useEffect, useState} from 'react';
import { View, Text, StyleSheet, Animated, Easing} from 'react-native';

export const Toast = (props) => {
    const [opacity, setOpacity] = useState(0);
    let opacityValue = new Animated.Value(0);

    useEffect(() => {
        if (props.visible === true) {
            showToast();
        }
    }, [props.visible]);

    async function showToast() {
        await Animated.timing(opacityValue, {
            toValue: 6,
            duration: 4000,
            easing: Easing.inOut(Easing.sin),
            useNativeDriver: true,
        }).start((finished) => {
        });

        setOpacity(opacityValue.interpolate({
            inputRange: [0, 1, 2, 3, 4, 5, 6],
            outputRange: [0, 1, 1, 1, 1, 1, 0]
        }));
    }

    return(
        <View style={{position: 'absolute', bottom: 20, justifyContent: 'center', width: '100%'}} pointerEvents={'none'}>
            <Animated.View style={[styles.container, {opacity: opacity}]}>
                <Text style={{flex: 1, flexWrap: 'wrap'}}>{props.message}</Text>
            </Animated.View>
        </View>
    )
}

export default Toast;

const styles = StyleSheet.create({
    container: {
        width: '90%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 15,
        paddingHorizontal: 15,
        backgroundColor: 'rgba(221, 221, 221, 0.9)',
        borderRadius: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
})
