import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity, Text, Image, Dimensions} from 'react-native';
import * as Colors from '../../styles/Colors';
import { Button } from '../../components/button.component';
import {getFormRegister, updateUser} from '../../services/user.service';
import loginStore from '../../store/loginStore';
import {useNavigate, useLocation} from 'react-router-dom';

const {width} = Dimensions.get('window');

const FormRegisterScreen = props => {
    const location = useLocation();

    const user = location.state;
    const [isMajor, setIsMajor] = useState(null);
    const [formData, setFormData] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const setLogin = loginStore(state => state.setLogin);
    const navigate = useNavigate();

    //Recoge el formulario para el segundo paso del registro
    useEffect(() => {
        getFormRegister().then(res => {
            let formulario = [];
            res.data.map(response => {
                response.isChecked = false;
                formulario.push(response);
            })
            setFormData(formulario);
        }).catch(err => {
            console.log('ERROR al recoger le form', err);
        })
    }, []);

    //Cambia el objeto seleccionado del formulario
    const changeIsChecked = (res) => {
        user.previous_question_id = res.id;
        setFormData(formData.map(response => {
            console.log(response.id + '----' + res.id)
            if (response.id === res.id) {
                response.isChecked = true;
            } else {
                response.isChecked = false;
            }
            return response;
        }))
    }

    //Actualiza el usuario añadiendo la opcion seleccionada
    const modifyUser = () => {
        /*comprueba si es mayor de edad o no, o si tiene el formulario respondido para hacer un una actualizacion del usuario u otra*/
        if (isMajor) {
            if (user.previous_question_id) {
                updateUser(user).then(res => {
                    navigate('/home');
                    setLogin(true);
                    console.log('Usuario actualizado con exito', res);
                }).catch(err => {
                    console.log('ERROR al actualizar el usuario', err);
                })
            } else {
                console.log('Selecciona una opcion');
            }

        } else if (isMajor === false) {
            updateUser(user).then(res => {
                navigate('/home');
                setLogin(true);
                console.log('Usuario actualizado con exito', res);
            }).catch(err => {
                console.log('ERROR al actualizar el usuario', err);
            })
            /*console.log('resp', isMajor);
            navigate('/home');
            setLogin(true);*/
        } else {
            console.log('No puedes continuar')
        }

    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    <View style={styles.containerImage}>
                        <Image style={styles.image} resizeMode={'contain'}  source={require('../../assets/growpsyApaisado.png')}/>
                    </View>
                    <Text style={styles.title}>Cuéntanos un poco más sobre ti</Text>
                    <Text style={styles.title}>¿Eres mayor de edad?</Text>
                    <View style={styles.containerType}>
                        <Button primary={isMajor === false} style={styles.button} text={'No'} press={() => setIsMajor(false)}/>
                        <Button primary={isMajor}style={styles.button} text={'Si'} press={() => setIsMajor(true)}/>
                    </View>
                    {isMajor === true && formData.length > 0 ?
                        <>
                            <Text style={styles.title}>¿Cuál de estas afirmaciones define mejor tu necesidad?</Text>
                            {formData.map(res => {
                                return (
                                    <TouchableOpacity activeOpacity={0.7} style={[styles.card, res.isChecked ? styles.cardFocus : null]}
                                                      onPress={() => changeIsChecked(res)}>
                                        <Text>{res.title}</Text>
                                    </TouchableOpacity>
                                );
                            })}
                        </>
                        :
                        null
                    }
                    <Button primary={true} text={'Continuar'} style={styles.buttonContinue} press={() => modifyUser()}/>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default FormRegisterScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 50
    },
    containerImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 100
    },
    image: {
        width: width <= 420 ? '90%' : (width <= 820 ? '50%' : '30%'),
        height: 80
    },
    title: {
        marginBottom: 20,
        fontSize: 16,
        color: '#2D2E2F',
    },
    containerType: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginBottom: 40
    },
    button: {
        width: '48%'
    },
    card: {
        width: '90%',
        paddingHorizontal: 7,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: '#D8DBDF',
        borderRadius: 6,
        marginTop: 10,
        backgroundColor: '#FFFFFF'
    },
    cardFocus: {
        borderColor: Colors.default.COLORS.primary
    },
    buttonContinue: {
        width: '90%',
        marginTop: 20,
        bottom: 0
    }

})
