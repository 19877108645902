import React from 'react';
import {StyleSheet, View, TouchableOpacity, Text} from 'react-native';
import * as Colors  from '../styles/Colors';
import moment from 'moment';

export const Invitation = props => {

    return(
        <View style={[styles.container, props.style]}>
            <View style={styles.containerTitle}>
                <Text style={styles.title}>{props.user} te ha invitado al canal de {props.channel}</Text>
                <Text style={styles.date}>{moment(props.date).format('DD/MM/YYYY')}</Text>
            </View>
            <View style={styles.containerButtons}>
                <TouchableOpacity style={[styles.button, {backgroundColor: '#CECECE'}]} onPress={props.pressReject}>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Rechazar</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, {backgroundColor: Colors.default.COLORS.primary}]} onPress={props.pressAccept}>
                    <Text style={styles.textAccept}>Aceptar</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '90%',
        borderRadius: 6,
        padding: 16,
        backgroundColor: '#FAF9F9',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    containerTitle: {
    },
    title: {
        fontSize: 14,
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    },
    date: {
        fontSize: 12,
        marginTop: 5,
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    containerButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%',
        height: 40,
        borderRadius: 3,
        zIndex: 9
    },
    textAccept: {
        color: Colors.default.COLORS.text_light,
        fontFamily: 'Montserrat',
        fontWeight: 500
    }
})
