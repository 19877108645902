import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet } from "react-native";
import { BackgroundBlur } from '@livekit/track-processors';
import {
  ControlBar,
  GridLayout,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  LayoutContextProvider,
  Chat,
  usePinnedTracks,
  useCreateLayoutContext,
  CarouselLayout,
  FocusLayout,
  FocusLayoutContainer,
  useLocalParticipant
} from '@slashmobility/livekit-components-react';
import '@livekit/components-styles';
import { RoomEvent, Track } from 'livekit-client';
import { isEqualTrackRef, isTrackReference } from '@livekit/components-core';
import {ReactComponent as BlurIcon} from '../assets/blur.svg';


export const MyVideoConference = (props) => {
        
    const [widgetState, setWidgetState] = React.useState({
      showChat: false,
      unreadMessages: 0,
      showSettings: false,
    });

    const lastAutoFocusedScreenShareTrack = React.useRef(null);

    const tracks = useTracks(
      [
        { source: Track.Source.Camera, withPlaceholder: true },
        { source: Track.Source.ScreenShare, withPlaceholder: false },
      ],
      { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
    );

    const layoutContext = useCreateLayoutContext();

    const screenShareTracks = tracks
      .filter(isTrackReference)
      .filter((track) => track.publication.source === Track.Source.ScreenShare);

    const focusTrack = usePinnedTracks(layoutContext)?.[0];
    const carouselTracks = tracks.filter((track) => !isEqualTrackRef(track, focusTrack));
    const { localParticipant } = useLocalParticipant();
    const [blurEnabled, setBlurEnabled] = useState(false)

    React.useEffect(() => {
      // If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
      if (
        screenShareTracks.some((track) => track.publication.isSubscribed) &&
        lastAutoFocusedScreenShareTrack.current === null
      ) {
        layoutContext.pin.dispatch?.({ msg: 'set_pin', trackReference: screenShareTracks[0] });
        lastAutoFocusedScreenShareTrack.current = screenShareTracks[0];
      } else if (
        lastAutoFocusedScreenShareTrack.current &&
        !screenShareTracks.some(
          (track) =>
            track.publication.trackSid ===
            lastAutoFocusedScreenShareTrack.current?.publication?.trackSid,
        )
      ) {
        layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
        lastAutoFocusedScreenShareTrack.current = null;
      }
    }, [
      screenShareTracks
        .map((ref) => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`)
        .join(),
      focusTrack?.publication?.trackSid,
    ]);

    const widgetUpdate = (state) => {
      setWidgetState(state);
    };

    const onHandleBackgroundBlur = async () => {  
      
      console.log(localParticipant.videoTrackPublications)

      try{
        for (const [trackSid, publication] of localParticipant.videoTrackPublications.entries()) {
            if (publication.kind == "video" && publication.source == "camera") {
              const localVideoTrack = publication.track;
              
              if (localVideoTrack && !blurEnabled) {
                const blur = BackgroundBlur(5);
                //const blur = VirtualBackground(image)

                disableBackgroundBlur(localVideoTrack)
                await localVideoTrack.setProcessor(blur);
                setBlurEnabled(!blurEnabled)    
                //break;
              } else if (localVideoTrack) {
                disableBackgroundBlur(localVideoTrack)
                setBlurEnabled(!blurEnabled)
              }
            }
          }
      }  catch (err) {
        console.log("ERROR setting background, err")
      }
    };
  
    async function disableBackgroundBlur(videoTrack) {
      await videoTrack.stopProcessor();
    }
  
    return (
    <div className="lk-video-conference">

      <LayoutContextProvider
      value={layoutContext}
      onWidgetChange={widgetUpdate}
    >
      <div className="lk-video-conference-inner">
        {!focusTrack ? (
          <div className="lk-grid-layout-wrapper">
            <GridLayout tracks={tracks}>
              <ParticipantTile />
            </GridLayout>
          </div>
        ) : (
          <div className="lk-focus-layout-wrapper">
            <FocusLayoutContainer>
              <CarouselLayout tracks={carouselTracks}>
                <ParticipantTile />
              </CarouselLayout>
              {focusTrack && <FocusLayout trackRef={focusTrack} />}
            </FocusLayoutContainer>
          </div>
        )}
        <div style={styles.containerFlexCenter}>
          <ControlBar 
          controls={{ chat: true }}
          onDeviceError={(error, source) => alert(`Debe activar el persmiso de ${source} para continuar`)}
          />
          { props.isConnected && !window.ReactNativeWebView && 
            <TouchableOpacity style={styles.blurContainer} onPress={onHandleBackgroundBlur}>
                <BlurIcon style={blurEnabled ?  {} : styles.opacity} />
            </TouchableOpacity>
          }
        </div>

      </div>
      <RoomAudioRenderer />
          <Chat
            style={{ display: widgetState.showChat ? 'grid' : 'none' }}
          />
      </LayoutContextProvider>
    </div>
    );
}

const styles = StyleSheet.create({ 
    containerFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    blurContainer: {
        width: 45,
        height: 45,
    },
    opacity: {
        opacity: 0.5
    }
})
