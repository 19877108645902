import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Text, Dimensions, Switch} from 'react-native';
import {Input} from '../components/input.component';
import {Button} from '../components/button.component';
import {Select} from '../components/select.component';

//import DatePicker from 'react-native-modern-datepicker';
import * as Colors from '../styles/Colors';
import {createSession, editSession, createRecurrentSession} from '../services/data.service';
import {Loader} from '../components/loader.component';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment';

const {width} = Dimensions.get('window');

const NewSessionScreen = props => {
    const [name, setName] = useState(null);
    const [duration, setDuration] = useState(60);
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [loader, setLoader] = useState(false);

    const [endDate, setEndDate] = useState(null);
    const [interval, setInterval] = useState(null);
    const [patternType, setPatternType] = useState(null);
    const [isRecurrent, setIsRecurrent] = useState(false);
    const [showRecurrentFields, setShowRecurrentFields] = useState(false); 

    const [errorMessageTitle, setErrorMessageTitle] = useState(null);
    const [errorMessageDuration, setErrorMessageDuration] = useState(null);
    const [errorMessageDate, setErrorMessageDate] = useState(null);
    const [errorMessageHour, setErrorMessageHour] = useState(null);
    const [errorMessageInterval, setErrorMessageInterval] = useState(null);

    const navigate = useNavigate();
    const {id, idChannel} = useParams();
    const location = useLocation();

    //NEW
    const patternTypeValues = [{label: 'Días', key: 'days'}, {label: 'Semanas', key: 'weeks'}];

    /*Al entrar a la vista*/
    useEffect(() => {
        /*Si existe el id edita los estados*/
        if (id !== undefined) {
            setName(location.state.name);
            setDuration(location.state.duration);
            setDate(formatDate(location.state.date));
        }
    }, []);

    const formatDate = (dateStr) => {
        const [datePart, timePart] = dateStr.split(' ');
        const [day, month, year] = datePart.split('/');
        const [hour, minuteWithSuffix] = timePart.split(':');
        const minute = minuteWithSuffix.replace('h', '');
    
        return `${year}-${month}-${day}T${hour}:${minute}`;
    };
    

    /*Crea una sesión*/
    const newSession = () => {
        setLoader(true);
        /*Valida que los campos estan correctos*/
        if (!validateAll()) {
            /*Crea la sesión*/
            createSession(name, idChannel, date, duration).then(res => {
                setLoader(false);
                /*Navega a la pagina de sesiones del grupo en el que se a creado la sesión*/
                navigate(`/sessions/${idChannel}`, {state: {colors: location.state.colors}});
                console.log('sesión creada con exito', res.data);
            }).catch(err => {
                console.log('ERR', err);
                if (err.response.status === 400) {
                    /*Da error al crear la sesión*/
                    alert('No se ha podido crear la sesión porque uno o varios pacientes no han pagado sesiones');
                } else if (err.response.status === 422) {
                    /*La informacion introducida no es correcta*/
                    setLoader(false);
                    setTimeout(() => {
                        alert('Se ha producido un error, revisa la información introducida.')
                    }, 200)
                }
                setLoader(false);
                console.log('ERROR al crear la sesión', err);
            })
        } else {
            setLoader(false);
        }
    }

    const newRecurrentSession = () => {
        setLoader(true);
        if (!validateAll()) {
            createRecurrentSession(name, idChannel, date, endDate, duration, interval, patternType).then(res => {
                setLoader(false);
                /*Navega a la pagina de sesiones del grupo en el que se a creado la sesión*/
                navigate(`/sessions/${idChannel}`, {state: {colors: location.state.colors}});
                console.log('sesión creada con exito', res.data);
            }).catch(err => {
                console.log('ERR', err);
                if (err.response.status === 400) {
                    /*Da error al crear la sesión*/
                    alert('No se ha podido crear la sesión porque uno o varios pacientes no han pagado sesiones');
                } else if (err.response.status === 422) {
                    /*La informacion introducida no es correcta*/
                    setLoader(false);
                    setTimeout(() => {
                        alert('Se ha producido un error, revisa la información introducida.')
                    }, 200)
                }
                setLoader(false);
                console.log('ERROR al crear la sesión', err);
            })
        }
    }

    /*Edita la sesión*/
    const modifySession = () => {
        setLoader(true);
        /*Valida que los campos de la sesión son correctos*/
        if (validateTitle() === false && validateDuration() === false && validateDate() === false) {
            /*Edita la sesión*/
            editSession(id, name, date, duration).then(res => {
                setLoader(false);
                /*Navega a la pagina de sesiones del grupo en el que se a creado la sesión*/
                navigate(`/sessions/${location.state.idChannel}`, {state: {colors: location.state.colors}});
            }).catch(err => {
                console.log('ERROR al editar la sesión', err);
            })
        } else {
            setLoader(false);
        }
    }

    /*Validación de todos los campos*/
    const validateAll = () => {
        setErrorMessageTitle(null);
        setErrorMessageDuration(null);
        setErrorMessageDate(null);
    
        const isTitleValid = validateTitle();
        const isDurationValid = validateDuration();
        const isDateValid = validateDate();

        return isTitleValid || isDurationValid || isDateValid;
    };

    /*Valida el titulo de la sesión*/
    const validateTitle = () => {
        if (name === null) {
            setErrorMessageTitle('Campo obligatorio.')
            return true;
        } else {
            return false;
        }
    }

    /*Valida la duración de la sesión*/
    const validateDuration = () => {
        if (duration === null) {
            setErrorMessageDuration('Campo obligatorio.')
            return true;
        } else {
            return false;
        }
    }

    /*Valida la fecha de la sesión*/
    const validateDate = () => {
        if (date === null) {
            setErrorMessageDate('Selecciona una fecha por favor.')
            return true;
        } else {
            return false;
        }
    }

    /*const validateHour = () => {
        if (hour === null) {
            setErrorMessageHour('Selecciona una hora por favor.')
            return true;
        } else {
            return false;
        }
    }*/

        
            return (
                loader ?
                    <Loader style={{ flex: 1, height: 500 }} />
                    :
                    <SafeAreaView style={styles.safeArea}>
                        <ScrollView>
                            <View style={styles.container}>
                                <Input
                                    title={'Nombre de sesión'}
                                    value={name}
                                    error={errorMessageTitle}
                                    getValue={value => {
                                        setErrorMessageTitle(null);
                                        setName(value);
                                    }}
                                />
                                <Input
                                    title={'Duración (en minutos)'}
                                    value={duration}
                                    keyboardType={'number-pad'}
                                    error={errorMessageDuration}
                                    getValue={value => {
                                        setErrorMessageDuration(null);
                                        setDuration(value);
                                    }}
                                />
                                <View style={styles.containerCalendar}>
                                    <Text>Fecha y hora inicio</Text>
                                    <input
                                        style={styles.dateTimePicker}
                                        value={date}
                                        type="datetime-local"
                                        onChange={value => setDate(value.target.value)}
                                    />
                                    {errorMessageDate !== null &&
                                        <Text style={styles.error}>{errorMessageDate}</Text>
                                    }
                                </View>
                                {/* 
                                    1. Al editar (id !== undefined) y si location.state.idRecurrent !== null.
                                    2. Al crear (id === undefined) solo si showRecurrentFields es true.
                                */}
                                {(id !== undefined && location.state.idRecurrent !== null) || (id === undefined && showRecurrentFields) ? (
                                    <>
                                        <View style={styles.containerCalendar}>
                                            <Text>Fecha y hora finalización</Text>
                                            <input
                                                style={styles.dateTimePicker}
                                                value={endDate}
                                                type="datetime-local"
                                                onChange={value => setEndDate(value.target.value)}
                                            />
                                            {errorMessageDate !== null &&
                                                <Text style={styles.error}>{errorMessageDate}</Text>
                                            }
                                        </View>
                                        <Input
                                            title={'Cada cuanto'}
                                            value={interval}
                                            error={errorMessageInterval}
                                            getValue={value => {
                                                setErrorMessageInterval(null);
                                                setInterval(value);
                                            }}
                                        />
                                        <Select
                                            title={''}
                                            values={patternTypeValues}
                                            onChangeValue={value => {
                                                setPatternType(value);
                                            }}
                                            defaultValue={true}
                                        />
                                    </>
                                ) : null}
                                {id === undefined && (
                                    <View style={styles.container}>
                                        <Text>Sesión recurrente</Text>
                                        <Switch
                                            trackColor={{false: '#767577', true: '#81b0ff'}}
                                            thumbColor={isRecurrent ? '#f5dd4b' : '#f4f3f4'}
                                            onValueChange={value => {
                                                setIsRecurrent(value);
                                                setShowRecurrentFields(value);
                                            }}
                                            value={isRecurrent}
                                        />
                                    </View>
                                )}
                                <Button
                                    style={{ width: '90%' }}
                                    text={id !== undefined ? 'Editar sesión' : 'Crear sesión'}
                                    primary
                                    press={() => id !== undefined ? modifySession() : (isRecurrent ? newRecurrentSession() : newSession())}
                                />
                            </View>
                        </ScrollView>
                    </SafeAreaView>
            );
        }
        

export default NewSessionScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 20
    },
    containerCalendar: {
        width: '90%',
        marginVertical: 20,
    },
    calendar: {
        marginTop: 5,
        borderRadius: 6,
        backgroundColor: '#F1F1F1',
        borderWidth: 1,
        borderColor: '#D8DBDF',
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginBottom: 5
    },
    dateTimePicker: {
        height: 42,
        marginTop: 5,
        paddingRight: 15,
        paddingLeft: 15,
        border: '1px solid #D8DBDF',
        borderRadius: 6
    }
})
