import React, { useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    StyleSheet,
    SafeAreaView,
    View,
    TouchableOpacity,
    TextInput,
    Image,
    Keyboard,
    Text,
    Alert,
    BackHandler,
    PermissionsAndroid,
    Platform,
    Modal,
    AppState,
    TouchableWithoutFeedback,
    NativeModules,
    Dimensions
} from "react-native";
import {ReactComponent as Clip} from '../assets/clip.svg';
import {ReactComponent as Audio} from '../assets/audio.svg';
import {ReactComponent as Sender} from '../assets/sender.svg';
import {ReactComponent as Start} from '../assets/startOutline.svg';
import {ReactComponent as Pause} from '../assets/pauseOutline.svg';
import {ReactComponent as Trash} from '../assets/trash.svg';
import {ReactComponent as Close} from '../assets/close.svg';
import {ReactComponent as Document} from '../assets/document.svg';
import * as Colors from '../styles/Colors';
import {createPrivateChat, blockChat, unblockChat} from '../services/data.service';
import {getChatChannel, getChatChannelPagination, sendMessage} from '../services/chat.service';
import userStore from '../store/userStore';
import io from 'socket.io-client';
import {Loader} from '../components/loader.component';
import {Toast} from '../components/toast.component';
import FlatChat from '../components/flatChat.component';
import {ReactComponent as Back} from '../assets/back.svg';
import { getUser } from "../services/user.service";
import moment from 'moment';
import {noPushNotifications, getColors} from '../services/data.service';
import {BrowserRouter as Router, Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import ReactAudioPlayer from 'react-audio-player';
import { useTimer } from 'react-timer-hook';
import typeHeaderStore from '../store/typeHeaderStore';
import { Player } from 'video-react';
import AsyncStorage from "@react-native-async-storage/async-storage";


const {width} = Dimensions.get('window');

const PrivateChatDetailScreen = props => {
    const [idChat, setIdChat] = useState(null);
    let messageEnter = '';
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState(null);
    const [lastPage, setLastPage] = useState(false);
    const [moreMessages, setMoreMessages] = useState(false);
    const [quantityNewMessages, setQuantityNewMessages] = useState(0);
    const [incrementPages, setIncrementPages] = useState(true);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [isRecord, setIsRecord] = useState(false);
    const [timeToPlayAudio, setTimeToPlayAudio] = useState(1);
    const [search, setSearch] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [indexSearch, setIndexSearch] = useState([]);
    const [actualIndexSearch, setActualIndexSearch] = useState(0);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [scrollRef, setScrollRef] = useState(null);
    const [urlDocument, setUrlDocument] = useState(null);
    const [toastNoPermissions, setToastNoPermissions] = useState(false);
    const [toastDownloadCorrect, setToastDownloadCorrect] = useState(false);
    const [toastErrorSendMessage, setToastErrorSendMessage] = useState(false);
    const [typeDataDownload, setTypeDataDownload] = useState(null);
    const [toastDownloadError, setToastDownloadError] = useState(false);
    const [modalDownloadDocument, setModalDownloadDocument] = useState(false);
    const [loaderSender, setLoaderSender] = useState(false);
    const [toastNoChat, setToastNoChat] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    const [urlVideo, setUrlVideo] = useState(null);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const [showInput, setShowInput] = useState(true);

    const [audioRecorderSeconds, setAudioRecorderSeconds] = useState(0);
    const [audioRecorderTime, setAudioRecorderTime] = useState('');
    
    const {id, type, idSession, idMember} = useParams();
    const inputHidden = useRef(null);
    const socketRef = useRef(null);
    const user = userStore(state => state.user);
    const setUser = userStore(state => state.setUser);
    const setTypeHeader = typeHeaderStore(state => state.setTypeHeader);
    const openMenu = typeHeaderStore(state => state.openMenu);
    const location = useLocation();
    const navigate = useNavigate();
    const audioOptions = {
        sampleRate: 16000,
        channels: 1,
        bitsPerSample: 16,
        audioSource: 6,
        //wavFile: 'test.wav'
    };
    let {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({onExpire: () => console.warn('onExpire called') });


    /*Al entrar a la vista gestiona la notificaciones de los mensajes sin leer (puntitos naranjas que parpadean)*/
    useEffect( () => {
        let newNotifs = [];

        /*Comprueba si es una grupo o una conversacion privada.
        * Segun donde entre se encaraga de meter en el array en el caso de que el id del canal o conversacion no coincida con el que hemos entrado actualmente*/
        if (type === 'CH') {
            let channels = JSON.parse(localStorage.getItem('channelNotification'));
            if (channels){
                for(let i = 0; i < channels.length; i++){
                    if (channels[i] !== `${type}${id}`) {
    
                        if (!channels[i].startsWith("CH")) {
                            continue;
                        }
                        newNotifs.push(channels[i]);
                    }
                }
            }
        } else {
            let privates = JSON.parse(localStorage.getItem('privateNotification'));
            if (privates){
                for(let i = 0; i < privates.length; i++){
                    if (privates[i] !== `${type}${id}`) {

                        if (!privates[i].startsWith("CN")) {
                            continue;
                        }
                        newNotifs.push(privates[i]);
                    }
                }
            }
        }

        /*Una vez finalizado y los arrays llenos con sus respectivos valores
        * setea localstorage para tener los valores necesarios y trabajar con ellos en otros puntos de la aplicación*/
        if (type === 'CH') {
            console.log('THIS IS A CHANNEL', newNotifs);
            localStorage.setItem('channelNotification', JSON.stringify(newNotifs));
        } else {
            console.log('THIS IS A CONVERSATION', newNotifs);
            localStorage.setItem('privateNotification', JSON.stringify(newNotifs));
        }
    }, [])


    /*Al entrara a la vista pide los permisos de microfono para grabar audios*/
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({audio: true}).catch(err => {
            alert('Activa el permiso del microfono para grabar audio.')
        });
    }, []);

    /*Gestiona las notificaciones de mensajes sin leer (puntits naranjas)*/
    useEffect(() => {
        AsyncStorage.getItem('notifications').then(notifications => {
            console.log("notis", notifications)
            if (type === 'CH') {
                AsyncStorage.setItem('notifications', JSON.stringify({group: false, conversation: JSON.parse(notifications)?.conversation}));

            } else if (type === 'CN') {
                AsyncStorage.setItem('notifications', JSON.stringify({group: JSON.parse(notifications)?.group, conversation: false}));
            }
        })
    }, [])

    useEffect(() => {
        if (idSession !== null && idSession !== 'null' && idSession !== undefined) {
            AsyncStorage.getItem('token').then(res => {
                //window.open(`https://videochat.growpsy.com/sfu/${idSession}/${res.split('Bearer ').pop()}/`, '_blank');
            })
        }
    }, [])

    /*Al entrar a la vista decide el tipo de cabecera que va atener el header en este vista*/
    useEffect(() => {
        setTypeHeader('menu');
    }, [])

    /*Al entrara a la vista*/
    useLayoutEffect(() => {
        /*Recoge el usuario*/
        getUser().then(res => {
            console.log('getUser', res.data);
            setUser(res.data);
            connectToChat(res.data);
        }).catch(err => {
            console.log('ERROR al recoger el usuario', err);
        });
    }, []);

    /*Al entrar a ala vista*/
    useEffect(() => {
        /*Recoge el token del usuario y conecta el socket*/
        AsyncStorage.getItem('token').then(token => {
            socketRef.current = io('https://node.growpsy.com/chat-socket', {auth: {token: token?.split('Bearer ').pop()}});
        })

        /*Pasado un segundo inicializa los eventos del socket*/
        setTimeout(() => {
            socketEvents();
        }, 1000);
    }, []);

    const socketEvents = () => {
        socketRef.current?.on('connect', () => {
            console.log('CONECT', socketRef.current.id);
        });

        socketRef.current.on('welcome', () => {
            console.log('IS CONNECT', socketRef.current.id);
        });

        /*Al recibir un nuevo mensaje*/
        socketRef.current.on('newMessage', (e) => {
            setIncrementPages(false);
            /*Mete en el array el nuevo mensaje para que se visualize en la lista del chat*/
            setMessages(messages => [...messages, e]);
            /*Guarda la cantidad de mensajes que tiene*/
            setQuantityNewMessages(quantityNewMessages =>  quantityNewMessages + 1);
        });

        /*En el caso de desconectarse el socket*/
        socketRef?.current?.on('disconnect', () => {
            console.log('CONECT SOCKET DISCONNECT');
            /*Muestra un alert informativo*/
            alertSocketDisconnect();
        });
    }
    /*Muestra un alert informativo de que se ha perdido la conexion con el servidor*/
    const alertSocketDisconnect = () => {
        if (window.confirm('Se ha perdido la conexión con el servidor.')) {
            /*Navega a la pagina del grupo*/
            navigate(`/channel/${id}`)
        }
    }

    const connectToChat = (userConnect) => {
        if (id !== null && id !== 'null') {
            setIdChat(id);
            /*Recoge los mensajes de esta conversacion*/
            getChatChannel(`${type}${id}`, userConnect.user.id).then(async res => {
                let ids = []
                /*Recoge los mensajes y guarda los ids en el array*/
                res.data.channel.messages.messages.map( res => {
                    ids.push(res.messageId)
                })

                console.log("ids are", ids)
                await setMessages(res.data.channel.messages.messages);
                await setLastPage(res.data.channel.messages.isLastPage);
                /*Pasado medio segundo emite el join para unirse a la conversacion y el socket sea capaz de mandarnos
                * la informacion necesaria por los eventos del socket*/
                setTimeout(() => {
                    socketRef?.current?.emit('join', {
                        channelId: `${type}${id}`,
                        userId: userConnect.user.id,
                        email: userConnect.user.email,
                        socketId: socketRef.current.id,
                        platform: Platform.OS,
                        appVersion: 'web'
                    });
                }, 500);
            }).catch(err => {
                navigate(`/channel/${id}`)
                alert("Se ha producido un error conectando con el servidor de chat. Servidor desconectado.")
                console.log('ERROR al recoger el chat', err);
            })
        } else {
            setMessages([]);
        }
    }

    const showMoreMessages = () => {
        setMoreMessages(true);
        setIncrementPages(true);
        getChatChannelPagination(`${type}${id}`, messages[0]._id).then(res => {
            let newMessages = res.data.messages.messages.reverse();
            setLastPage(res.data.messages.isLastPage);
            setMessages(messages => [...newMessages, ...messages]);
            setQuantityNewMessages(quantityNewMessages =>  quantityNewMessages + res.data.messages.messages.length);
            console.log('QUANTITY MESSAGES', quantityNewMessages);
            setMoreMessages(false);
        }).catch(err => {
            console.log('ERROR al recoger mas mesnajes', err);
        })
    }

    /*Abre el explorador de archivos para abrirlos y poder enviarlos*/
    const openFileExplorer = () => {
        inputHidden.current.value = null; // Restablecer el valor del input
        inputHidden.current.click();
    }

    /*Selecciona un archivo y editamos el estado al valor del tipo de archivo que hemos seleccionado*/
    const uploadImage = (media) => {
        if(media.target.files[0]){
            const file = media.target.files[0];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['pdf', 'docx'];
            const notAllowedExtensions = ['txt', 'csv'];
        
            if (file.type.includes('image')) {
                setTypeData('IMAGE');
                /*Guardamos un base64 del archivo para poder hacer la previsualizacion*/
                setFile(URL.createObjectURL(file));
                /*Guardamos el objeto archivo para poder enviarlo ala servidor como mensaje nuevo*/
                setFile2(file);
            } else if (file.type.includes('video')) {
                setTypeData('VIDEO');
                setFile(URL.createObjectURL(file));
                setFile2(file);
            } else if (file.type.includes('application')) {
                if (allowedExtensions.includes(fileExtension)) {
                    // Lógica para manejar el archivo permitido
                    setTypeData('DOCUMENT');
                    setFile(URL.createObjectURL(file));
                    setFile2(file);
                    console.log('Archivo permitido:', file);
                } else if (notAllowedExtensions.includes(fileExtension)) {     
                    alert(`El formato .${fileExtension} no está permitido.`);
                } else {
                    alert('Formato de archivo no soportado.');
                }
            } else {
                alert('Tipo de archivo no soportado.');
            }
        } else {
            alert('Error: El archivo no se ha podido subir, inténtelo de nuevo');
        }
    };

    /*Gestiona el envio de un nuevo mensaje*/
    const sendNewMessage = () => {
        setLoaderSender(true);
        /*Comprueba si es una conversacion existente o una conversacion que se tiene que crear nueva*/
        if ((id !== null && id !== 'null' && id !== undefined) || idChat !== null) {
            /*En el caso de ser una conversacion existenete.
            * LLama ala funcion para enviar el nuevo mensaje*/
            newMessage(idChat);
            setMessage('');
        } else {
            /*En el caso de ser una nueva conversacion.
            * LLama al endpoint para crear la nueva conversacion*/
            createPrivateChat(idMember).then(async res => {
                /*Hace el join a la nueva conversacion para pdoer recibir sus datos por el evento del socket*/
                await socketRef.current.emit('join', { channelId: `${type}${res.data.id}`, userId: user.user.id, email: user.user.email, appVersion: 'web', platform: Platform.OS, socketId: socketRef?.current?.id});
                setIdChat(res.data.id);
                /*Llama ala funcion para enviar un nuevo mensaje a la conversacion que acabamos de craer*/
                await newMessage(res.data.id);
                setMessage('');
                console.log('Conversacion creada con exito', res.data);
            }).catch(err => {
                alert("Se ha producido un error al iniciar la conversación")
                console.log('ERROR al crear la conversacion', err);
            })
        }
    }

    /*Envia un nuevo mensaje*/
    const newMessage = async (idChat) => {
        /*Se encarga de formar el objeto para enviar el mensaje*/
        let route;
        setLoaderSender(true);
        let formData = new FormData();
        formData.append('channelId', `${type}${idChat ? idChat : id}`);
        formData.append('userId', user.user.id);
        formData.append('message', message);
        if (file2 !== null) {
            route = file2.uri;
        } else {
            route = undefined;
        }
        formData.append('file', file2);

        /*Envia el mensaje*/
        sendMessage(formData, route, `${type}${idChat ? idChat : id}`, user.user.id, typeData, message).then(res => {
            /*En el caso de ser un mensaje con un archivo muestra este alert para informar de que el mensaje aparecera en breve*/
            if (file2 !== null) {
                alertMessageFlat();
            }
            /*El resto de estados los devuelve a su estado inicial para poder enviar nuevos mensajes sin ningun tipo de probelma*/
            setMessage('');
            setFile(null);
            setFile2(null);
            setFileName(null);
            setTypeData(null);
            setLoaderSender(false);
            setAudioRecorderSeconds(0);
            setAudioRecorderTime(0);
            console.log('Mensaje enviado con exito', res);
        }).catch(err => {
            setMessage('');
            setFile(null);
            setFile2(null);
            setFileName(null);
            setTypeData(null);
            setLoaderSender(false);
            setToastErrorSendMessage(true);
            setAudioRecorderSeconds(0);
            setAudioRecorderTime(0);
            console.log('ERROR al enviar el mensaje', err);
            alert("Se ha producido un error al enviar el mensaje.")
        })
    }

    const alertMessageFlat = () => {
        Alert.alert(
            "Enviando archivo",
            "Tu fichero se ha enviado con exito en breve aparecera en tu conversacion.",
            [
                {
                    text: "Cerrar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "Aceptar", onPress: () => console.log('Accept pressed') }
            ]
        );
    }

    /*Filtra los mensajes*/
    const searchMessage = (value) => {
        let indexMessages = [];
        if (value !== '') {
            messages.filter((message, index) => {
                if (message.message.toLowerCase().includes(value.toLowerCase())) indexMessages.push(index);
            })

        }
        if (indexMessages.length > 0) {
            setActualIndexSearch(0);
            setIndexSearch(indexMessages);
            scrollRef.current.scrollToIndex({index: indexMessages[0]});
        }
    }

    const alertBlockChat = () => {
        Alert.alert(
            "Bloquear chat",
            "¿Esta seguro que desea bloquear el chat?",
            [
                {
                    text: "Cancelar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "Bloquear chat", onPress: () => blockOneChat() }
            ]
        );
    }

    /*Bloque un chat por id de chat*/
    const blockOneChat = () => {
        blockChat(idChat).then(res => {
            //setIsBlock(true);
            console.log('Chat bloqueado con exito', res.data);
        }).catch(err => {
            console.log('ERROR al bloquear el chat', err);
        })
    }

    const alertUnblockChat = () => {
        Alert.alert(
            "Desbloquear chat",
            "¿Esta seguro que desea desbloquear el chat?",
            [
                {
                    text: "Cancelar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "Desbloquear chat", onPress: () => unblockOneChat() }
            ]
        );
    }

    /*Desbloque un chat por id de chat*/
    const unblockOneChat = () => {
        unblockChat(idChat).then(res => {
            //setIsBlock(false);
            console.log('Chat bloqueado con exito', res.data);
        }).catch(err => {
            console.log('ERROR al bloquear el chat', err);
        })
    }

    const startRecordAudio = async () => {
        //let time = 1
        /*setIsRecord(true);
        await audioRecorderPlayer.startRecorder();
        audioRecorderPlayer.addRecordBackListener(e => {
            setAudioRecorderSeconds(e.currentPosition);
            setAudioRecorderTime(audioRecorderPlayer.mmssss(Math.floor(e.currentPosition)));
        })*/
    }

    /*Para de grabar un audio*/
    const stopRecordAudio = async (audio) => {
        /*Indica el tipo de dato con el que estamos trabajando
        * y lo guarda los datos en los estado para poder trabajar con ellos*/
        setTypeData('AUDIO')
        let res = await fetch(audio.url);
        let buf = await res.arrayBuffer();
        let file = new File([buf], 'audio')
        setAudioFile(audio.url);
        setFile2(file);
    }

    const startAudio = async () => {
        /*setIsPlay(true);
        await audioRecorderPlayer.startPlayer();
        audioRecorderPlayer.addPlayBackListener(e => {
            setAudioPlaySeconds(e.currentPosition);
        })*/
    }

    const stopAudio = async () => {
        /*setIsPlay(false);
        await audioRecorderPlayer.stopPlayer();*/
    }

    const removePushNotifications = () => {
        noPushNotifications(null).then(res => {
            console.log('Push eliminadas con exito', res);
        }).catch(err => {
            console.log('ERROR al elimianr las push', err);
        })
    }


    return(
        user ?
            <SafeAreaView style={styles.safeArea}>
                {openMenu &&
                <div style={styles.dropdown}>
                    <p style={styles.blockChatDropdown}>Bloquear chat</p>
                </div>
                }

                <AudioReactRecorder style={{display: 'none'}} state={isRecord ? RecordState.START : RecordState.STOP}  canvasWidth={0} canvasHeight={0} onStop={stopRecordAudio} />

                <View style={styles.container}>
                    {openSearch &&
                    <View style={styles.searcherBar}>
                        <TouchableOpacity style={styles.containerClose} onPress={() => {
                            setSearch("");
                            setOpenSearch(false);
                            setActualIndexSearch(0);
                            setIndexSearch([]);
                        }}>
                        </TouchableOpacity>
                        <View style={styles.containerInputSearch}>
                            <View style={{ width: "90%" }}>
                                <TextInput
                                    style={styles.inputSearch}
                                    value={search}
                                    onChangeText={(value) => {
                                        setSearch(value);
                                        searchMessage(value);
                                    }}
                                />
                            </View>
                            <View style={{ width: "15%", marginRight: 10 }}>
                                <Text
                                    style={styles.textCoincidences}>{indexSearch.length > 0 ? (actualIndexSearch + 1) : (actualIndexSearch)} / {indexSearch.length}</Text>
                            </View>

                        </View>
                        <View style={styles.containerUpDown}>
                            <TouchableOpacity onPress={() => {
                                if (actualIndexSearch > 0) {
                                    setActualIndexSearch(actualIndexSearch - 1);
                                    scrollRef.current.scrollToIndex({ index: indexSearch[actualIndexSearch - 1] });
                                }
                            }}>
                                {/*<Ionicons name={"chevron-up-outline"} size={20} color={Colors.default.COLORS.background_dark} />*/}
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
                                if (actualIndexSearch < indexSearch.length - 1) {
                                    setActualIndexSearch(actualIndexSearch + 1);
                                    scrollRef.current.scrollToIndex({ index: indexSearch[actualIndexSearch + 1] });
                                }
                            }}>
                            </TouchableOpacity>
                        </View>
                    </View>
                    }


                    <View style={[styles.containerMessages, openSearch && { paddingBottom: 50 }]}>
                        {messages === null ?
                            <View style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <Loader style={{ marginTop: "70%" }} />
                            </View>
                            :
                            messages.length > 0 ?
                                (file === null ?
                                        <FlatChat
                                            user={user}
                                            messages={moreMessages === false ? messages : []}
                                            getRef={ref => setScrollRef(ref)}
                                            getUrlDownloadFile={value => setUrlDocument(value)}
                                            getTypeData={value => setTypeDataDownload(value)}
                                            getUrlVideo={value => {
                                                setUrlVideo(value);
                                                setModalVideo(true);
                                            }}
                                            getUrlImage={(image) => {
                                                setFile(image);
                                                setTypeData('IMAGE');
                                                setShowInput(false);
                                            }}
                                            getUrlDocument={(document) => {
                                                window.open(`https://node.growpsy.com/${document}`)
                                            }}
                                            private={true}
                                            isTop={() => showMoreMessages()}
                                            quantityNewMessages={quantityNewMessages}
                                            isNewMessage={incrementPages}
                                            lastPage={lastPage}
                                        />
                                        :
                                        <View style={{ position: "relative", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 50 }}>
                                            <TouchableOpacity
                                                style={{marginLeft: 'auto', marginBottom: 30}}
                                                activeOpacity={0.7}
                                                onPress={() => {
                                                    setTypeData(null);
                                                    setFile(null);
                                                    setFile2(null);
                                                    setShowInput(true);
                                                }}
                                            >
                                                <Close width={30}/>
                                            </TouchableOpacity>
                                            {typeData === "IMAGE" &&
                                            <Image style={{ width: '100%', height: 500 }} resizeMode={'contain'}
                                                   source={{ uri: file }} />
                                            }

                                            {typeData === "VIDEO" &&
                                                <video style={{width: '100%', height: 500}} controls>
                                                    <source src={file}/>
                                                </video>
                                            }

                                            {typeData === "DOCUMENT" &&
                                            <View style={{ alignItems: "center", width: "100%", height: 300 }}>
                                                <Document width={200}/>
                                                <Text style={{ fontSize: 30 }}>{file2.name}</Text>
                                            </View>
                                            }
                                        </View>
                                )
                                :
                                (file === null ?
                                        <View style={{marginTop: 30}}>
                                            <Text style={styles.noMessages}>Todavia no hay mensajes en esta conversación</Text>
                                            <Text style={styles.noMessages}>Recuerda que los mensajes son privados con esta persona.</Text>
                                        </View>
                                        :
                                        <View style={{ position: "relative", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                            {typeData === "IMAGE" &&
                                            <Image style={{ width: '100%', height: 500 }} resizeMode={'contain'}
                                                   source={{ uri: file }} />
                                            }

                                            {typeData === "VIDEO" &&
                                            <video
                                                style={{ width: 300, height: 300 }}
                                            >
                                            <source src={file} type={'video/mp4'}/>
                                            </video>
                                            }

                                            {typeData === "DOCUMENT" &&
                                            <View style={{ alignItems: "center", width: "100%", height: 300 }}>
                                                <Text style={{ fontSize: 30 }}>{fileName}</Text>
                                            </View>
                                            }
                                        </View>
                                )

                        }

                        {modalDownloadDocument &&
                        <View style={{
                            position: "absolute",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "115%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0 , 0.5)",
                        }}>
                            <View style={{
                                width: "100%",
                                borderRadius: 6,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingVertical: 20,
                                backgroundColor: Colors.default.COLORS.background_light,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 3,
                                },
                                shadowOpacity: 0.27,
                                shadowRadius: 4.65,
                                elevation: 6,
                            }}>
                                <Loader />
                                <Text>Descargando archivo</Text>
                            </View>
                        </View>
                        }
                    </View>

                </View>

                {showInput &&
                    <View
                        style={[styles.containerWriterImage, {alignItems: 'center'}, Platform.OS === "ios" && {marginBottom: keyboardHeight}]}
                        importantForAccessibility={"no-hide-descendants"}
                        pointerEvents={messages !== null ? 'auto' : 'none'}>

                        <View style={styles.containerWriter} importantForAccessibility={"no-hide-descendants"}>
                            {typeData === null || typeData === "IMAGE" || typeData === "DOCUMENT" || typeData === "VIDEO" ?
                                <TouchableOpacity
                                    style={[styles.writerButtons, {width: 50, paddingHorizontal: 10}]}
                                    activeOpacity={0.7}
                                    onPress={() => openFileExplorer()}
                                >
                                    <Clip/>
                                    <input ref={inputHidden} style={{display: 'none'}} type={'file'}
                                           onChange={(e) => uploadImage(e)}/>
                                </TouchableOpacity>
                                :
                                isPlay ?
                                    <TouchableOpacity onPress={() => stopAudio()}>
                                        <Pause/>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity activeOpacity={0.7} onPress={() => startAudio()}>
                                        <Start/>
                                    </TouchableOpacity>

                            }

                            {
                                typeData === null || typeData === "IMAGE" || typeData === "DOCUMENT" || typeData === "VIDEO" ?
                                    <TextInput
                                        style={[styles.input]}
                                        placeholder={"Escribe tu mensaje"}
                                        value={isRecord ? 'Grabando audio...' : message}
                                        textAlign={isRecord ? 'center' : 'left'}
                                        multiline={true}
                                        numberOfLines={5}
                                        onChangeText={(value) => {
                                            if (isRecord) {
                                                //stopAudio();
                                            } else {
                                                setMessage(value);
                                                messageEnter = value;
                                            }
                                        }}
                                        onPressIn={() => setKeyboardOpen(true)}
                                        onKeyPress={(e) => {
                                            if (e.code === 'Enter') {
                                                if (message.trim().length > 0) {
                                                    console.log("Message", message.length);
                                                    sendNewMessage();
                                                }
                                            }
                                        }}
                                    />
                                    :
                                    <ReactAudioPlayer
                                        src={audioFile}
                                        controls
                                    />
                            }


                            <View
                                style={[styles.writerButtons, typeData === "AUDIO" ? {width: "15%"} : {width: "10%"}]}
                            >
                                {loaderSender ?
                                    <Loader/>
                                    :
                                    ((message === "" || message === null) && typeData === null ?
                                            <TouchableOpacity
                                                style={styles.button}
                                                onPressIn={() => {
                                                    setIsRecord(true)
                                                    start();
                                                }}
                                                onPressOut={() => {
                                                    setIsRecord(false);
                                                    pause();
                                                }}
                                                importantForAccessibility={"no-hide-descendants"}
                                            >
                                                <Audio/>
                                            </TouchableOpacity>
                                            :
                                            <View style={styles.containerSenderAndRemover}>
                                                {file2 !== null && file2.name === 'audio' &&
                                                <TouchableOpacity style={{width: 50, marginRight: 10}} onPress={() => {
                                                    setFile(null);
                                                    setFile2(null);
                                                    setFileName(null);
                                                    setTypeData(null);
                                                    setTimeToPlayAudio(1);
                                                }}>
                                                    <Trash style={{width: 20, height: 20}}/>
                                                </TouchableOpacity>
                                                }
                                                <TouchableOpacity
                                                    style={[styles.button, typeData === "AUDIO" && {marginRight: 10}]}
                                                    onPress={() => sendNewMessage()}
                                                    disabled={typeData === null && message.trim().length <= 0}
                                                >
                                                    <Sender/>
                                                </TouchableOpacity>
                                            </View>
                                    )
                                }

                            </View>
                        </View>
                    </View>
                }

                <Toast message={"No se ha podido descargar el archivo, activa los permisos para poder descargarlo."}
                       visible={toastNoPermissions} />
                <Toast message={"Descarga realizada con exito."} visible={toastDownloadCorrect} />
                <Toast message={"No se ha podido descargar el archivo, intentalo de nuevo."} visible={toastDownloadError} />
                <Toast message={"No se ha podido enviar el mensaje, por favor vuelve a intentarlo."}
                       visible={toastErrorSendMessage} />
                <Toast message={"Esta conversacion se encuentra bloqueada o el usuario a desactivado los chats."}
                       visible={toastNoChat} />
            </SafeAreaView>
            :
            null
    )
}

export default PrivateChatDetailScreen;

const styles = StyleSheet.create({
    anchorButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container:{
        flex: 1,
        maxWidth: '100%'
    },
    searcherBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerClose: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '10%'
    },
    containerInputSearch: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '70%',
        height: 50
    },
    inputSearch: {
        width: '100%',
        height: '100%',
        borderBottomWidth: 1,
        borderBottomColor: Colors.default.COLORS.background_dark
    },
    textCoincidences: {
        color: Colors.default.COLORS.primary
    },
    containerUpDown: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '15%',
        paddingRight: 10
    },
    containerMessages: {
        paddingHorizontal: 20
    },
    containerImageArchive: {
        position: 'absolute',
        bottom: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: 130,
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderRadius: 6,
        marginRight: 'auto',
        marginBottom: 10,
        marginLeft: 20,
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerCloseImageArchive: {
        position: 'absolute',
        top: -12,
        right: -12,
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: 25,
        height: 25,
        borderRadius: 100,
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerImageWriter: {
        alignItems: 'center',
        width: 100,
        maxHeight: 100
    },
    image: {
        width: '100%',
        height: 100
    },
    containerWriter: {
        position: 'absolute',
        bottom: width <= 820 ? 60 : 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 55,
        //paddingHorizontal: 5,
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderColor: '#E1E1E1',
        backgroundColor: Colors.default.COLORS.background_light,
    },
    writerButtons: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        //paddingHorizontal: 10
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    input: {
        width: '80%',
        height: 55,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        outlineStyle: 'none'
    },
    containerActionSheet: {
        width: '100%',
        padding: 20,
        paddingBottom: 0
    },
    blockChat: {
        width: '100%',
        padding: 20
    },
    textBlockChat: {
        color: '#FF2626'
    },
    textUnblockChat: {
        color: Colors.default.COLORS.primary
    },
    containerOpenSearcher: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10
    },
    iconSearch: {
        marginRight: 10
    },
    containerSenderAndRemover: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    noMessages: {
        textAlign: 'center',
        marginTop: 30
    },
    cancelSuscription: {
        width: '100%',
        padding: 20
    },
    textDownloadDocument: {
        color: Colors.default.COLORS.primary
    },
    imageModal: {
        width: '100%',
        height: '100%'
    },
    safeAreaModal: {
        flex: 1,
        backgroundColor: Colors.default.COLORS.background_dark
    },
    containerHeaderModal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    buttonBackHeader: {
        justifyContent: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    containerImageModal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerActionSheetDocuments: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        padding: 20,
        paddingBottom: 15
    },
    containerOptionDocuments: {
        alignItems: 'center'
    },
    dropdown: {
        position: 'absolute',
        zIndex: 9,
        top: 50,
        right: '3%',
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: Colors.default.COLORS.background_light,
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
    },
    blockChatDropdown: {
        color: 'red'
    }
})
