import create from 'zustand';

/*Se utiliza para los puntos de mensajes sin leer que aparecen en el tabbar*/
const tabBarNotifStore = create((set, get) => ({
  /*Se inicializa el valo del store*/
  chats: false,
  /*Se actualiza el store*/
  setChats: chats => set(state => ({chats: chats})),
  /*Se inicializa el valo del store*/
  channels: false,
  /*Se actualiza el store*/
  setChannels: channels => set(state => ({channels: channels}))
}));

export default tabBarNotifStore;
