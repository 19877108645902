import React, {useState} from 'react';
import {StyleSheet, TextInput, View, Text, TouchableOpacity, Image} from 'react-native';

export const Input = props => {
    const [isSecure, setIsSecure] = useState(true);
    return (
        props.secure === true ?
            <View style={[props.style, styles.containerInput]}>
                <Text style={styles.title}>{props.title}</Text>
                <View style={styles.containerInputSecure}>
                    <TextInput
                        style={styles.inputSecure}
                        placeholder={props.placeholder}
                        secureTextEntry={isSecure}
                        value={props.value}
                        onChangeText={(value) => props.getValue(value)}
                    />
                    <TouchableOpacity onPress={() => setIsSecure(!isSecure)}>
                        <Image style={{width: 15, height: 10}} source={require('../assets/Vector.png')}/>
                    </TouchableOpacity>
                </View>
                {props.error !== null ? <Text style={styles.error}>{props.error}</Text> : null}
            </View>
            :
            <View style={[props.style, styles.containerInput]}>
                <Text style={styles.title}>{props.title}</Text>
                <TextInput
                    style={styles.input}
                    placeholder={props.placeholder}
                    keyboardType={props.keyboardType}
                    value={props.value}
                    onChangeText={(value) => props.getValue(value)}
                    onEndEditing={props.isError}
                />
                {props.error !== null ? <Text style={styles.error}>{props.error}</Text> : null}
            </View>
    )
}

const styles = StyleSheet.create({
    containerInput: {
        width: '90%',
        marginTop: 10
    },
    title: {
        marginBottom: 5
    },
    input: {
        width: '100%',
        height: 42,
        paddingHorizontal: 15,
        borderWidth: 1,
        borderColor: '#D8DBDF',
        borderRadius: 6,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        outlineStyle: 'none'
    },
    containerInputSecure: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 42,
        paddingHorizontal: 15,
        borderWidth: 1,
        borderColor: '#D8DBDF',
        borderRadius: 6
    },
    inputSecure: {
        width: '95%',
        height: '100%',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        outlineStyle: 'none'
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginBottom: 5
    }
})
