import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';


const axiosInstance = axios.create({baseURL: 'https://admin.growpsy.com/'});


export const getSessionToken = async (channelId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const queryParams = {
      channelId: channelId
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.get('/livekit_video_sessions', { headers: headers, params: queryParams }).then(res => {
        resolve(res);
      }).catch(err => {
        console.log("error join session", err)
        reject(err);
      })
    })
  }