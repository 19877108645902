import React, {useEffect, useState} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Dimensions} from 'react-native';
import * as Colors from '../styles/Colors';
import {getPostsOfCategory} from '../services/data.service';
import {ArticleLarge} from '../components/articleLarge.component';
import {Loader} from '../components/loader.component';
import {Searcher} from '../components/searcher.component';
import {useParams, Link} from 'react-router-dom';

const {width} = Dimensions.get('window');

const ArticlesScreen = props => {
    const [allPosts, setAllPosts] = useState(null);
    const [posts, setPosts] = useState(null);
    const [search, setSearch] = useState('');

    const {id} = useParams();

    /*Recoge los post de una categoria al entrara a la vista*/
    useEffect(() => {
        getPostsOfCategory(id).then(res => {
            setAllPosts((res.data));
            setPosts(res.data);
            console.log('Posts de las categorias recogiados con exito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger los articulos de las categorias', err);
        })
    }, []);

    /*Filtra los articulos*/
    const searchArticles = (value) => {
        if (value !== '') {
            let newArticles = posts.filter(article => {
                console.log(article);
                if (article.title.toLowerCase().includes(value.toLowerCase())) return article;
            })

            if (newArticles.length > 0) setPosts(newArticles);
            else setPosts(allPosts);
        }
        else {
            setPosts(allPosts);
        }
    }

    /*Mientras los post se cargan aparecera un loader*/
    return(
        posts === null ?
            <Loader style={{flex: 1}}/>
            :
            <SafeAreaView style={styles.safeArea}>
                <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                    <View style={styles.container}>
                        <Searcher
                            placeholder={'Búsca tus artículos'}
                            value={search}
                            getValue={async (value) => {
                                await setSearch(value);
                                await searchArticles(value);
                            }}
                        />
                        <View style={styles.containerPosts}>
                            {posts !== null ?
                                posts.map(post => {
                                    return(
                                        <Link style={{width: width <= 420 ? '100%' : '33.3%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}} to={`/article/${post.id}`}>
                                            <ArticleLarge title={post.title} image={post.image.url} create={post.created_at} /*press={() => props.navigation.navigate('Article', {article: post})}*//>
                                        </Link>
                                    )
                                })
                                :
                                null
                            }
                        </View>

                    </View>
                </ScrollView>
            </SafeAreaView>
    )
}

export default ArticlesScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
    },
    containerPosts: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '90%'
    }
})
