import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import * as Colors from '../styles/Colors';
import {ReactComponent as More} from '../assets/more.svg';

export const ButtonNewChannel = props => {
    return(
        <TouchableOpacity style={[styles.container, props.style]} activeOpacity={0.7} onPress={props.press}>
            <More width={25}/>
            {/*<Ionicons name={'add-outline'} size={25} color={Colors.default.COLORS.background_light}/>*/}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 60,
        borderRadius: 100,
        backgroundColor: Colors.default.COLORS.primary
    }
})
