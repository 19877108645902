import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyleSheet, SafeAreaView, ScrollView, View, Text, BackHandler, TouchableOpacity, Dimensions} from "react-native";
import {getPrivateChats} from '../services/data.service';
import {Searcher} from '../components/searcher.component';
import {Member} from '../components/member.component';
import {Loader} from '../components/loader.component';
import {noPushNotifications} from '../services/data.service';
import * as Colors from "../styles/Colors";
import {ReactComponent as Back} from "../assets/back.svg";
import tabBarNotifStore from '../store/tabBarNotifStore';
import {translateTypeUser} from '../utils/translateTypeUser';
import {BrowserRouter as Router, Link, Navigate,useNavigate} from 'react-router-dom';
import { normalizeString } from "../utils/formatters";

import AsyncStorage from "@react-native-async-storage/async-storage";
import io from "socket.io-client";

const {width} = Dimensions.get('window');


const PrivateChatsScreen = (props) => {
    const socketRef = useRef(null);
    const [allChats, setAllChats] = useState(null);
    const [chats, setChats] = useState(null);
    const [search, setSearch] = useState('');
    const setChatsNotif = tabBarNotifStore(state => state.setChats);
    const navigate = useNavigate();

    useEffect(() => {

        window.onpageshow = function(event) {
            console.log("pageshow event", event.persisted)
            if (event.persisted) {
                console.log("reload")
                window.location.reload()
            }
        };

        var current_time = new Date().getTime();

        /*Recoge todos los chats privados abiertos*/
        getPrivateChats(current_time).then(res => {
            setChats(res.data.conversations);
            setAllChats(res.data.conversations);
            console.log('getPrivateChats Chats privados recogidos con exito', res.data);

        }).catch(err => {
            console.log('ERROR al recoger los chats privados', err);
            /*Informa que ha habido un error al recoger los chats*/
            alert("Se ha producido un error al recoger los chats privados");
            /*Vuelve a la pagina de home*/
            navigate("/home")
        })

    }, []);

    /*Al entrara a la vista desconecta el socket*/
    useEffect(() => {
        socketRef?.current?.disconnect();
        /*Recoge el usuario*/
        AsyncStorage.getItem('USER').then(user => {
            /*Conecta el socket*/
            socketRef.current = io('https://node.growpsy.com');
            /*Inicializa los eventos del socket*/
            socketEvents(JSON.parse(user).id);
        })
    }, [])

    /*Inicializa los eventos del socket*/
    const socketEvents = (idUser) => {
        socketRef?.current?.emit('join', {userId: idUser});

        socketRef?.current?.on('joined', () => {
            console.log('is joined')
        })

        /*Cuando entra un nuevo mensaje*/
        socketRef?.current?.on('newMessage', (item) => {
            /*Comprueba que el id del que a mandado el mensaje no es el mismo id del usuario que tiene la sesion abierta*/
            if (idUser !== item.userId) {
                /*Comprueba si el nuevo mensaje es de un chat privado*/
                if (item.channelId.includes('CN')) {
                    /*Recoge todos los chats privados que tienes abiertos*/
                    getPrivateChats().then(res => {
                        setChats(res.data.conversations);
                        setAllChats(res.data.conversations);
                        console.log('Chats privados recogidos con exito', res.data);
                    }).catch(err => {
                        navigate(`/home`)
                        alert("Se ha producido un error al recoger las conversaciones. Servidor desconectado.")
                        console.log('ERROR al recoger los chats privados', err);
                    })
                }
            }
        })
    }

    /*Filtra las conversaciones privadas*/
    const searchChats = (value) => {
        if (value !== '') {
            let newChannels = chats.filter(chat => {
                if (normalizeString(chat.name).includes(normalizeString(value))) return chat;
            })

            if (newChannels.length > 0) setChats(newChannels);
            else setChats(allChats);
        }
        else {
            setChats(allChats);
        }
    }

    /*Elimina las notificaciones */
    const removePushNotifications = (chat) => {
        /*Elimina las notificaciones de una conversacion privada por id de conversacion*/
        noPushNotifications(`CN${chat.id}`).then(res => {
            socketRef?.current?.disconnect();
            setAllChats(null);
            setChats(null);
            props.navigation.navigate("PrivateChatDetail", { idChat: chat.id, imageUser: chat.image, userName: chat.name, newMessages: chat.new_messages, blocked: chat.blocked, permissions: chat.chat_permission});
            console.log('Push eliminadas con exito', res);
        }).catch(err => {
            console.log('ERROR al elimianr las push', err);
        })
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    <Searcher
                        placeholder={'Busca tus chats'}
                        value={search}
                        getValue={(value) => {
                            setSearch(value);
                            searchChats(value);
                        }}
                    />

                    {chats !== null ?
                        chats.length > 0 ?
                            chats.map(chat => {
                                    return (
                                        <Link style={{width: '100%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}} to={`/chats/${chat.id}/CN`} state={{idChat: chat.id}}>
                                            <Member
                                                image={chat.image}
                                                name={chat.name}
                                                type={translateTypeUser(chat.user_type)}
                                                buttonTime={false}
                                                newMessages={chat.new_messages}
                                                pressAll={() => {
                                                    console.log('USER', chat);
                                                    removePushNotifications(chat);
                                                }}
                                            />
                                        </Link>

                                    )
                                })

                            :
                            <Text>No tienes chats activos</Text>
                        :
                        <Loader style={{ flex: 1, height: 500 }}/>
                    }
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default PrivateChatsScreen;

const styles = StyleSheet.create({

    anchorButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light,
        paddingTop: 50,
        paddingBottom: 70
    },
    container: {
        flex: 1,
        alignItems: 'center'
    }
})
