import { useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import loginStore from '../store/loginStore';
import { logout } from '../services/user.service';
import { Text } from 'react-native';

export default function RequestInterceptor() {
    const setLogin = loginStore((state) => state.setLogin);

    useEffect(() => {
        const interceptor = axios.interceptors.response.use((res) => {
                return res;
            }, (err) => {
                if (err.response.status == 401) {
                    /*Cuando el interceptor detecta el 401, cierra la sesion, ya que otro dispositivo puede estar utilizando la misma cuenta*/
                    AsyncStorage.getAllKeys().then(keys => AsyncStorage.multiRemove(keys)).then(() => setLogin(false));
                    window.location.href = '/';
                }
                return Promise.reject(err);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    // No need to render anything here!
    return null;
}
