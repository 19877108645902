import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';


/**
 * Recoge las categorias de los articulos
 * */
export const getCategoriesPost = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/post_categories.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los articulos d euna categoria
 * */
export const getPostsOfCategory = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/category_posts/${id}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los articulos
 * */
export const getPosts = async () => {
    console.log('TOKEN', await AsyncStorage.getItem('token'))
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/posts.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge un articuo por id
 * */
export const getPostById = async (idPost) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/posts/${idPost}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crea un nuevo canal
 * */
export const createChannel = async (name, description, maxUsers, price, chatActive, category, privateChannel, freeSessions) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        name: name,
        description: description,
        max_users: maxUsers,
        growpsy_product_id: price,
        chat_active: chatActive,
        channel_category_id: category,
        is_private: privateChannel,
        free_sessions: freeSessions
    }
    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/channels.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Actualiza un canal
 * */
export const updateChannel = async (idChannel, name, description, maxUsers, price, chatActive, category, privateChannel, freeSessions) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    console.log('PRICE', category)

    const data = {
        name: name,
        description: description,
        max_users: maxUsers,
        growpsy_product_id: price,
        has_chat: chatActive,
        channel_category_id: category,
        is_private: privateChannel,
        free_sessions: freeSessions
    }
    return new Promise((resolve, reject) => {
        axios.patch(`https://admin.growpsy.com/channels/${idChannel}.json`, data, { headers }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las categorias de canales
 * */
export const getChannels = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/channel_categories.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las categorias de canales
 * */
export const getChannel = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/channels/${id}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los canales por id de categoria
 * */
export const getChannelsByCategoryId = async (idCategory) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/channels.json?channel_category_id=${idCategory}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los canales para el slide de la home
 * */
export const getChannelsHome = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/channels.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Trae los usuarios a los que puedes invitar a un canal
 * */
export const getUsersGuests = async (idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/get_patients_doctors.json?channel_id=${idChannel}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Trae los miembros de un canal
 * */
export const getMembersOfChannel = async (idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/get_channel_patients/${idChannel}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Elimina un miembro de un canal en concreto
 * */
export const deleteMemberFromChannel = async (idChannel, idMember) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    const data = {
        channel_id: idChannel,
        user_id: idMember
    }
    return new Promise((resolve, reject) => {
        axios.delete(`https://admin.growpsy.com/remove_user_from_channel`, {data, headers})
        .then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crea una invitacion para un canal
 * */
export const createInvitation = async (idTarget, idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    const data = {
        target_id: idTarget,
        channel_id: idChannel
    }
    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/channel_invitations.json', data,  {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Trae todos tus chats privados
 * */
export const getPrivateChats = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token'),
        'Cache-Control': 'no-cache'
    }


    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/my_private_conversations.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crear un nuevo chat
 * */
export const createPrivateChat = async (idTarget) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    const data = {
        target_id: idTarget
    }
    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/create_private_conversation.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Bloquea un chat
 * */
export const blockChat = async (idChat) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    const data = {
        id: idChat
    }
    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/block_conversation.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Desbloquea un chat
 * */
export const unblockChat = async (idChat) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }
    const data = {
        id: idChat
    }
    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/unblock_conversation.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crea una nueva sesion
 * */
export const createSession = async (title, channelId, date, duration) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        title: title,
        channel_id: channelId,
        date: date,
        duration: duration
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/video_sessions.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crea una nueva sesión recurrente
 * */
export const createRecurrentSession = async (title, channelId, startDate, endDate, duration, interval, patternType) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        video_session:{
            title: title,
            channel_id: channelId,
            start_date: startDate,
            end_date: endDate,
            duration: duration,
            interval: interval,
            pattern_type: patternType
        }
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/create_recurrent_session', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Edita una sesion
 * */
export const editSession = async (id, title, date, duration) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        title: title,
        date: date,
        duration: duration
    }

    return new Promise((resolve, reject) => {
        axios.put(`https://admin.growpsy.com/video_sessions/${id}.json`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Elimina una sesion
 * */
export const deleteSession = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.delete(`https://admin.growpsy.com/video_sessions/${id}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * Recoge las sesiones
 * */
export const getSessions = async (idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/get_channel_video_sessions/${idChannel}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Evita que se envie notificaciones push cuando ya estas dentro de la conversacion
 * */
export const noPushNotifications = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        conversation_id: id
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/set_current_conversation.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Trae la imagenes del slider de la home
 * */
export const getHomeCarousel = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/banners.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los cursos a los que estas suscrito
 * */
export const getMyCourses = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/my_courses.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })

}

/**
 * Recoge todos los cursos disponibles
 * */
export const getCourses = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/courses.json', {headers}).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge un curso en especifico a partir de la Id
 * */
export const getCourse = async (courseId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/courses/${courseId}.json`, {headers}).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge una lección en especifico a partir de la Id
 * */
export const getLesson = async (lessonId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/lessons/${lessonId}.json`, {headers}).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge un contenido por id
 * */
export const getContent = async (contentId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/contents/${contentId}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Te unes a un curso
 * */
export const joinCourse = async (idUser, idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        course_id: idChannel
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/user_courses.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Peticion para unirse a un canal (Llegara al back y mandara el correo para pagar por stripe)
 * */
export const requestJoinChannel = async (idUser, idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        user_id: idUser,
        channel_id: idChannel
    }
    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/channel_join_purchases.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Marcar contenidos leccione sy tal como finalizadas
 * */
export const sendFinalizedTask = async (idBlock, idLesson, idContent) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        block_id : idBlock,
        lesson_id: idLesson,
        content_id: idContent
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/set_content_finished.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge todos los eventos
 * */
export const getAllEvents = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/events.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge un evento por id
 * */
export const getEventById = async (idEvent) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/events/${idEvent}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los precios de pago para crear un canal
 * */
export const getPricesChannel = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get('https://admin.growpsy.com/api_channels_products.json', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Descargas el diploma de un curso
 * */
export const downloadCertificate = async (idCourse, idUser) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/generate_certificate.pdf?course_id=2&user_id=45`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Guarda las compras realizadas en la BD
 * */
export const saveBuy = async (idProduct, idCourse, transactionId, receipt) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        growpsy_product_id: idProduct,
        course_id: idCourse,
        transaction_id: transactionId,
        receipt: receipt
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/create_inapp_purchase', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Realiza las compras en stripe (WEB)
 * */
export const buyStripe = async (idProduct, idCourse) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    const data = {
        product_id: idProduct,
        course_id: idCourse
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/create_stripe_payment_link', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Cancelar suscripcion stripe (WEB)
 * */
export const rejectSubscription = async (idProduct, idCourse) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/cancel_growpsy_subscription', {}, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los mensajes que existan en notas
 * */
export const getMessagesNotes = async (idChannel) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/channel_notes.json?channel_id=${idChannel}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Envia un mensaje a notas
 * */
export const sendMessageNotes = async (idChannel, message, image, video, file, audio) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    let data = {
        channel_id: idChannel,
        note: message,
        image: image,
        video: video,
        file: file,
        audio: audio
    }

    return new Promise((resolve, reject) => {
        axios.post('https://admin.growpsy.com/channel_notes.json', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los colores de los nombres de usuario en el chat
 * */
export const getColors = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': await AsyncStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.get(`https://admin.growpsy.com/get_colors/${id}.json`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
