import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    SafeAreaView,
    TextInput, Image, Keyboard, Platform,Dimensions
} from "react-native";
import * as Colors from '../../styles/Colors';
import { login, register } from "../../services/user.service";
import loginStore from '../../store/loginStore';
import { Button } from '../../components/button.component';
import {Input} from '../../components/input.component';
import {useNavigate} from 'react-router-dom';
import {Loader} from '../../components/loader.component';
import {Toast} from '../../components/toast.component';
import {Select} from '../../components/select.component';

const {width} = Dimensions.get('window');

const RegisterScreen = props => {
    const [typeProfile, setTypeProfile] = useState(null);
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [lastname2, setLastname2] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [errorMessageProfile, setErrorMessageProfile] = useState(null);
    const [errorMessageName, setErrorMessageName] = useState(null);
    const [errorMessageLastname, setErrorMessageLastname] = useState(null);
    const [errorMessageEmail, setErrorMessageEmail] = useState(null);
    const [errorMessagePassword, setErrorMessagePassword] = useState(null);
    const [errorMessageRepeatPassword, setErrorMessageRepeatPassword] = useState(null);
    const [loader, setLoader] = useState(false);
    const [toastRegisterError, setToastRegisterError] = useState(false);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const setLogin = loginStore(state => state.setLogin);
    const profileValues = [{label: 'Paciente', key: 'patient'}, {label: 'Psicólogo', key: 'doctor'}];
    const navigate = useNavigate();

    Keyboard.addListener('keyboardWillShow', () => {
        setKeyboardOpen(true);
    });

    Keyboard.addListener('keyboardWillHide', () => {
        setKeyboardOpen(false);
    });

    useEffect(() => {
        setToastRegisterError(false)
    }, [toastRegisterError]);

    //Ejecuta la funcion de registro siempre y cuando todos los campos cumplan con los requisitos
    const registerUser = () => {
        if (validateProfile() === false && validateName() === false && validateLastname() === false && validateEmail() === false && validatePassword() === false) {
            setLoader(true);
            register(typeProfile, name, lastname, lastname2, email, password).then(res => {
                login(email, password).then(resp => {
                    if (typeProfile === 'doctor') {
                        setLogin(true);
                        setLoader(false);
                        navigate('/home')
                    } else {
                        navigate('/formregister', {state: {user_type: typeProfile, name: name, email: email}});
                        setLoader(false);
                    }
                }).catch(err => {
                    console.log('Error el logear al usuario', err);
                    setLoader(false)
                });
            }).catch(err => {
                console.log('Error el registrar un usuario', err);
                setLoader(false);
                setToastRegisterError(true);
            })
        }
    }

    /*Valida el tipo de usuario*/
    const validateProfile = () => {
        if (typeProfile === null) {
            setErrorMessageProfile('Selecciona un tipo de usuario.')
            return true;
        } else {
            return false;
        }
    }

    /*Valida el nombre del usuario*/
    const validateName = () => {
        if (name === '') {
            setErrorMessageName('Campo obligatorio.');
            return true;
        } else {
            return false;
        }
    }

    /*Valida el apellido del usuario*/
    const validateLastname = () => {
        if (lastname === '') {
            setErrorMessageLastname('Campo obligatorio.');
            return true;
        } else {
            return false;
        }
    }

    /*valida el email del usuario*/
    const validateEmail = () => {
        const regexEmail =  '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';
        const reg = new RegExp(regexEmail);
        if (email === '') {
            setErrorMessageEmail('Campo obligatorio.');
            return true;
        } else if (!reg.test(email)) {
            setErrorMessageEmail('Email invalido.');
            return true;
        } else {
            return false;
        }
    }

    /*Valida la contraseña del usuario*/
    const validatePassword = () => {
        if (password === '') {
            setErrorMessagePassword('Campo obligatorio.');
            return true;
        } else if (password !== passwordRepeat) {
            setErrorMessagePassword('Las contraseñas deben ser iguales.');
            setErrorMessageRepeatPassword('Las contraseñas deben ser iguales.');
            return true;
        } else if (password.length < 8) {
            setErrorMessagePassword('La contraseña debe tener más de 8 caracteres.');
            return true;
        } else {
            return false;
        }
    }

    return(
        loader ?
            <Loader style={{flex: 1}}/>
            :
            <SafeAreaView style={styles.safeArea}>
                <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                    <View style={[styles.container, (Platform.OS === 'ios' && keyboardOpen === true) && {paddingBottom: 280}]}>
                        <View style={styles.containerImage}>
                            <Image style={styles.image} resizeMode={'contain'} source={require('../../assets/growpsyApaisado.png')}/>
                        </View>
                        <Text style={styles.title}>Crear cuenta</Text>
                        <View style={styles.containerSubtitle}>
                            <Text style={styles.subtitle}>Datos personales</Text>
                        </View>

                        <Select
                            title={'Perfil'}
                            values={profileValues}
                            onChangeValue={value => {
                                setErrorMessageProfile(null);
                                setTypeProfile(value);
                            }}
                            error={errorMessageProfile}
                        />

                        <Input style={styles.input} title={'Nombre'} value={name} error={errorMessageName} getValue={(value) => {
                            setErrorMessageName(null);
                            setName(value);
                        }}/>
                        <Input style={styles.input} title={'Apellido'} value={lastname} error={errorMessageLastname} getValue={(value) => {
                            setErrorMessageLastname(null);
                            setLastname(value);
                        }}/>
                        <Input style={styles.input} title={'Segundo apellido'} value={lastname2} getValue={(value) => {setLastname2(value);}}/>

                        <View style={styles.containerSubtitle}>
                            <Text style={styles.subtitle}>Información de tu cuenta</Text>
                        </View>

                        <Input title={'Email'} value={email} keyboardType={'email-address'} error={errorMessageEmail} getValue={(value) => {
                            setErrorMessageEmail(null);
                            setEmail(value.replace(/\s/g, ''));
                        }}/>
                        <Input style={styles.input} title={'Contraseña'} error={errorMessagePassword} secure={true} value={password} getValue={(value) => {
                            setErrorMessagePassword(null);
                            setPassword(value);
                        }}/>
                        <Input style={styles.input} title={'Repetir contraseña'} error={errorMessageRepeatPassword} secure={true} value={passwordRepeat} getValue={(value) => {
                            if (value === password){
                                setErrorMessagePassword(null);
                                setErrorMessageRepeatPassword(null)
                            }
                            setPasswordRepeat(value);
                        }}/>

                        <Button style={styles.button} primary={true} text={'Crear cuenta'} press={() => registerUser()}/>

                        <TouchableOpacity onPress={() => navigate('/')}>
                            <Text>¿Ya tienes cuenta en Growpsy? <Text style={styles.colorLogIn}>Iniciar sesión</Text></Text>
                        </TouchableOpacity>

                    </View>
                </ScrollView>
                <Toast message={'El correo con el que intentas registrate ya existe.'} visible={toastRegisterError}/>
            </SafeAreaView>
    )
}

export default RegisterScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 50
    },
    containerImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 90,
        marginBottom: 30
    },
    image: {
        width: width <= 420 ? '90%' : (width <= 820 ? '50%' : '30%'),
        height: 80
    },
    title: {
        color: '#2D2E2F',
        fontSize: 20,
        fontWeight: 'bold'
    },
    containerSubtitle: {
        width: '90%',
        marginTop: 30,
        marginBottom: 20
    },
    subtitle: {
        color: '#2D2E2F',
        fontSize: 17,
        fontWeight: 'bold'
    },
    input: {
        marginTop: 10
    },
    button: {
        width: '90%',
        marginTop: 30,
        marginBottom: 20
    },
    colorLogIn: {
        color: Colors.default.COLORS.primary
    }
})
