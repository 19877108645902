import React from 'react'
import {StyleSheet, TouchableOpacity, Text, Image, ImageBackground, View} from 'react-native';
//import * as Colors from '../styles/Colors';
//import Feather from "react-native-vector-icons/Feather";

export const Channel = props => {

  {/*<TouchableOpacity
        style={[styles.containerCompact, props.color !== null && props.color !== undefined ? { backgroundColor: props.color } : { backgroundColor: Colors.default.COLORS.primary }]}
        activeOpacity={0.7} onPress={props.press}>
        <ImageBackground style={styles.backgroundImage} blurRadius={4} resizeMode={"cover"}
                         source={{ uri: `https://admin.growpsy.com/${props.image}` }}>
          <Text style={styles.textCompact}>{props.title}</Text>
        </ImageBackground>
      </TouchableOpacity>*/}
  return(
    props.compact ?
      <TouchableOpacity style={styles.containerCompact2} activeOpacity={0.7} onPress={props.press}>
        <Image style={styles.imageCompact} resizeMode={'contain'} source={{uri: `https://admin.growpsy.com/${props.image}`}}/>
        <View style={{paddingHorizontal: 10}}>
          <View style={{flexDirection: 'row', alignItems: 'flex-start', marginTop: 5}}>
            {/*props.price > 0 && <Feather style={{marginTop: 2, marginRight: 5 }} name={"star"} color={'#D38100'} size={15} />*/}
            <Text style={styles.title}>{props.title}</Text>
          </View>
          <Text style={{color: props.color, fontFamily: 'Montserrat-Medium.ttf'}}>{props.category}</Text>
        </View>
      </TouchableOpacity>
      :
      <TouchableOpacity style={[styles.containerLarge, props.color !== null && props.color !== undefined ? {backgroundColor: props.color} : {backgroundColor: '#99bfe3'}]} activeOpacity={0.7} onPress={props.press}>
        <Text style={styles.text}>{props.title}</Text>
        <Image style={styles.imageLarge} source={{uri: `https://admin.growpsy.com${props.image}`}}/>
      </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  containerCompact: {
    overflow: 'hidden',
    width: 120,
    height: 120,
    marginRight: 10,
    borderRadius: 6,
    backgroundColor: '#99bfe3'
  },
  backgroundImage: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 20
  },
  containerCompact2: {
    backgroundColor: '#FFFFFF',
    width: 150,
    minHeight: 185,
    borderRadius: 6,
    marginRight: 10,
    paddingBottom: 7,
    borderWidth: 1,
    borderColor: '#CCCCCC',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  imageCompact: {
    width: 150,
    height: 100,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6
  },
  title: {
    flex: 1,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  containerLarge: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    height: 56,
    borderRadius: 6,
    marginBottom: 10,
    paddingLeft: 16
  },
  textCompact: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'Montserrat-Medium.ttf'
  },
  text:{
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontWeight: 500
  },
  imageLarge: {
    width: '60%',
    height: '100%'
  }
})
