import React, { useEffect, useState } from "react";
import { StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity, Text, Modal, Alert, Dimensions} from "react-native";
import * as Colors from "../styles/Colors";
import {Session} from '../components/Session.component';
import { ButtonNewChannel } from "../components/buttonNewChannel.component";
import userStore from '../store/userStore';
import {getSessions, deleteSession} from '../services/data.service';
import {joinSession} from '../services/chat.service';
import {getSessionToken} from '../services/livekit.service';
import {useParams, Link, useNavigate, useLocation} from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from 'moment';
import typeHeaderStore from '../store/typeHeaderStore';
import ModalStartSession from '../components/modalStartSession.component';
//import NetInfo from "@react-native-community/netinfo";

const {width} = Dimensions.get('window');

const SessionsScreen = props => {
    const [user, setUser] = useState(null);
    const [sessions, setSessions] = useState([])
    const [type, setType] = useState(true);
    const [isConnected, setIsConnected] = useState();
    const [showModal, setShowModal] = useState(false);
    const [idChannel, setIdChannel] = useState(null);
    const [idSession, setIdSession] = useState(null);
    const [idRecurrent, setIdRecurrent] = useState(null);
    const [token, setToken] = useState(null);

    const setTypeHeader = typeHeaderStore(state => state.setTypeHeader);

    let actualDate = new Date();

    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    /*Al entrar a la vista*/
    useEffect(() => {
        /*Selecciona el tipo de cabecera qu eva tener la vista*/
        setTypeHeader('user');
        /*LLama a la funcion para recoger el usuario*/
        getterUser();

        /*LLama a la funcion para recoger la sesiones*/
        getAllSessions();

        /*Recoge el token del usuario*/
        AsyncStorage.getItem('token').then(res => {
            setToken(res?.split('Bearer ').pop());
        })
    }, [])

    /*Recoge el usuario*/
    const getterUser = () => {
        AsyncStorage.getItem('user').then(user => {
            console.log('USER', user)
            setUser(JSON.parse(user))
        });
    }

    /*Recoge todas la sesiones de un grupo*/
    const getAllSessions = () => {
        getSessions(id).then(res => {
            setSessions(res.data.video_sessions);
            console.log('Sesiones recogidas con exito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger la sesiones', err);
        })
    }

    const incrementTimeToSessionDate = (sessionDate, minutes) => {
        let session = new Date(sessionDate);
        session.setTime(session.getTime() + minutes * 60 * 1000);
        return session;
    }

    /*Une al usuario a una sesion*/
    const joinUserSession = (idSession, idChannel) => {
        /*LLama al endpoint para indicarle al back que usuario se a unido a la sesion*/
        // joinSession(user.user.id, idSession).then(res => {

            getSessionToken(idSession).then(res => {
                setShowModal(false);
                /*Navega a la vista del chat de la sesion*/
                window.open(`/videochat/?token=${res.data.token}`, '_blank')
                //navigate(`/chats/${idChannel}/CH/${idSession}`, {state: {colors: location.state.colors}});
            }).catch(err => {
                setShowModal(false);
                alert('Error al unirse a la sesión, por favor intentalo más tarde.')
                console.log('ERROR AL UNIRSE A LA SESION', err);
            })

        // }).catch(err => {
        //     setShowModal(false);
        //     alert('Error al unirse a la sesión, por favor intentalo más tarde. AAAAA')
        //     console.log('ERROR AL UNIRSE A LA SESION', err);
        // })
    }

    /*Muestra un alert de confirmacion de si quieres eliminar la sesion*/
    const removeSession = (sessionId) => {
        if (window.confirm('¿Desea eliminar la sesión?')) {
            /*Elimina la sesion por id de sesion*/
            deleteSession(sessionId).then(res => {
                alert('Sesión eliminada con exito.')
                getAllSessions();
            }).catch(err => {
                alert('No se a podido eliminar la sesión, por favor intentalo más tarde.')
            })
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            {showModal &&
            <ModalStartSession
                idSession={idSession}
                token={token}
                press={() => {

                    joinUserSession(idSession, idChannel);
                }}
            />
            }
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    <View style={styles.containerTypes}>
                        <TouchableOpacity style={type ? styles.type : styles.typeNoSelect} onPress={() => setType(true)}>
                            <Text style={type ? styles.typeText : styles.typeTextNoSelect}>
                                Próximas
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={type ? styles.typeNoSelect : styles.type} onPress={() => setType(false)}>
                            <Text style={type ? styles.typeTextNoSelect : styles.typeText}>
                                Anteriores
                            </Text>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.containerSessions}>
                        {sessions.length > 0 ?
                            sessions.map(session => {
                                console.log('Session', session);
                                if (type === true && !session.past) {
                                    return(
                                        <Session
                                            name={session.title}
                                            opened={session.opened}
                                            date={session.date}
                                            creator={session.creator}
                                            idSession={session.id}
                                            token={token}
                                            channel={session.channel_name}
                                            isView={type}
                                            start={session.date}
                                            duration={session.duration}
                                            idRecurrent={session.recurrent_session_id}
                                            typeUser={user.user.user_type}
                                            press={() => {
                                                setIdSession(session.id);
                                                setIdChannel(session.channel_id);
                                                setShowModal(true);
                                                setIdRecurrent(session.recurrent_session_id);
                                                //joinUserSession(session.id, session.channel_id)
                                            }}
                                            pressEdit={() => navigate(`/editSession/${session.id}`, {state: {idChannel: id, name: session.title, duration: session.duration, date: session.date, idRecurrent: session.recurrent_session_id, colors: location.state.colors}})}
                                            pressDelete={() => removeSession(session.id)}/>
                                    )
                                } else if (type === false && session.past) {
                                    return(
                                        <Session name={session.title} date={session.date} creator={session.creator} channel={session.channel_name} isView={type} />
                                    )
                                }
                            })
                            :
                            <Text style={{textAlign: 'center', marginTop: 50}}>Todavia no tienes sesiones</Text>
                        }
                    </View>

                </View>
            </ScrollView>

            {(user !== null && user.user.user_type === 'doctor') &&
                <ButtonNewChannel style={styles.buttonNewSession} press={() => navigate(`/newSession/${id}`, {state: {colors: location.state.colors}})}/>
            }
        </SafeAreaView>
    )
}

export default SessionsScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        paddingBottom: 70,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    containerTypes: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    type: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
        borderBottomWidth: 1,
        borderBottomColor: Colors.default.COLORS.primary
    },
    typeNoSelect: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
    },
    typeText: {
        color: Colors.default.COLORS.primary,
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    typeTextNoSelect: {
        color: Colors.default.COLORS.text_darkLight,
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    containerSessions: {
        width: '90%'
    },
    buttonNewSession: {
        position: 'absolute',
        right: '5%',
        bottom: 120
    }
})
