import React, { useEffect, useRef, useState } from "react";
import {StyleSheet, SafeAreaView, ScrollView, View, Image, Text, BackHandler, Platform, Dimensions} from 'react-native';
import RenderHtml from 'react-native-render-html';
import * as Colors from '../styles/Colors';
import {getPostById} from '../services/data.service';
import {useParams} from 'react-router-dom';

const {width, height} = Dimensions.get('window');

const ArticleScreen = props => {
    const [fullScreen, setFullScreen] = useState(false);
    const [article, setArticle] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        getPost();
    }, []);

    /*Recoge un post especifico por id*/
    const getPost = () => {
        getPostById(id).then(res => {
            console.log('Post recogido con exito', res.data)
            setArticle(res.data)
        }).catch(err => {
            console.log('Error al recoger el post', err);
        })
    }

    return(
        article !== null &&
        <SafeAreaView style={[styles.safeArea, fullScreen === true ? {backgroundColor: '#000000'} : {backgroundColor: '#FFFFFF'}]}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    {
                        article.image.url !== null ?
                            <Image style={styles.image} resizeMode={'cover'} source={{ uri: `https://admin.growpsy.com${article.image.url}` }} />
                            :
                            (
                                article.video.url !== null ?
                                    <View style={styles.containerVideo}>
                                        {/*<Video style={styles.video} controls paused={true}
                                               source={{ uri: `https://admin.growpsy.com${article.video.url}` }} />*/}
                                       <video>
                                           <source src={`https://admin.growpsy.com${article.video.url}`}/>
                                       </video>
                                    </View>
                                    :
                                    null
                            )
                    }

                    <View style={styles.containerText}>
                        <Text style={styles.title}>{article.title}</Text>
                        <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{article.created_at}</Text>
                        <RenderHtml  style={{fontFamily: 'Montserrat', fontWeight: 500}} source={{ html: article.content }} />
                    </View>
                </View>

                {
                    article.image.url !== null ?
                        (
                            article.video.url !== null ?
                                    <video style={{marginTop: 30}} controls>
                                        <source src={`https://admin.growpsy.com${article.video.url}`}/>
                                    </video>
                                :
                                null
                        )
                        :
                        null
                }
            </ScrollView>
        </SafeAreaView>

    )
}

export default ArticleScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        paddingBottom: 70,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: 400
    },
    containerText: {
        width: '100%'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 5,
        fontFamily: 'Montserrat'
    },
    containerVideo: {
        width: '90%',
        height: 200
    },
    video: {
        width: '90%',
        height: '100%',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    containerVideoFullScreen: {
        position: 'absolute',
        flex: 1,
        width: '100%',
    },
    videoFullScreen: {
        //position: 'absolute',
        width: '100%',
        height: '90%',
        ...Platform.select({
            ios: {
                maxHeight: '45%'
            },
            android: {
                maxHeight: '45%'
            }
        })

        //marginTop: '55%'
    }
})
