import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import * as Colors from '../styles/Colors';
import {Button} from './button.component';
import {Link, useNavigate} from "react-router-dom";

const ModalStartSession = (props) => {

    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGl0eSI6IiIsIm5hbWUiOiJteSBuYW1lLTIiLCJ2aWRlbyI6eyJyb29tQ3JlYXRlIjpmYWxzZSwicm9vbUxpc3QiOmZhbHNlLCJyb29tUmVjb3JkIjpmYWxzZSwicm9vbUFkbWluIjpmYWxzZSwicm9vbUpvaW4iOnRydWUsInJvb20iOiJteS1yb29tIiwiY2FuUHVibGlzaCI6dHJ1ZSwiY2FuU3Vic2NyaWJlIjp0cnVlLCJjYW5QdWJsaXNoRGF0YSI6dHJ1ZSwiY2FuUHVibGlzaFNvdXJjZXMiOltdLCJjYW5VcGRhdGVPd25NZXRhZGF0YSI6ZmFsc2UsImluZ3Jlc3NBZG1pbiI6ZmFsc2UsImhpZGRlbiI6ZmFsc2UsInJlY29yZGVyIjpmYWxzZSwiYWdlbnQiOmZhbHNlfSwibWV0YWRhdGEiOiIiLCJzaGEyNTYiOiIiLCJzdWIiOiJpZGVudGl0eS0yIiwiaXNzIjoiQVBJaFEyTEFiY3pDOW42IiwibmJmIjoxNzE1MDA0MzU3LCJleHAiOjE3MTUwMjU5NTd9.wu4_2Qgf2S2lDuLKAHGpkGnLpj3nmi7cMmK3ql33w_0'
    const navigate = useNavigate();

    return (
        <View style={styles.containerModal}>
            <View style={styles.container}>
                <View style={styles.headerModal}>
                    <Text style={{fontWeight: 'bold'}}>Atención:</Text>
                </View>

                <View style={styles.bodyModal}>
                    <Text style={styles.text}>Estás a punto de acceder a una sala de videochat en Growpsy.</Text>
                    <Text style={styles.text}>Para garantizar el correcto funcionamiento durante toda la sesión,
                        asegúrate de estar en un lugar con buena conexión a internet, y tener
                        la batería bien cargada o el dispositivo conectado a la red eléctrica.</Text>
                    <Text style={styles.text}>Aunque Growpsy puede funcionar en cualquier navegador, recomendamos el uso de Google Chrome.</Text>
                    <Text style={styles.text}>Si experimentas algún problema, indícalo al personal de Growpsy.</Text>
                    <a href={'mailto:admin@growpsy.es'} style={styles.textLink}>admin@growpsy.es</a>
                </View>

                <View style={styles.footerModal}>
                    <Button
                        press={props.press}
                        style={styles.button} 
                        text="Ok!"
                        primary
                    />  

                    {/* <a
                        style={styles.button}
                        href={`http://localhost:3000/?token=${token}`}
                        target={'_blank'}
                        onClick={props.press}
                    >
                        Ok!
                    </a> */}
                </View>
            </View>
        </View>

    )
}

export default ModalStartSession;

const styles = StyleSheet.create({
    containerModal: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '94.5%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 5
    },
    container: {
        borderRadius: 10,
        maxWidth: 500,
        backgroundColor: Colors.default.COLORS.background_light
    },
    headerModal: {
        borderTopStartRadius: 10,
        borderTopEndRadius: 10,
        padding: 20,
        backgroundColor: Colors.default.COLORS.primary
    },
    bodyModal: {
        padding: 20
    },
    footerModal: {
        padding: 20
    },
    text: {
      marginVertical: 10,
    },
    textLink: {
      marginVertical: 10,
        color: '#000000',
        fontWeight: 'bold',
        fontSize: 14
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: 100,
        height: 40,
        backgroundColor: Colors.default.COLORS.primary,
        color: Colors.default.COLORS.text_light
    }
})

