import React, {useEffect, useState} from 'react';
import { StyleSheet, ScrollView, View, TouchableOpacity, Text, Modal, Alert } from "react-native";
import * as Colors from '../styles/Colors';
import {Searcher} from '../components/searcher.component';
import {Loader} from '../components/loader.component';
import {Member} from '../components/member.component';
import {getUsersGuests,
    createInvitation} from '../services/data.service';
import {useParams} from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { normalizeString } from '../utils/formatters';

const AddMembersScreen = () => {
    const [allUsers, setAllUsers] = useState(null);
    const [users, setUsers] = useState(null);
    const [search, setSearch] = useState('');
    const [rechargeUsers, setRechargeUsers] = useState(false);

    const {id} = useParams();

    /*Recoge los usuarios a lo que se puede invitar en ese canal*/
    useEffect(() => {
        getUsersGuests(id).then(res => {
            setUsers(res.data.users);
            setAllUsers(res.data.users);
        }).catch(err => {
            console.log('ERROR al recoger los usuarios para invitar', err);
        })
    }, [rechargeUsers]);

    /*Filtra los usuarios*/
    const searchUsers = (value) => {
        if (value !== '') {
            let newUsers = users.filter(user => {
                if (normalizeString(user.full_name).includes(normalizeString(value))) return user;
            })
            if (newUsers.length > 0) setUsers(newUsers);
            else setUsers(allUsers);
        }
        else {
            setUsers(allUsers);
        }
    }

    /*Muestra un alert para confirmar que quieres invitar a este usuario*/
    const alertSendInvitation = (idUser, name) => {
        if (window.confirm(`¿Desea invitar a ${name} a este grupo?`)) {
            /*LLama a la funcion para invitar al usuario*/
            sendInvitation(idUser);
        } else {
            console.log('Envio de solicitud cancelado')
        }
    }

    /*Invita al usuario*/
    const sendInvitation = (idTarget) => {
        createInvitation(idTarget, id).then(res => {
            /*Actualiza el estado para volver a cargar los usuario que se pueden invitar a este grupo*/
            setRechargeUsers(!rechargeUsers);
            /*Alert para confirmar que se ha invitado al usuario correctamente*/
            alert('Invitacion enviada con exito.')
            console.log('Invitacion creada con exito', res.data);
        }).catch(err => {
            alert('No se ha podido enviar la invitacion por favor intentalo más tarde.')
            console.log('ERROR al crear la invitacion', err);
        })
    }

    return(
        <View style={styles.container}>
            <View style={{flex: 1}}>
                <ScrollView>
                    <View style={{alignItems: 'center'}}>
                        <Searcher
                            placeholder={'Buscar usuarios'}
                            value={search}
                            getValue={async (value) => {
                                await setSearch(value);
                                await searchUsers(value);
                            }}
                        />

                        {users !== null ?
                            users.map(user => {
                                return(
                                    <Member image={user.image} name={`${user.name} ${user.lastname}`} type={user.user_type} guest={user.invited} press={() => alertSendInvitation(user.id, `${user.name} ${user.lastname}`)}/>
                                )
                            })
                            :
                            <Loader style={{flex: 1, height: 500}}/>
                        }
                    </View>
                </ScrollView>
            </View>


        </View>
    )
}
export default AddMembersScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        paddingBottom: 70,
        backgroundColor: Colors.default.COLORS.background_light
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 50
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: '600'
    },
    containerClose: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 20
    }
})
