import { Routes, Route, Navigate } from 'react-router-dom';
import HomeScreen from '../views/HomeScreen';
import ProfileScreen from '../views/ProfileScreen';
import MySubscriptionsScreen from '../views/MySubscriptionsScreen';
import CategoriesArticleScreen from '../views/CategoriesArticleScreen';
import ArticlesScreen from '../views/ArticlesScreen';
import ArticleScreen from '../views/ArticleScreen';

import ChannelsScreen from '../views/ChannelsScreen';
import ChannelOptionsScreen from '../views/ChannelOptionsScreen';
import MyChannelOptionsScreen from '../views/MyChannelOptionsScreen';
import ChannelOptionScreen from '../views/ChannelOptionScreen';
import NewChannelScreen from '../views/NewChannelScreen';
import SessionsScreen from '../views/SessionsScreen';
import NewSessionScreen from '../views/NewSessionScreen';
import MembersScreen from '../views/MembersScreen';
import AddMembersScreen from '../views/AddMembersScreen';
import NotesScreen from '../views/NotesScreen';

import PrivateChatsScreen from '../views/PrivateChatsScreen';
import PrivateChatDetailScreen from '../views/PrivateChatDetailScreen';

import EventsScreen from '../views/EventsScreen';
import EventScreen from '../views/EventScreen';

import CoursesScreen from '../views/CoursesScreen';
import CourseScreen from '../views/CourseScreen';
import SearchCoursesScreen from '../views/SearchCoursesScreen';
import LessonScreen from '../views/LessonScreen';
import ContentCourseScreen from '../views/ContentCourseScreen';
import VideochatScreen from '../views/VideochatScreen';

function Router() {
console.log("ROUTER")        /*Inicializamos las rutas dentro de la aplicacion una vez realizada la autenticacion*/
    return (
        <Routes>
            <Route path='/' element={<HomeScreen />}/>
            <Route path='/home' element={<HomeScreen />} />
            <Route path='/profile' element={<ProfileScreen />} />
            <Route path='/subscription' element={<MySubscriptionsScreen />} />
            <Route path='/categoriesArticles' element={<CategoriesArticleScreen />} />
            <Route path='/articles/:id' element={<ArticlesScreen />} />
            <Route path='/article/:id' element={<ArticleScreen />} />


            <Route path={'/channels'} element={<ChannelsScreen/>}/>
            <Route path={'/channels/:id'} element={<ChannelOptionsScreen/>}/>
            <Route path='/myChannels' element={<MyChannelOptionsScreen />} />
            <Route path='/channel/:id' element={<ChannelOptionScreen />} />
            <Route path='/newChannel' element={<NewChannelScreen />} />
            <Route path='/editChannel/:id' element={<NewChannelScreen />} />
            <Route path='/sessions/:id' element={<SessionsScreen />} />
            <Route path='/newSession/:idChannel' element={<NewSessionScreen />} />
            <Route path='/editSession/:id' element={<NewSessionScreen />} />
            <Route path='/members/:id' element={<MembersScreen />} />
            <Route path='/addMembers/:id' element={<AddMembersScreen />} />
            <Route path='/notes/:id' element={<NotesScreen />} />
            <Route path='/videochat' element={<VideochatScreen />} />



            <Route path='/chats' element={<PrivateChatsScreen />} />
            <Route path='/chats/:id/:type' element={<PrivateChatDetailScreen />} />
            <Route path='/chats/:id/:type/:idSession' element={<PrivateChatDetailScreen />} />
            <Route path='/chatMember/:id/:type/:idMember' element={<PrivateChatDetailScreen />} />


            <Route path={'/events'} element={<EventsScreen/>}/>
            <Route path={'/event/:id'} element={<EventScreen/>}/>


            <Route path='/courses' element={<CoursesScreen />} />
            <Route path='/course/:id' element={<CourseScreen />} />
            <Route path='/searchCourses' element={<SearchCoursesScreen />} />
            <Route path='/lesson/:id' element={<LessonScreen />} />
            <Route path='/content/:id' element={<ContentCourseScreen />} />

            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    )
}

export default Router;
