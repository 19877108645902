import React, {useLayoutEffect, useEffect, useState} from 'react';
import { StyleSheet, SafeAreaView, ScrollView, View, Dimensions, Text } from "react-native";
import * as Colors from "../styles/Colors";
import userStore from '../store/userStore';
import tabBarNotifStore from '../store/tabBarNotifStore';
import {Searcher} from '../components/searcher.component';
import {Article} from '../components/article.component';
import {Loader} from '../components/loader.component';
import {Toast} from '../components/toast.component';
//import {ButtonNewChannel} from '../components/buttonNewChannel';
import {getMyChannels} from '../services/user.service';
import {getChannelsByCategoryId} from '../services/data.service';
import {useParams, Link} from 'react-router-dom';

const {width} = Dimensions.get('window');


const ChannelOptionScreen = props => {
    const [allChannels, setAllChannels] = useState(null);
    const [channels, setChannels] = useState(null);
    const [search, setSearch] = useState('');
    const user = userStore(state => state.user);
    const setChannelsNotif = tabBarNotifStore(state => state.setChannels);

    const {id} = useParams();

    /*Recoge los grupos de una categoria por id al entrar a la vista*/
    useEffect(() => {
        getChannelsByCategoryId(id).then(res => {
            setAllChannels(res.data);
            setChannels(res.data);
            console.log('getChannelsByCategoryId', res.data);
        }).catch(err => {
            console.log('ERROR al recoger los canales', err);
        })
    }, []);

    /*FIltra los grupos de una categoria*/
    const searchChannels = (value) => {
        if (value !== '') {
            let newChannels = channels.filter(channel => {
                if (channel.name.toLowerCase().includes(value.toLowerCase())) return channel;
            })

            if (newChannels.length > 0) setChannels(newChannels);
            else setChannels(allChannels);
        }
        else {
            setChannels(allChannels);
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>

                    {channels !== null ?
                        <>
                            <Searcher
                                placeholder={'Busca tu grupo'}
                                value={search}
                                getValue={async (value) => {
                                    await setSearch(value);
                                    await searchChannels(value);
                                }}
                            />
                            {channels.length > 0 ?
                                <View style={styles.containerArticles}>
                                    {channels.map(channel => {
                                        return (
                                            <Link style={{width: width <= 420 ? '100%' : '33.3%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}} to={`/channel/${channel.id}`}>
                                                <Article style={styles.article}
                                                         image={channel.channel_image}
                                                         title={channel.name}
                                                         subtitle={channel.description}
                                                         price={channel.price}
                                                         size={'big'}
                                                         textButton={'Ver grupo'}
                                                />
                                            </Link>
                                        );
                                    })}
                                </View>
                                :
                                <Text>No hay canales activos en este momento</Text>
                            }
                        </>
                        :
                        <Loader style={{flex: 1, height: 500}}/>
                    }

                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default ChannelOptionScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 50
    },
    containerArticles: {
        flexDirection: width <= 420 ? 'column' : 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '90%',
        marginBottom: 30
    },
    article: {
        marginTop: 20
    },
    buttonNewChannel: {
        position: 'absolute',
        right: 15,
        bottom: 15
    }
})
