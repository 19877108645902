/*
  NORMALIZE STRING TO LOWER CASE & DELETE ACCENT MARKS
*/
export const normalizeString = (cadena) => {
  return cadena
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return "";

  return new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
