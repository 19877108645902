const Constants = {
  COLORS: {
    primary: '#99bfe3',
    text_dark: '#2D2E2F',
    text_darkLight: '#87898E',
    text_light: '#FFFFFF',
    background_light: '#FFFFFF',
    background_lightDark: '#E5E5E5',
    background_dark: '#000000',
    background_sender: '#E8E8FF',
  }
}

export default Constants;
