import React, { useEffect, useLayoutEffect, useState } from "react";
import {
    StyleSheet,
    SafeAreaView,
    ScrollView,
    View,
    Image,
    Text,
    TouchableOpacity,
    Dimensions,
    Alert,
    Modal,
    PermissionsAndroid,
    Platform
} from "react-native";
import {Button} from '../components/button.component';
import {Toast} from '../components/toast.component';
import {sendFinalizedTask} from '../services/data.service';
import * as Colors from "../styles/Colors";
import {ReactComponent as Document} from '../assets/document.svg';
import {ReactComponent as DownloadOutline} from '../assets/downloadOutline.svg';
import {getContent} from '../services/data.service';
import {useParams, useNavigate} from 'react-router-dom';

const {width} = Dimensions.get('window');

const ContentScreen = ({navigation, route}) => {
    const [content, setContent] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    const [modalImage, setModalImage] = useState(false);
    const [toastDownloadFile, setToastDownloadFile] = useState(false);
    const [toastErrorDownloadFile, setToastErrorDownloadFile] = useState(false);
    const [toastNoPermissions, setToastNoPermissions] = useState(false);

    const [videoVimeo, setVideoVimeo] = useState(null)

    const {id} = useParams();
    const navigate = useNavigate();

    /*Recoge un contenido al entrar a la vista*/
    useLayoutEffect(() => {
        getContent(id).then(res => {
            console.log('Contenido recogido con exito', res);
            setContent(res.data);
        }).catch(err => {
            console.log('ERROR al recoger el contenido', err);
        })
    }, []);

    useEffect(() => {
        setToastDownloadFile(false);
        setToastErrorDownloadFile(false);
        setToastNoPermissions(false);
    }, [toastDownloadFile, toastErrorDownloadFile, toastNoPermissions])

    /*Marca un contenido como filaizado*/
    const sendTaskProgress = () => {
        sendFinalizedTask(null, null, id).then(res => {
            setCompleted(true);
            alert('Leccion finalizada con exito.')
            //navigation.goBack();
            console.log('respuesta enviada con exito', res);
        }). catch(err => {
            console.log('ERROR al enviar la respuesta', err);
        })
    }

    /*Muestra un alert de confirmacion para finalizar el contenido*/
    const confirmEndContent = () => {
        if (window.confirm("¿Desea finalizar el contenido?")) {
            sendTaskProgress();
        } else {
            console.log('No has marcado la leccion como finalizada')
        }
    }

    /*Al entrar a la vista comprueba si el vidoe que aparece en el contenido esta subido al server o viene de la plataforma (VIMEO)
    * en el caso d evenir de la plataforma de vimeo hace una peticion para recoger el video y poder mostrarlo*/
    useEffect(() => {
        if (content !== null && content.video.url === null && content.vimeo) {
            fetch(`https://player.vimeo.com/video/${content.vimeo}/config`)
                .then(res => res.json())
                .then(res => {
                    console.log('video 1', res.request.files.hls.cdns[res.request.files.hls.default_cdn].url)
                    setVideoVimeo(res.request.files.hls.cdns[res.request.files.hls.default_cdn].url)
                    console.log('video 1', res.video)
                });
        }

    }, [content])

    return(
        content !== null &&
        <SafeAreaView style={styles.safeArea}>

            <ScrollView>
                <View style={styles.container}>
                    <View style={{flexDirection: 'row', alignItems: 'center', width: '90%', marginTop:30, marginBottom: 20}}>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => navigate(`/course/${content.course_id}`)}>
                            <Text style={{fontWeight: 'bold'}}>{content.course_name} </Text>
                        </TouchableOpacity>
                        <Text>/</Text>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => navigate(`/course/${content.course_id}`)}>
                            <Text style={{fontWeight: 'bold'}}> {content.block_name} </Text>
                        </TouchableOpacity>
                        <Text>/</Text>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => navigate(`/lesson/${content.lesson_id}`)}>
                            <Text style={{fontWeight: 'bold'}}> {content.lesson_name} </Text>
                        </TouchableOpacity>
                        <Text>/</Text>
                        <TouchableOpacity activeOpacity={0.7}>
                            <Text style={{fontWeight: 'bold'}}> {content.name} </Text>
                        </TouchableOpacity>
                    </View>

                    {/*Muestra el vidoe del server o el de la plataforma de vimeo segun sea necesario*/}
                    {(content !== null && content.video.url !== null) ?
                        <video style={{width: '90%'}} controls>
                            <source src={`https://admin.growpsy.com${content.video.url}`}/>
                        </video>
                        :
                        (content.vimeo)?
                        <iframe width="90%" height="600"
                        src={`https://player.vimeo.com/video/${content.vimeo}?h=aee925f4ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1`}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title="test player iframe vimeo" />
                        :
                        null
                    }

                    {content.description !== null &&
                        <View style={styles.containerDescription}>
                            <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{content.description}</Text>
                        </View>
                    }

                    {content.image.url !== null &&
                        <a style={{width: '90%'}} href={`https://admin.growpsy.com${content.image.url}`} download>
                            <Image style={styles.image} resizeMode={'contain'} source={{uri: `https://admin.growpsy.com${content.image.url}`}}/>
                        </a>
                    }

                    {(content !== null && content.document.url !== null) &&
                        <a style={{width: '90%', textDecoration: 'none'}} href={`https://admin.growpsy.com/${content.document.url}`} download>
                            <TouchableOpacity style={styles.containerDownload} activeOpacity={0.7}>
                                <Text style={styles.titleDownload}>Contenido descargable</Text>
                                <View style={styles.containerDownloader}>
                                    <View style={styles.containerFile}>
                                        <Document width={30}/>
                                        <Text style={{fontFamily: 'Montserrat', fontWeight: 600}}>{content.document_name}</Text>
                                    </View>
                                    <TouchableOpacity style={{marginLeft: 'auto'}} activeOpacity={0.7}>
                                        <DownloadOutline width={30}/>
                                    </TouchableOpacity>
                                </View>
                            </TouchableOpacity>
                        </a>

                    }

                    {(content !== null && content.completed === false && completed === false) &&
                        <Button
                            style={styles.button}
                            text={"Marcar como finalizado"}
                            primary
                            press={() => confirmEndContent()}
                        />
                    }
                </View>

            </ScrollView>
            <Toast message={'Descarga en curso.'} visible={toastDownloadFile}/>
            <Toast message={'No tienes permisos para descargar archivos.'} visible={toastNoPermissions}/>
        </SafeAreaView>
    )
}

export default ContentScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        paddingBottom: 70,
        backgroundColor: Colors.default.COLORS.background_light,
        position: 'relative'
    },
    modal: {
        flex: 1
    },
    ModalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        paddingHorizontal: 20,
        backgroundColor: Colors.default.COLORS.background_light
    },
    videoFullScreen: {
        width: '100%',
        height: 220,
    },
    imageImageFullScreen: {
        width: '100%',
        height: 300,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginBottom: 70
    },
    video: {
        width: '100%',
        height: 220,
        marginBottom: 30
    },
    containerDescription: {
        width: '90%',
        marginVertical: 30
    },
    image: {
        width: '100%',
        height: 940,
        marginBottom: 30
    },
    containerDownload: {
        width: '100%'
    },
    titleDownload:{
        fontFamily: 'Montserrat',
        fontWeight: 600
    },
    containerDownloader: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginTop: 15,
        paddingVertical: 7,
        paddingHorizontal: 15,
        borderRadius: 6,
        backgroundColor: Colors.default.COLORS.background_lightDark
    },
    containerFile: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        width: '90%',
        marginTop: 20
    },
    bottomButtonsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        width: '100%',
        padding: 10,
        backgroundColor: Colors.default.COLORS.background_light
    }
})
