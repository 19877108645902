import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LiveKitRoom} from '@slashmobility/livekit-components-react';
import '@livekit/components-styles';
import { MyVideoConference } from '../components/videochat.component';


function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const VideochatScreen = () => {

  const [isConnected, setIsConnected] = useState(false)
  const serverUrl = 'wss://growpsyslash-xemgogfv.livekit.cloud';
  let query = useQuery();
  let token = query.get('token');

  const handleOnDisconnected = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('close');
      } else {
        window.close()
      }
  }

  return (
    <>
      <LiveKitRoom
          video={true}
          audio={true}
          token={token}
          serverUrl={serverUrl}
          options={{
            publishDefaults: {
              videoCodec: 'vp9',
              videoEncoding: {
                maxBitrate: '1900000'
              }
            },
          }}
          data-lk-theme="default"
          style={{ height: '100vh' }}
          onConnected={() => setIsConnected(true)}
          onDisconnected={() => handleOnDisconnected()}
      >
        <MyVideoConference isConnected = {isConnected}/>
      </LiveKitRoom>
    </>
  );

}

export default VideochatScreen;

