import React, {useEffect, useState} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Text, Dimensions} from 'react-native';
import * as Colors from "../styles/Colors";
import {getAllEvents} from '../services/data.service';
import {Loader} from '../components/loader.component';
import {Article} from '../components/article.component';
import {Link} from 'react-router-dom';

const {width} = Dimensions.get('window');

const EventsScreen = ({navigation, route}) => {
    const [events, setEvents] = useState(null);

    /*LLama a la funcion al entrar a la vista*/
    useEffect(() => {
        getEvents();
    }, []);

    /*Recoge todos los eventos*/
    const getEvents = () => {
        getAllEvents().then(res => {
            setEvents(res.data);
            console.log('Eventos recogidos con exito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger los eventos', err);
        })
    }

    /*Renderia los eventos para mostrarlos en la vista*/
    const renderEvents = (event) => {
        console.log('EVENT', event);
        return (
            <Link
                key={event.id}
                style={{ width: width <= 420 ? '100%' : '33%', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}
                to={`/event/${event.id}`}
            >
                <Article
                    style={styles.article}
                    image={event.event_image}
                    title={event.title}
                    subtitle={event.description}
                    date={event.date}
                    place={event.place}
                    //price={channel.price}
                    size={'big'}
                />
            </Link>
        )
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView>
                <View style={styles.container}>
                    {events === null ?
                        <Loader style={{flex: 1, height: 500}}/>
                        :
                        events.length > 0 ?
                            events.map(event => renderEvents(event))
                            :
                            <Text style={{textAlign: 'center'}}>No existen eventos</Text>
                    }
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default EventsScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        flexDirection: width <= 420 ? 'column' : 'row',
        flexWrap: 'wrap',
        justifyContent: width <= 420 ? 'center' : 'flex-start',
        alignItems: 'center',
        marginBottom: 20
    },
    article: {
        width: '90%',
        marginTop: 20,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
})
