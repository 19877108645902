import React, { useEffect, useState } from "react";
import {StyleSheet, SafeAreaView, ScrollView, View, Text, Dimensions} from 'react-native';
import * as Colors from "../styles/Colors";
import {Searcher} from '../components/searcher.component';
import {Article} from '../components/article.component';
import {Loader} from '../components/loader.component';
import { getCourses } from '../services/data.service';
import { Course } from "../components/course.component";
import {Link} from 'react-router-dom';

const {width} = Dimensions.get('window');

const SearchCoursesScreen = props => {
    const [allCourses, setAllCourses] = useState([]);
    const [courses, setCourses] = useState(null);
    const [search, setSearch] = useState('');

    /*Recoge todos los cursos*/
    useEffect(() => {
        getCourses().then(res => {
            console.log('Cursos recogidos con exito', res.courses);
            setAllCourses(res.courses);
            setCourses(res.courses);
        }).catch(err => {
            console.log('ERROR al recoger los cursos', err);
        })
    }, []);

    /*Renderiza los cursos para poder verlos en la vista*/
    const renderCourses = (course) => {
        return(
            <Link style={{width: width <= 420 ? '100%' : '33.3%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}} to={`/course/${course.id}`}>
                <Article
                    style={styles.article}
                    image={course.course_image}
                    title={course.name}
                    subtitle={course.description}
                    size={'big'}
                    press={() => props.navigation.navigate('Course', { id: course.id, name: course.name })}
                    textButton={'Ver curso'}
                />
            </Link>
        )
    }

    /*Filtra los cursos*/
    const searchCourses = (value) => {
        if (value !== '') {
            let newCourses = courses.filter(course => {
                console.log(course)
                if (course.name.toLowerCase().includes(value.toLowerCase())) return course;
            })
            if (newCourses.length > 0) setCourses(newCourses);
            else setCourses(allCourses);
        }
        else {
            setCourses(allCourses);
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView>
                <View style={styles.container}>

                    <Searcher
                        placeholder={'Busca más cursos'}
                        value={search}
                        getValue={async (value) => {
                            await setSearch(value);
                            await searchCourses(value);
                        }}
                    />

                    <View style={{flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', width: '90%', paddingBottom: 50}}>
                        {courses === null ?
                            <Loader style={{flex: 1, height: 500}}/>
                            :
                            courses.length > 0 ?
                                courses.map(course => renderCourses(course))
                                :
                                <Text style={{textAlign: 'center'}}>No hay cursos en este momento</Text>
                        }
                    </View>

                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default SearchCoursesScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 70,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginBottom: 30
    },
    article: {
        marginTop: 20
    }
})
