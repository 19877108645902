import React, {useLayoutEffect, useEffect, useState} from 'react';
import { StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity, Text, Dimensions} from "react-native";
import * as Colors from "../styles/Colors";
import tabBarNotifStore from '../store/tabBarNotifStore';
import {Searcher} from '../components/searcher.component';
import {Article} from '../components/article.component';
import {Loader} from '../components/loader.component';
import {Toast} from '../components/toast.component';
import {ButtonNewChannel} from '../components/buttonNewChannel.component';
import {getMyChannels, getUser} from '../services/user.service';
import {Link} from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import userStore from '../store/userStore';
import {getChannelsByCategoryId} from '../services/data.service';
import { normalizeString } from '../utils/formatters';

const {width} = Dimensions.get('window');


const ChannelOptionScreen = props => {
    const [user, setUser] = useState(null);
    const [allChannels, setAllChannels] = useState(null);
    const [channels, setChannels] = useState(null);
    const [search, setSearch] = useState('');
    const [channelsWithNotif, setChannelsWithNotif] = useState([]);
    const setChannelsNotif = tabBarNotifStore(state => state.setChannels);

    /*Al entrar a la vista*/
    useEffect(() => {
        window.onpageshow = function(event) {
            if (event.persisted) {
                console.log("reload")
                window.location.reload()
            }
        };

        /*Gestiona los puntos de las notificacions de mensajes sin leer*/
        setInterval(() => {
            /*Mira el asyncStorage para comprobar los grupos que tienen mensajes sin leer*/
            AsyncStorage.getItem('channelNotification').then(channels => {
                if (JSON.parse(channels) !== null && JSON.parse(channels) !== undefined && JSON.parse(channels).length > 0) {
                    /*En el caso de tener grupos con mensajes sin leer los almacena para gestionarlos*/
                    setChannelsWithNotif(JSON.parse(channels));
                }
            })
        }, 800);
    }, []);

    useEffect(() => {
        /*LLama a la funcion para recoger el usuario*/
        getterUser();
        setChannelsNotif(false);
        /*Recoge los grupos en los que estas suscrito*/
        getMyChannels().then(res => {
            console.log('CHANNELS', res.data)
            setAllChannels(res.data);
            setChannels(res.data);
        }).catch(err => {
            console.log('ERROR al recoger mis canales', err);
        })
    }, []);

    /*Recoge el usuario*/
    const getterUser = () => {
        AsyncStorage.getItem('user').then(user => {
            setUser(JSON.parse(user))
        });
    }

    /*Filtra los grupos*/
    const searchChannels = (value) => {
        if (value !== '') {
            let newChannels = channels.filter(channel => {
                if (normalizeString(channel.name).includes(normalizeString(value))) return channel;
            })

            if (newChannels.length > 0) setChannels(newChannels);
            else setChannels(allChannels);
        }
        else {
            setChannels(allChannels);
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>

                    {channels !== null ?
                        <>
                            <Searcher
                                placeholder={'Busca tu grupo'}
                                value={search}
                                getValue={async (value) => {
                                    await setSearch(value);
                                    await searchChannels(value);
                                }}
                            />
                            {channels.length > 0 ?
                                <View style={styles.containerArticles}>
                                    {channels.map(channel => {
                                        return (
                                            <Link style={{width: width <= 420 ? '100%' : '33.3%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}} to={`/channel/${channel.id}`}>
                                                <Article
                                                    style={styles.article}
                                                    image={channel.channel_image}
                                                    title={channel.name}
                                                    subtitle={channel.description}
                                                    price={channel.price}
                                                    size={'big'}
                                                    textButton={'Ver grupo'}
                                                    dot={channelsWithNotif.length > 0 ? channelsWithNotif.includes(`CH${channel.id}`) : false}
                                                />
                                            </Link>
                                        );
                                    })}
                                </View>
                                :
                                <Text>No estas suscrito a ningun canal</Text>
                            }
                        </>
                        :
                        <Loader style={{flex: 1, height: 500}}/>
                    }

                </View>
            </ScrollView>
            {(user !== null && user.user.user_type === 'doctor') &&
                <Link style={{position: 'fixed', right: width <= 820 ? 15 : 70, bottom: width <= 820 ? 0 : 30}} to={'/newChannel'}>
                    <ButtonNewChannel style={styles.buttonNewChannel}/>
                </Link>
            }
        </SafeAreaView>
    )
}

export default ChannelOptionScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50
    },
    containerArticles: {
        flexDirection: width <= 420 ? 'column' : 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '90%',
        marginBottom: 30
    },
    article: {
        marginTop: 20,
    },
    buttonNewChannel: {
        position: 'absolute',
        right: '5%',
        bottom: 120
    }
})
