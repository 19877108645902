import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image, Modal, SafeAreaView, PermissionsAndroid, Platform } from "react-native";
import * as Colors from '../styles/Colors';
import {ReactComponent as Document} from '../assets/document.svg';
//import IsReadTrue from '../assets/isReadTrue.svg';
//import IsReadFalse from '../assets/isReadFalse.svg';
import {ReactComponent as Back} from '../assets/back.svg';
import moment from 'moment';
//import Ionicons from 'react-native-vector-icons/Ionicons';
//import Video from 'react-native-video-controls';
//import { SheetManager } from "react-native-actions-sheet";
//import RNFetchBlob from "rn-fetch-blob";
import { Toast } from "./toast.component";
import { Loader } from "./loader.component";
//import { OrientationLocker, PORTRAIT, LANDSCAPE } from 'react-native-orientation-locker';
//import ImageViewer from 'react-native-image-zoom-viewer';
//import FastImage from 'react-native-fast-image'
import Clipboard from '@react-native-clipboard/clipboard';

const Message = props => {
    const date = new Date();
    const [modalImage, setModalImage] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    const [toastDownloadError, setToastDownloadError] = useState(false);
    const [toastDownloadCorrect, setToastDownloadCorrect] = useState(false);
    const [toastNoPermissions, setToastNoPermissions] = useState(false);
    const [modalDownloadDocument, setModalDownloadDocument] = useState(false);

    useEffect(() => {
        setToastDownloadError(false)
        setToastDownloadCorrect(false)
        setToastNoPermissions(false)
    }, [toastDownloadError, toastDownloadCorrect, toastNoPermissions])

    return(
        <>
            {/*Le pone un estilo u otro al componente segun si el mensaje es de quien lo a enviado o no*/}
            <View style={props.isSender ? styles.containerTimeSender : styles.containerTimeNoSender}>
                {String(moment(date).format('DD')) === String(moment(props.time).format('DD')) ?
                    <Text style={styles.time}>{moment(props.time).format('LT')}</Text>
                    :
                    <Text style={styles.time}>{moment(props.time).format('DD/MM/YY - LT')}</Text>
                }
                {/*props.isRead ?
                  <IsReadTrue/>
                  :
                  <IsReadFalse/>
                */}
            </View>

            <View style={[styles.container, props.isSender ? styles.containerSender : styles.containerNoSender]} onLayout={item => props.height(item.nativeEvent.layout.height)}>
                {/*Aqui se muestran la imagen y el nombre del usuario que ha enviado el mensaje*/}
                <View style={styles.containerImageNameUser}>
                    <Image style={{width: 25, height: 25, borderRadius: 100, marginTop: -1, marginRight: 5, marginBottom: 5}} resizeMode={'cover'} source={{uri: `https://admin.growpsy.com/${props.imageUser}`}}/>
                    <Text style={[styles.name, props.color !== null && {color: props.color}]}>{props.name}</Text>
                </View>

                {/*Cuando el mensaje lleva un archivo de tipo imagen*/}
                {(props.typeData && props.typeData === 'IMAGE') &&
                <TouchableOpacity style={styles.containerImage} activeOpacity={0.7} onPress={() => props.urlImage(`${props.typeChat === 'notes' ? 'https://admin.growpsy.com' : 'https://node.growpsy.com/'}${props.file}`)}>
                    <Image style={styles.image} resizeMode={"contain"} source={{ uri: `${props.typeChat === 'notes' ? 'https://admin.growpsy.com' : 'https://node.growpsy.com/'}${props.file}`}} />
                </TouchableOpacity>
                }

                {/*Cuando el mensaje lleva un archivo de tipo video*/}
                {(props.typeData && props.typeData === 'VIDEO') &&
                <TouchableOpacity style={styles.containerImage} activeOpacity={0.7}>
                    <View style={{width: 300, height: 170}}>
                        <video
                            style={{position: 'absolute', width: 270, height: 170, zIndex: 10}}
                            controls
                        >
                            <source src={`${props.typeChat === 'notes' ? 'https://admin.growpsy.com' : 'https://node.growpsy.com/'}${props.file}`}/>
                        </video>

                    </View>
                </TouchableOpacity>
                }

                {/*Cuando el mensaje lleva un documento (PDF, XSL...)*/}
                {(props.typeData && props.typeData === 'DOCUMENT') &&
                <TouchableOpacity style={styles.containerFile} activeOpacity={0.7} onPress={() => props.urlDocument(props.file)}>
                    {console.log('DOCUMENT')}
                    <Document width={40}/>
                    {/*<Ionicons name={'document'} size={40}/>*/}
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500}} numberOfLines={1}>{props.fileName}</Text>
                </TouchableOpacity>
                }
                {/*Al clicar sobre el texto del mensaje podras copiar el texto del mensaje para pegarlo en cualquier otro sitio*/}
                {props.text !== null && props.text !== undefined && props.text !== '' &&
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500, zIndex: 9}}>{props.text}</Text>
                }
            </View>
        </>
    )
}

export default React.memo(Message, () => {
    return true;
})

const styles = StyleSheet.create({
    safeAreaModal: {
        flex: 1,
        backgroundColor: Colors.default.COLORS.background_dark
    },
    containerHeaderModal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    buttonBackHeader: {
        justifyContent: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    containerImageModal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageModal: {
        width: '100%',
        height: '100%'
    },
    container: {
        maxWidth: '90%',
        padding: 10,
        borderRadius: 6,
        marginTop: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    name: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 13,
        marginBottom: 5,
        marginLeft: 10
    },
    containerImage: {
        width: '100%',
        maxHeight: 200,
        marginBottom: 10
    },
    image: {
        width: 200,
        height: 100
    },
    containerFile: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10
    },
    containerSender: {
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_sender
    },
    containerNoSender: {
        marginRight: 'auto',
        backgroundColor: '#f9f9f9',
        borderColor: '#EDEDED',
        borderWidth: 0.5
    },
    containerImageNameUser: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerImageUser: {
        width: 20,
        height: 20,
        borderRadius: 100
    },
    containerTimeSender: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        marginTop: 3
    },
    containerTimeNoSender: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 'auto',
        marginTop: 3
    },
    time: {
        marginRight: 3,
        fontSize: 10,
        color: Colors.default.COLORS.text_darkLight,
        fontFamily: 'Montserrat',
        fontWeight: 500
    }
})
