import React, { useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    StyleSheet,
    SafeAreaView,
    View,
    TouchableOpacity,
    TextInput,
    Image,
    Keyboard,
    Text,
    Alert,
    BackHandler,
    PermissionsAndroid,
    Platform,
    Modal,
    AppState,
    TouchableWithoutFeedback,
    NativeModules,
    Dimensions
} from "react-native";
import {ReactComponent as Clip} from '../assets/clip.svg';
import {ReactComponent as Audio} from '../assets/audio.svg';
import {ReactComponent as Sender} from '../assets/sender.svg';
import {ReactComponent as Start} from '../assets/startOutline.svg';
import {ReactComponent as Pause} from '../assets/pauseOutline.svg';
import {ReactComponent as Trash} from '../assets/trash.svg';
import {ReactComponent as Close} from '../assets/close.svg';
import {ReactComponent as Document} from '../assets/document.svg';
import * as Colors from '../styles/Colors';
import {createPrivateChat, blockChat, unblockChat, sendMessageNotes, getMessagesNotes} from '../services/data.service';
import {getChatChannel, getChatChannelPagination, sendMessage} from '../services/chat.service';
import userStore from '../store/userStore';
import io from 'socket.io-client';
import {Loader} from '../components/loader.component';
import {Toast} from '../components/toast.component';
import FlatChat from '../components/flatChat.component';
import { getUser } from "../services/user.service";
import moment from 'moment';
import {noPushNotifications} from '../services/data.service';
import {BrowserRouter as Router, Link, useParams, useHistory, useLocation} from 'react-router-dom';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import ReactAudioPlayer from 'react-audio-player';
import { useTimer } from 'react-timer-hook';
import typeHeaderStore from '../store/typeHeaderStore';
import { Player } from 'video-react';
import AsyncStorage from "@react-native-async-storage/async-storage";

const {width} = Dimensions.get('window');

const PrivateChatDetailScreen = props => {
    const [idChat, setIdChat] = useState(null);
    let messageEnter = '';
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState(null);
    const [lastPage, setLastPage] = useState(false);
    const [moreMessages, setMoreMessages] = useState(false);
    const [quantityNewMessages, setQuantityNewMessages] = useState(0);
    const [incrementPages, setIncrementPages] = useState(true);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file2Name, setFile2Name] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [isRecord, setIsRecord] = useState(false);
    const [timeToPlayAudio, setTimeToPlayAudio] = useState(1);
    const [search, setSearch] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [indexSearch, setIndexSearch] = useState([]);
    const [actualIndexSearch, setActualIndexSearch] = useState(0);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [scrollRef, setScrollRef] = useState(null);
    const [urlDocument, setUrlDocument] = useState(null);
    const [toastNoPermissions, setToastNoPermissions] = useState(false);
    const [toastDownloadCorrect, setToastDownloadCorrect] = useState(false);
    const [toastErrorSendMessage, setToastErrorSendMessage] = useState(false);
    const [typeDataDownload, setTypeDataDownload] = useState(null);
    const [toastDownloadError, setToastDownloadError] = useState(false);
    const [modalDownloadDocument, setModalDownloadDocument] = useState(false);
    const [loaderSender, setLoaderSender] = useState(false);
    const [toastNoChat, setToastNoChat] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    const [urlVideo, setUrlVideo] = useState(null);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [appState, setAppState] = useState(AppState.currentState);
    const [audioFile, setAudioFile] = useState(null);
    const [showInput, setShowInput] = useState(true);

    const {id, type, idSession, idMember} = useParams();
    const inputHidden = useRef(null);
    const socketRef = useRef(null);
    const user = userStore(state => state.user);
    const setUser = userStore(state => state.setUser);
    const setTypeHeader = typeHeaderStore(state => state.setTypeHeader);
    const openMenu = typeHeaderStore(state => state.openMenu);
    const location = useLocation();
    const audioOptions = {
        sampleRate: 16000,
        channels: 1,
        bitsPerSample: 16,
        audioSource: 6,
        //wavFile: 'test.wav'
    };
    let {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({onExpire: () => console.warn('onExpire called') });


    /*Al entrara  la vista activa el permiso del microfono*/
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({audio: true}).catch(err => {
            alert('Activa el permiso del microfono para grabar audio.')
        });
    }, []);

    /*Gestiona las notificaciones de mensajes sin leer*/
    useEffect(() => {
        AsyncStorage.getItem('notifications').then(notifications => {
            if (type === 'CH') {
                AsyncStorage.setItem('notifications', JSON.stringify({group: false, conversation: JSON.parse(notifications).conversation}));

            } else if (type === 'CN') {
                AsyncStorage.setItem('notifications', JSON.stringify({group: JSON.parse(notifications).group, conversation: false}));
            }
        })
    }, [])

    /*useEffect(() => {
        if (idSession !== null && idSession !== 'null' && idSession !== undefined) {
            AsyncStorage.getItem('token').then(res => {
                window.open(`https://videochat.growpsy.com/sfu/${idSession}/${res.split('Bearer ').pop()}/`);
            })
        }
    }, [])*/

    /*Al entrar a la vista dice el tipo de cabecera que va a tner la vista*/
    useEffect(() => {
        setTypeHeader('menu');
    }, [])

    /*Recoge el usuario al entrara a la vista*/
    useLayoutEffect(() => {
        if (!user) {
            getUser().then(res => {
                console.log('getUser', res.data);
                setUser(res.data);
                connectToChat(res.data);
            }).catch(err => {
                console.log('ERROR al recoger el usuario', err);
            });
        }
    }, []);

    /*Recoge los mensajes de la pagina de notas*/
    const connectToChat = (userConnect) => {
        getMessagesNotes(id).then(res => {
            setMessages(res.data.reverse());
            console.log('Mensajes rocogidos cone xito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger los mensajes de notas', err);
        })
    }

    const showMoreMessages = () => {
        console.log('ID LAST MESSAGE', messages[0]);
        setMoreMessages(true);
        setIncrementPages(true);
        getChatChannelPagination(`${type}${id}`, messages[0]._id).then(res => {
            console.log('YYYYYY', res.data.messages)
            let newMessages = res.data.messages.messages.reverse();
            setLastPage(res.data.messages.isLastPage);
            setMessages(messages => [...newMessages, ...messages]);
            setQuantityNewMessages(quantityNewMessages =>  quantityNewMessages + res.data.messages.messages.length);
            console.log('QUANTITY MESSAGES', quantityNewMessages);
            setMoreMessages(false);
        }).catch(err => {
            console.log('ERROR al recoger mas mesnajes', err);
        })
    }

    const openFileExplorer = () => {
        inputHidden.current.click();
    }

    const uploadImage = (media) => {
        console.log('ppppp', )
        if (media.target.files[0].type.includes('image')) {
            setTypeData('IMAGE');
        } else if (media.target.files[0].type.includes('video')) {
            setTypeData('VIDEO');
        } else if (media.target.files[0].type.includes('application')) {
            console.log('DOOOOOOOOC')
            setTypeData('DOCUMENT');
        }
        //console.log('URL', URL.createObjectURL(media.target.files[0]))
        //setFile(URL.createObjectURL(media.target.files[0]));
        console.log('media', media.target.files)
        setFile2Name(media.target.files[0].name);
        setFile(URL.createObjectURL(media.target.files[0]));
        let reader = new FileReader();
        reader.readAsDataURL(media.target.files[0])
        reader.onload = function() {
            console.log(reader.result);
            setFile2(reader.result);
        }
    }

    const sendNewMessage = () => {
        setLoaderSender(true);
        if (id !== null && id !== 'null' && id !== undefined) {
            newMessage();
            setMessage('');
        } else {
            createPrivateChat(idMember).then(async res => {
                await socketRef.current.emit('join', { channelId: `${type}${res.data.id}`, userId: user.user.id, email: user.user.email, appVersion: 'web', platform: Platform.OS, socketId: socketRef?.current?.id});
                await newMessage();
                setMessage('');
                setIdChat(res.data.id);
                console.log('Conversacion creada con exito', res.data);
            }).catch(err => {
                console.log('ERROR al crear la conversacion', err);
            })
        }
    }

    const newMessage = async (idChat) => {
        let newMessageNotes = {
            audio: {url: null},
            channel_id: id,
            created_at: new Date(),
            file: {url: null},
            image: file2,
            video: {url: null},
            note: message,
            user_id: user.user.id
        }
        sendMessageNotes(id, message, typeData === 'IMAGE' ? file2 : null, typeData === 'VIDEO' ? file2 : null, typeData === 'DOCUMENT' ? file2 : null, typeData === 'AUDIO' ? file2 : null).then(res => {
            setMessages(messages => [...messages, res.data]);
            setLoaderSender(false);
            setMessage(null);
            setTypeData(null);
            setFile(null);
            setFile2(null);
            console.log('Mensaje enviado con exito', res);
        }).catch(err => {
            console.log('ERROR al enviar el mensaje', err);
        })
    }

    const alertMessageFlat = () => {
        Alert.alert(
            "Enviando archivo",
            "Tu fichero se ha enviado con exito en breve aparecera en tu conversacion.",
            [
                {
                    text: "Cerrar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "Aceptar", onPress: () => console.log('Accept pressed') }
            ]
        );
    }

    const searchMessage = (value) => {
        let indexMessages = [];
        if (value !== '') {
            messages.filter((message, index) => {
                if (message.message.toLowerCase().includes(value.toLowerCase())) indexMessages.push(index);
            })

        }
        if (indexMessages.length > 0) {
            setActualIndexSearch(0);
            setIndexSearch(indexMessages);
            scrollRef.current.scrollToIndex({index: indexMessages[0]});
        }
    }

    const alertBlockChat = () => {
        Alert.alert(
            "Bloquear chat",
            "¿Esta seguro que desea bloquear el chat?",
            [
                {
                    text: "Cancelar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "Bloquear chat", onPress: () => blockOneChat() }
            ]
        );
    }

    const blockOneChat = () => {
        blockChat(idChat).then(res => {
            console.log('Chat bloqueado con exito', res.data);
        }).catch(err => {
            console.log('ERROR al bloquear el chat', err);
        })
    }

    const alertUnblockChat = () => {
        Alert.alert(
            "Desbloquear chat",
            "¿Esta seguro que desea desbloquear el chat?",
            [
                {
                    text: "Cancelar",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "Desbloquear chat", onPress: () => unblockOneChat() }
            ]
        );
    }

    const unblockOneChat = () => {
        unblockChat(idChat).then(res => {
            console.log('Chat bloqueado con exito', res.data);
        }).catch(err => {
            console.log('ERROR al bloquear el chat', err);
        })
    }

    const stopRecordAudio = async (audio) => {
        setTypeData('AUDIO')
        let res = await fetch(audio.url);
        let buf = await res.arrayBuffer();
        let file = new File([buf], 'audio')
        setAudioFile(audio.url);
        setFile2(file);
    }

    const startAudio = async () => {
    }

    const stopAudio = async () => {
    }


    const removePushNotifications = () => {
        noPushNotifications(null).then(res => {
            console.log('Push eliminadas con exito', res);
        }).catch(err => {
            console.log('ERROR al elimianr las push', err);
        })
    }

    return(
        user ?
            <SafeAreaView style={styles.safeArea}>
                {openMenu &&
                <div style={styles.dropdown}>
                    <p style={styles.blockChatDropdown}>Bloquear chat</p>
                </div>
                }

                <AudioReactRecorder style={{display: 'none'}} state={isRecord ? RecordState.START : RecordState.STOP}  canvasWidth={0} canvasHeight={0} onStop={stopRecordAudio} type={'mp3'}/>

                <View style={styles.container}>
                    {openSearch &&
                    <View style={styles.searcherBar}>
                        <TouchableOpacity style={styles.containerClose} onPress={() => {
                            setSearch("");
                            setOpenSearch(false);
                            setActualIndexSearch(0);
                            setIndexSearch([]);
                        }}>
                        </TouchableOpacity>
                        <View style={styles.containerInputSearch}>
                            <View style={{ width: "90%" }}>
                                <TextInput
                                    style={styles.inputSearch}
                                    value={search}
                                    onChangeText={(value) => {
                                        setSearch(value);
                                        searchMessage(value);
                                    }}
                                />
                            </View>
                            <View style={{ width: "15%", marginRight: 10 }}>
                                <Text
                                    style={styles.textCoincidences}>{indexSearch.length > 0 ? (actualIndexSearch + 1) : (actualIndexSearch)} / {indexSearch.length}</Text>
                            </View>

                        </View>
                        <View style={styles.containerUpDown}>
                            <TouchableOpacity onPress={() => {
                                if (actualIndexSearch > 0) {
                                    setActualIndexSearch(actualIndexSearch - 1);
                                    scrollRef.current.scrollToIndex({ index: indexSearch[actualIndexSearch - 1] });
                                }
                            }}>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
                                if (actualIndexSearch < indexSearch.length - 1) {
                                    setActualIndexSearch(actualIndexSearch + 1);
                                    scrollRef.current.scrollToIndex({ index: indexSearch[actualIndexSearch + 1] });
                                }
                            }}>
                            </TouchableOpacity>
                        </View>
                    </View>
                    }


                    <View style={[styles.containerMessages, openSearch && { paddingBottom: 50 }]}>
                        {messages === null ?
                            <View style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <Loader style={{ marginTop: "70%" }} />
                            </View>
                            :
                            messages.length > 0 ?
                                (file === null ?
                                        <FlatChat
                                            user={user}
                                            messages={moreMessages === false ? messages : []}
                                            getRef={ref => setScrollRef(ref)}
                                            fileName={file2Name}
                                            getUrlDownloadFile={value => setUrlDocument(value)}
                                            getTypeData={value => setTypeDataDownload(value)}
                                            getUrlVideo={value => {
                                                setUrlVideo(value);
                                                setModalVideo(true);
                                            }}
                                            getUrlImage={(image) => {
                                                setFile(image);
                                                setTypeData('IMAGE');
                                                setShowInput(false);
                                            }}
                                            getUrlDocument={(document) => {
                                                window.open(`https://node.growpsy.com/${document}`)
                                            }}
                                            private={true}
                                            //isTop={() => showMoreMessages()}
                                            quantityNewMessages={quantityNewMessages}
                                            isNewMessage={incrementPages}
                                            lastPage={lastPage}
                                            idsColors={location.state !== null ? location.state.colors : undefined}
                                            typeChat={'notes'}
                                        />
                                        :
                                        <View style={{ position: "relative", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 50 }}>
                                            <TouchableOpacity
                                                style={{marginLeft: 'auto', marginBottom: 30}}
                                                activeOpacity={0.7}
                                                onPress={() => {
                                                    setTypeData(null);
                                                    setFile(null);
                                                    setFile2(null);
                                                    setShowInput(true);
                                                }}
                                            >
                                                <Close width={30}/>
                                            </TouchableOpacity>
                                            {typeData === "IMAGE" &&
                                            <Image style={{ width: '100%', height: 500 }} resizeMode={'contain'}
                                                   source={{ uri: file }} />
                                            }

                                            {typeData === "VIDEO" &&
                                            <video style={{width: '100%', height: 500}} controls>
                                                <source src={file}/>
                                            </video>
                                            }

                                            {typeData === "DOCUMENT" &&
                                            <View style={{ alignItems: "center", width: "100%", height: 300 }}>
                                                <Document width={200}/>
                                                <Text style={{ fontSize: 30 }}>{file2Name}</Text>
                                            </View>
                                            }
                                        </View>
                                )
                                :
                                (file === null ?
                                        <View style={{marginTop: 30}}>
                                            <Text style={styles.noMessages}>Todavia no hay mensajes en esta conversación</Text>
                                            <Text style={styles.noMessages}>Recuerda que los mensajes son privados con esta persona.</Text>
                                        </View>
                                        :
                                        <View style={{ position: "relative", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                            {typeData === "IMAGE" &&
                                            <Image style={{ width: '100%', height: 500 }} resizeMode={'contain'}
                                                   source={{ uri: file }} />
                                            }

                                            {typeData === "VIDEO" &&
                                            <video
                                                style={{ width: 300, height: 300 }}
                                            >
                                                <source src={file} type={'video/mp4'}/>
                                            </video>
                                            }

                                            {typeData === "DOCUMENT" &&
                                            <View style={{ alignItems: "center", width: "100%", height: 300 }}>
                                                {/*<Ionicons name={"document"} size={200} />*/}
                                                <Text style={{ fontSize: 30 }}>{file2Name}</Text>
                                            </View>
                                            }
                                        </View>
                                )

                        }

                        {modalDownloadDocument &&
                        <View style={{
                            position: "absolute",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "115%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0 , 0.5)",
                        }}>
                            <View style={{
                                width: "100%",
                                borderRadius: 6,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingVertical: 20,
                                backgroundColor: Colors.default.COLORS.background_light,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 3,
                                },
                                shadowOpacity: 0.27,
                                shadowRadius: 4.65,
                                elevation: 6,
                            }}>
                                <Loader />
                                <Text>Descargando archivo</Text>
                            </View>
                        </View>
                        }
                    </View>

                </View>

                {showInput &&
                <View
                    style={[styles.containerWriterImage, {alignItems: 'center'}, Platform.OS === "ios" && {marginBottom: keyboardHeight}]}
                    importantForAccessibility={"no-hide-descendants"}
                    pointerEvents={messages !== null ? 'auto' : 'none'}>

                    <View style={styles.containerWriter} importantForAccessibility={"no-hide-descendants"}>
                        {typeData === null || typeData === "IMAGE" || typeData === "DOCUMENT" || typeData === "VIDEO" ?
                            <TouchableOpacity
                                style={[styles.writerButtons, {width: 50, paddingHorizontal: 10}]}
                                activeOpacity={0.7}
                                onPress={() => openFileExplorer()}
                            >
                                <Clip/>
                                <input ref={inputHidden} style={{display: 'none'}} type={'file'}
                                       onChange={(e) => uploadImage(e)}/>
                            </TouchableOpacity>
                            :
                            isPlay ?
                                <TouchableOpacity onPress={() => stopAudio()}>
                                    <Pause/>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity activeOpacity={0.7} onPress={() => startAudio()}>
                                    <Start/>
                                </TouchableOpacity>

                        }

                        {
                            typeData === null || typeData === "IMAGE" || typeData === "DOCUMENT" || typeData === "VIDEO" ?
                                <TextInput
                                    style={[styles.input]}
                                    placeholder={"Escribe tu mensaje"}
                                    value={isRecord ? 'Grabando audio...' : message}
                                    textAlign={isRecord ? 'center' : 'left'}
                                    multiline={true}
                                    numberOfLines={5}
                                    onChangeText={(value) => {
                                        if (isRecord) {
                                            //stopAudio();
                                        } else {
                                            setMessage(value);
                                            messageEnter = value;
                                        }
                                    }}
                                    onPressIn={() => setKeyboardOpen(true)}
                                    onKeyPress={(e) => {
                                        if (e.code === 'Enter') {
                                            sendNewMessage();
                                        }
                                    }}
                                />
                                :
                                <ReactAudioPlayer
                                    src={audioFile}
                                    controls
                                />
                        }


                        <View
                            style={[styles.writerButtons, typeData === "AUDIO" ? {width: "15%"} : {width: "10%"}]}
                        >
                            {loaderSender ?
                                <Loader/>
                                :
                                ((message === "" || message === null) && typeData === null ?
                                        <TouchableOpacity
                                            style={styles.button}
                                            onPressIn={() => {
                                                setIsRecord(true)
                                                start();
                                            }}
                                            onPressOut={() => {
                                                setIsRecord(false);
                                                pause();
                                            }}
                                            importantForAccessibility={"no-hide-descendants"}
                                        >
                                            <Audio/>
                                        </TouchableOpacity>
                                        :
                                        <View style={styles.containerSenderAndRemover}>
                                            {file2 !== null && file2.name === 'audio' &&
                                            <TouchableOpacity style={{width: 50, marginRight: 10}} onPress={() => {
                                                setFile(null);
                                                setFile2(null);
                                                setFileName(null);
                                                setTypeData(null);
                                                setTimeToPlayAudio(1);
                                            }}>
                                                <Trash style={{width: 20, height: 20}}/>
                                            </TouchableOpacity>
                                            }
                                            <TouchableOpacity
                                                style={[styles.button, typeData === "AUDIO" && {marginRight: 10}]}
                                                onPress={() => sendNewMessage()}
                                            >
                                                <Sender/>
                                            </TouchableOpacity>
                                        </View>
                                )
                            }

                        </View>
                    </View>
                </View>
                }

                <Toast message={"No se ha podido descargar el archivo, activa los permisos para poder descargarlo."}
                       visible={toastNoPermissions} />
                <Toast message={"Descarga realizada con exito."} visible={toastDownloadCorrect} />
                <Toast message={"No se ha podido descargar el archivo, intentalo de nuevo."} visible={toastDownloadError} />
                <Toast message={"No se ha podido enviar el mensaje, por favor vuelve a intentarlo."}
                       visible={toastErrorSendMessage} />
                <Toast message={"Esta conversacion se encuentra bloqueada o el usuario a desactivado los chats."}
                       visible={toastNoChat} />
            </SafeAreaView>
            :
            null
    )
}

export default PrivateChatDetailScreen;

const styles = StyleSheet.create({
    anchorButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container:{
        flex: 1,
        maxWidth: '100%'
    },
    searcherBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerClose: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '10%'
    },
    containerInputSearch: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '70%',
        height: 50
    },
    inputSearch: {
        width: '100%',
        height: '100%',
        borderBottomWidth: 1,
        borderBottomColor: Colors.default.COLORS.background_dark
    },
    textCoincidences: {
        color: Colors.default.COLORS.primary
    },
    containerUpDown: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '15%',
        paddingRight: 10
    },
    containerMessages: {
        paddingHorizontal: 20
    },
    containerImageArchive: {
        position: 'absolute',
        bottom: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: 130,
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderRadius: 6,
        marginRight: 'auto',
        marginBottom: 10,
        marginLeft: 20,
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerCloseImageArchive: {
        position: 'absolute',
        top: -12,
        right: -12,
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: 25,
        height: 25,
        borderRadius: 100,
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerImageWriter: {
        alignItems: 'center',
        width: 100,
        maxHeight: 100
    },
    image: {
        width: '100%',
        height: 100
    },
    containerWriter: {
        position: 'absolute',
        bottom: width <= 820 ? 60 : 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 55,
        //paddingHorizontal: 5,
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderColor: '#E1E1E1',
        backgroundColor: Colors.default.COLORS.background_light,
    },
    writerButtons: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        //paddingHorizontal: 10
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    input: {
        width: '80%',
        height: 55,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        outlineStyle: 'none'
    },
    containerActionSheet: {
        width: '100%',
        padding: 20,
        paddingBottom: 0
    },
    blockChat: {
        width: '100%',
        padding: 20
    },
    textBlockChat: {
        color: '#FF2626'
    },
    textUnblockChat: {
        color: Colors.default.COLORS.primary
    },
    containerOpenSearcher: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10
    },
    iconSearch: {
        marginRight: 10
    },
    containerSenderAndRemover: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    noMessages: {
        textAlign: 'center',
        marginTop: 30
    },
    cancelSuscription: {
        width: '100%',
        padding: 20
    },
    textDownloadDocument: {
        color: Colors.default.COLORS.primary
    },
    imageModal: {
        width: '100%',
        height: '100%'
    },
    safeAreaModal: {
        flex: 1,
        backgroundColor: Colors.default.COLORS.background_dark
    },
    containerHeaderModal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    buttonBackHeader: {
        justifyContent: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    containerImageModal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerActionSheetDocuments: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        padding: 20,
        paddingBottom: 15
    },
    containerOptionDocuments: {
        alignItems: 'center'
    },
    dropdown: {
        position: 'absolute',
        zIndex: 9,
        top: 50,
        right: '3%',
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: Colors.default.COLORS.background_light,
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
    },
    blockChatDropdown: {
        color: 'red'
    }
})
