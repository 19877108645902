import React from 'react';
import {StyleSheet, View, TouchableOpacity, Image, Text} from 'react-native';
import * as Colors from '../styles/Colors';
import {Button} from './button.component';


export const Member = props => {

    return(
        <TouchableOpacity style={styles.container} activeOpacity={props.pressAll === null || props.pressAll === undefined ? 1 : 0.7} onPress={props.pressAll}>
            <View style={styles.containerData}>
                <Image style={styles.image} source={{uri: `https://admin.growpsy.com/${props.image}`}}/>
                <View style={styles.containerDataText}>
                    <Text style={styles.name}>{props.name}</Text>
                    <Text style={styles.type}>{props.type}</Text>
                </View>
            </View>
            <View style={props.time !== undefined && props.time !== null? styles.containerTime : styles.containerButton}>
                {props.buttonTime !== false ?
                    props.time !== undefined && props.time !== null ?
                        <Text style={styles.time}>Se unió el {props.time}</Text>
                        :
                        <Button style={styles.button} text={props.guest ? 'Invitado' : 'Invitar'} primary={props.guest} press={props.press}/>
                    :
                    null
                }
                {(props.newMessages !== null && props.newMessages !== undefined && props.newMessages.length > 0 && props.newMessages !== '0') &&
                <View style={styles.containerNewMessages}>
                    <Text style={styles.newMessages}>{props.newMessages}</Text>
                </View>
                }
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        width: '90%',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#e1e1e1'
    },
    containerData: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerDataText: {
        height: 40,
        justifyContent: 'space-between'
    },
    image: {
        width: 50,
        height: 50,
        borderRadius: 100,
        marginRight: 10
    },
    name: {
        fontSize: 14,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: Colors.default.COLORS.text_dark
    },
    type: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 12,
        color: '#97989B'
    },
    time: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 10,
        color: Colors.default.COLORS.text_darkLight,
        marginTop: 5
    },
    containerTime:{
        height: '100%'
    },
    containerButton: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        paddingHorizontal: 10
    },
    containerNewMessages: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 20,
        height: 20,
        borderRadius: 100,
        backgroundColor: Colors.default.COLORS.primary
    },
    newMessages: {
        color: Colors.default.COLORS.text_light
    }
})
