import React, {useEffect, useState} from 'react';
import { Image, StyleSheet, TouchableOpacity, Text, View, Dimensions} from 'react-native';
import * as Colors from '../styles/Colors';
//import RenderHtml from 'react-native-render-html';
//import Feather from "react-native-vector-icons/Feather";
import moment from 'moment';
import {Button} from './button.component';

const {width} = Dimensions.get('window');

export const Article = props => {
  const regex = /(<([^>]+)>)/ig;
  const [isShowingText, setIsShowingText] = useState(true);

  /*En el caso de ser un elemento que lo necesita pone el punto de mensajes sin leer
  * como puede ser en los elementos del la vista mis grupos*/
  useEffect(() => {
    const toggle = setInterval(() => {
      setIsShowingText(!isShowingText);
    }, 700);

    return () => clearInterval(toggle);
  })

  /*En la mayoria de elementos depende de la propiedad size segun sea una u otra cambia los estilos o algunos elemntos*/
  return(
    <TouchableOpacity style={[props.size === 'big' ? (props.type === 'article' ? styles.containerNoMargin : styles.containerNoMarginHeight) : styles.container, props.style]} activeOpacity={0.7} onPress={props.press}>
      {props.date &&
        <View style={styles.header}>
          <Text style={{color: '#FFFFFF'}}>{props.date}</Text>
        </View>
      }
      <Image style={props.size === 'big' ? styles.imageBig : styles.image} resizeMode={props.image !== undefined && props.image !== null ? 'cover' : 'contain'} source={props.image !== undefined && props.image !== null ? {uri: `https://admin.growpsy.com${props.image}`} : require('../assets/growpsyApaisado.png')}/>
      <View style={props.size === 'big' ? styles.containerTextBig : styles.containerTextLittle}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>

          <Text numberOfLine={2} style={styles.title}>{props.title}</Text>
          {props.dot === true &&
            (isShowingText &&
              <View style={styles.dotInfo}>
              </View>
            )
          }
        </View>
        {props.date &&
          <View style={styles.containerDatePlace}>
            <View style={styles.chip}>
              <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{props.place === '' ? 'Online' : props.place}</Text>
            </View>
          </View>
        }
        {props.type === 'article' &&
          <div style={styles.contentText} dangerouslySetInnerHTML={{__html: props.subtitle}}>
          </div>
        }
        {/*{props.size === 'big' ?
          <Text numberOfLine={2} style={styles.contentText}>{props.subtitle.replace(regex, '')}</Text>
          :
          <Text numberOfLine={2} style={styles.contentText}>{props.subtitle}</Text>
        }*/}
      </View>

      { props.textButton &&
        <Button
            style={{width: '90%', marginTop: 'auto', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto'}}
            text={props.textButton}
            primary
        />
      }

    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 270,
    maxHeight: 270,
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    marginRight: 10,
    borderWidth: 1,
    borderColor: '#CCCCCC',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  header: {
    padding: 10,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: '#99bfe3'
  },
  containerNoMargin: {
    minHeight: 324,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#CCCCCC',
    width: width <= 420 ? '100%' : '90%',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  containerNoMarginHeight: {
    minHeight: 290,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#CCCCCC',
    width: width <= 420 ? '100%' : '90%',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  image: {
    width: '100%',
    height: 100,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6
  },
  imageBig: {
    width: '100%',
    height: 160,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6
  },
  containerTextBig: {
    overflow: 'hidden',
    //maxHeight: 105,
    marginVertical: 7,
    padding: 10
  },
  containerTextLittle: {
    maxWidth: 200,
    padding: 10
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 8,
  },
  contentText: {
    color: '#5C5F62',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    marginTop: 0
  },
  containerDatePlace: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  chip: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 20,
    backgroundColor: '#C3FFEA'
  },
  dotInfo: {
    width: 10,
    height: 10,
    marginLeft: 'auto',
    borderRadius: 100,
    backgroundColor: '#d38001',
  }
})
