import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    SafeAreaView,
    ScrollView,
    Image,
    Platform,
    Dimensions
} from 'react-native';
import React, {useState, useEffect} from 'react';
import {Channel} from '../components/channel.component';
import {Loader} from '../components/loader.component';
import {Article} from '../components/article.component';
import {Invitation} from '../components/invitation.component';
import userStore from '../store/userStore';
import {Meet} from '../components/meet.component';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../components/cssCarousel/carousel.css';
import {Link, useNavigate} from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Slider from "react-slick";
import {getPosts,
    getChannels,
    getChannelsHome,
    getHomeCarousel} from '../services/data.service';
import {getUser,
    acceptInvitation,
    rejectInvitation,
    RegisterDevice} from '../services/user.service';
import * as Colors from "../styles/Colors";

const {width} = Dimensions.get('window');

const HomeScreen = props => {
    const [invitations, setInvitations] = useState(null);
    const [sessions, setSessions] = useState(null);
    const [channels, setChannels] = useState(null);
    const [posts, setPosts] = useState(null);
    //const [rechargeUser, setRechargeUser] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]);
    const user = userStore(state => state.user);
    const setUser = userStore(state => state.setUser);

    const navigate = useNavigate();

    /*LLama a la funcion al entrar a la vista*/
    useEffect(() => {
        rechargeUser();
    }, []);

    /*Al entrara  a la vista*/
    useEffect(() => {
        /*Recoge los grupos que se vana a mostrar en la home*/
        getChannelsHome().then(res => {
            /*En el caso de que vengan mas de 10 cursos se controlara para que se vean un maximo de 10
            * Si viene menos de 10 o igual a 10 se mostraran todos los cursos*/
            if (res.data.length > 10) {
                setChannels(res.data.slice(0, 10));
            } else {
                setChannels(res.data);
            }
        }).catch(err => {
            console.log('ERROR al recoger los canales de la home', err);
        })

        /*Recoge los posts que se van a ver en la home*/
        getPosts().then(res => {
            setPosts(res.data);
        }).catch(err => {
            console.log('ERROR al recoger los articulos', err);
        });

        /*Recoge las imagenes que se vana a ver en el carrusel de la home*/
        getHomeCarousel().then(res => {
            setCarouselImages(res.data);
            console.log('getHomeCarousel', res.data)
        }).catch(err => {
            console.log('ERROR al recoger la imagenes dle carrousel');
        })
    }, []);

    /*Recoge el usuario*/
    const rechargeUser = () => {
        getUser().then(res => {
            /*Guarda el usuario en asyncstorage*/
            AsyncStorage.setItem('user', JSON.stringify(res.data));
            /*Guarda las invitaciones a grupos que tiene pendientes el usuario*/
            setInvitations(res.data.invitations);
            /*Guarda las sesiones que tiene pendientes el usuario*/
            setSessions(res.data.video_sessions);
        }).catch(err => {
            console.log('ERROR al recoger el usuario', err);
        });
    }

    /*Acepta una invitacion a un grupo*/
    const userAcceptInvitation = (idInvitation) => {
        acceptInvitation(idInvitation).then(res => {
            /*Vuelve a cargar el usuario*/
            rechargeUser();
        }).catch(err => {
            console.log('ERROR al aceptar la invitacion', err);
        })
    }

    /*Rechaza una invitacion qu etiene el usuario*/
    const userRejectInvitation = (idInvitation) => {
        rejectInvitation(idInvitation).then(res => {
            /*Vuelve a cargar el usuario*/
            rechargeUser();
        }).catch(err => {
            console.log('ERROR al rechazar la invitacion', err)
        })
    }

    return (
        <SafeAreaView style={styles.safeArea}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>

                    {carouselImages.length > 0 &&
                        <Carousel
                            className={'carousel'}
                            /*style={{width: '100%', top: 0}}
                            containerStyle={{width: '100%'}}
                            contentContainerStyle={{width: '100%'}}*/
                            autoPlay={true}
                            interval={3000}
                            infiniteLoop={true}
                        >
                            {
                                carouselImages.map(image => {
                                    return (
                                        <TouchableOpacity
                                            style={{width: '100%', justifyContent: 'center', alignItems: 'center', top: 0}}
                                            activeOpacity={0.9} onPress={() => window.open(image.link)}>
                                            <Image style={{width: '100%', height: 300}} resizeMode={'cover'}
                                                   source={{uri: `https://admin.growpsy.com${image.image.url}`}}/>
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </Carousel>
                    }

            {(invitations !== null && invitations.length > 0) &&
            <>
              <View style={styles.containerWidth}>
                <Text style={styles.title}>Tus Invitaciones</Text>
              </View>
              <View style={invitations !== null && invitations.length > 0 ? {maxHeight: 160} : {maxHeight: 50}}>
                <Carousel
                    style={{width: '100%'}}
                    showStatus={false}
                >
                  {invitations !== null && invitations.length > 0 ?
                      invitations.map(invitation => {
                        return(
                            <Invitation style={{marginBottom: 40}} channel={invitation.channel} user={invitation.user} date={invitation.created_at} pressAccept={() => userAcceptInvitation(invitation.id)} pressReject={() => userRejectInvitation(invitation.id)}/>
                        )
                      })
                      :
                      <View style={styles.containerNoInvitations}>
                        <Text>No tienes invitaciones</Text>
                      </View>
                  }
                </Carousel>
              </View>
            </>
            }


            {(sessions !== null && sessions.length > 0) &&
            <>
              <View style={styles.containerWidth}>
                <Text style={styles.title}>Tu terapia</Text>
              </View>
              <View style={[{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: "center" }, sessions !== null && sessions.length > 0 ? { height: 150 } : { maxHeight: 50 },]}>
                <Carousel
                    width={500}
                    showStatus={false}
                >
                  {sessions === null ?
                      <Loader style={{ marginLeft: 20 }} />
                      :
                      (sessions !== null && sessions.length > 0 ?
                              sessions.map(session => {
                                return (
                                    <View style={width <= 820 ? {width: '90vw'} : {display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '72vw'}}>
                                        <Meet style={{marginBottom: 40}} creator={session.channel_name} date={session.date} image={session.image} press={() => navigate(`/channel/${session.channel_id}`)}/>
                                    </View>
                                );
                              })
                              :
                              <Text style={{ textAlign: "center" }}>No tienes sesiones</Text>
                      )
                  }
                </Carousel>
              </View>
            </>
            }

                    <View style={styles.containerWidth}>
                        <Text style={styles.title}>Grupos</Text>
                        <Link style={{textDecoration: 'none'}} to={'/channels'}>
                            <Text style={styles.showMore}>Ver más</Text>
                        </Link>
                    </View>

                    <View style={{width: '90%'}}>
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                            {
                                channels === null ?
                                    <Loader style={{marginTop: 20}} />
                                    :
                                    channels.map(channel => {
                                        return(
                                            <Link style={{textDecoration: 'none'}} to={`/channel/${channel.id}`}>
                                                <Channel title={channel.name} color={channel.channel_category.color} image={channel.channel_image} price={channel.price} category={channel.channel_category.name} compact /*press={() => props.navigation.navigate('Channel', {channel: channel, subscribe: channel.subscribed, creatorUser: channel.user_id})}*//>
                                            </Link>
                                        )
                                    })
                            }
                        </ScrollView>
                    </View>

                    <View style={styles.containerWidth}>
                        <Text style={styles.title}>Artículos</Text>
                        <Link style={{textDecoration: 'none'}} to={'/categoriesArticles'}>
                            <Text style={styles.showMore}>Ver más</Text>
                        </Link>
                    </View>
                    <View style={{width: '90%'}}>
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                            {posts !== null ?
                                posts.map(post => {
                                    return (
                                        <Link style={{textDecoration: 'none'}} to={`/article/${post.id}`}>
                                            <Article title={post.title} subtitle={post.subtitle} image={post.image.url} size={'little'} type={'article'}/>
                                        </Link>
                                    )
                                })
                                :
                                <Loader style={{marginTop: 20}}/>
                            }
                        </ScrollView>
                    </View>

                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default HomeScreen;


const styles = StyleSheet.create({
    safeArea: {
        overflow: 'scroll',
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        paddingBottom: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 50,
    },
    containerWidth: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginTop: 30,
        marginBottom: 20
    },
    containerNoInvitations: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 16,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#2D2E2F',
    },
    showMore: {
        color: '#99bfe3',
        fontFamily: 'Montserrat',
        fontWeight: 600
    },
    containerSelectOption: {
        width: '90%',
        flexDirection: 'row',
    },
    containerSelectOptionSelected: {
        borderBottomWidth: 1,
        borderBottomColor: '#2D2E2F'
    },
    optionSelect: {
        color: '#2D2E2F'
    },
    optionNoSelect: {
        color: '#87898E'
    }
})
