import React, { useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  SafeAreaView,
  ScrollView,
  View,
  Text,
  Dimensions,
  createContext
} from "react-native";
import { getChatsFromUserId } from "../services/firestore.service";
import { Searcher } from "../components/searcher.component";
import { Member } from "../components/member.component";
import { Loader } from "../components/loader.component";
import { noPushNotifications } from "../services/data.service";
import * as Colors from "../styles/Colors";
import { normalizeString } from "../utils/formatters";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
const { width } = Dimensions.get("window");

const PrivateChatsScreen = (props) => {
  const [fbChat, setFbChat] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true); 
  const fbChatBackup = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = getChatsFromUserId((chats) => {
      if (chats !== undefined && chats !== null) {
        setFbChat(chats);
        fbChatBackup.current = chats;
        setLoading(false);
      } else {
        console.error("Received undefined or null chats");
        setFbChat([]);
        fbChatBackup.current = [];
        setLoading(false);
      }
    });
  
    return () => {
      if (unsubscribe) {
        try {
          unsubscribe();
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, []);

  /* Filtra las conversaciones privadas */
  const searchChats = (value) => {
    setSearch(value);
    if (value !== "") {
      const filteredChats = fbChatBackup.current.filter((chat) =>
        normalizeString(chat.name).includes(normalizeString(value))
      );
      setFbChat(filteredChats);
    } else {
      setFbChat(fbChatBackup.current); 
    }
  };

  /* Elimina las notificaciones */
  // const removePushNotifications = (chat) => {
  //   console.log("CHAT PRESIONADO ", chat);
  //   noPushNotifications(`CN${chat.dbChatId}`)
  //     .then((res) => {
  //       console.log("Push eliminadas con éxito", res);
  //       props.navigation.navigate("PrivateChatDetail", {
  //         idChat: chat.dbChatId,
  //         imageUser: chat.image,
  //         userName: chat.name,
  //         newMessages: null,
  //         blocked: chat.blocked,
  //         permissions: null,
  //       });
  //     })
  //     .catch((err) => {
  //       console.error("Error al eliminar las notificaciones", err);
  //     });
  // };

  // const createChatAndNavigate = async selectedUser => {
  //   try {
  //     const {id} = await createPrivateChat(selectedUser.userId);
  //     const chatData = await getChatDataFromId(id);

  //     if (chatData) {
  //       props.navigation.navigate('PrivateChatsScreenDetails', {
  //         loggedUserId: chat.loggedUserId,
  //         chatData: chat,
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error al crear o encontrar el chat:', error);
  //   }
  // };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={"always"}
      >
        <View style={styles.container}>
          <Searcher
            placeholder={"Busca tus chats"}
            value={search}
            getValue={(value) => searchChats(value)}
          />

          {loading ? (
            <Loader style={{ flex: 1, height: 500 }} />
          ) : fbChat.length > 0 ? (
            fbChat.map((chat) => {
              return (
                <Link
                  key={chat.chatId}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textDecoration: "none",
                  }}
                  to={`/chats/${chat.dbChatId}/${chat.type}`}
                  state={{ idChat: chat.dbChatId }}
                >
                  <Member
                    image={chat.image}
                    name={chat.name}
                    type={chat.lastMessage.message}
                    buttonTime={false}
                    newMessages={null}                
                    // lastMessageTime={chat.lastMessage.timestamp}
                    pressAll={() => {
                      navigate(`/chats/${chat.dbChatId}/${chat.type}`, {
                        state: { chatData: chat },
                      });
                    }}
                  />
                </Link>
              );
            })
          ) : (
            <Text>No tienes chats activos</Text>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default PrivateChatsScreen;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: Colors.default.COLORS.background_light,
    paddingTop: 50,
    paddingBottom: 70,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
});
