import React, {useEffect, useState, useRef} from 'react';
import {StyleSheet, View, Text, Image, TouchableOpacity, Dimensions} from "react-native";
import {ReactComponent as Profile} from '../assets/profile.svg';
import {ReactComponent as ButtonMenu} from '../assets/buttonMenu.svg';
import AsyncStorage from "@react-native-async-storage/async-storage";
import typeHeaderStore from '../store/typeHeaderStore';
import * as Colors from '../styles/Colors';
import ActionSheet from 'actionsheet-react';

const {width} = Dimensions.get('window');

export const Header = () => {
    const [user, setUser] = useState(null);
    const [reloadUser, setReloaduser] = useState(true);
    //const [openMenu, setOpenMenu] = useState(false);
    const typeHeader = typeHeaderStore(state => state.typeHeader);
    const openMenu = typeHeaderStore(state => state.openMenu);
    const setOpenMenu = typeHeaderStore(state => state.setOpenMenu);
    const actionSheet = useRef();


    useEffect(() => {
        getterUser();
    }, [reloadUser]);

    const getterUser = () => {
        AsyncStorage.getItem('user').then(user => {
            setUser(JSON.parse(user));
            if (!user) {
                setReloaduser(!reloadUser);
            }
        });
    }

    const selectTypeHeader = () => {
        if (typeHeader === 'user') {
            return (
            <a style={{marginLeft: 'auto'}} href={'/profile'}>
                {user !== null && user.user.image.url !== null ?
                    <Image style={styles.image} source={{uri: `https://admin.growpsy.com${user.user.image.url}`}}/>
                    :
                    <Profile width={30}/>
                }
            </a>
            )
        } else {
            return(
                <ButtonMenu style={{marginLeft: 'auto'}} width={30} onClick={() => setOpenMenu(!openMenu)}/>
            )
        }
    }

    return(
        <View style={styles.container}>
            {width <= 820 &&
                <a style={{marginRight: 'auto'}} href={('/home')}>
                    <Image style={{width: 120, height: 50}} resizeMode={'contain'} source={require('../assets/growpsyRegister.png')}/>
                </a>
            }
            {/*<a href={'/profile'}>
                {user !== null && user.user.image.url !== null ?
                    <Image style={styles.image} source={{uri: `https://admin.growpsy.com${user.user.image.url}`}}/>
                    :
                    <Profile width={30}/>
                }
            </a>*/}
            {selectTypeHeader()}
            {/*{openMenu &&
                <div style={styles.dropdown}>
                    <p>Bloquear chat</p>
                </div>
            }*/}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: width <= 820 ? '100%' : '80%',
        height: 50,
        marginLeft: width <= 820 ? 0 : '20%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
        zIndex: 9,
        paddingHorizontal: width <= 820 ? 15 : 30,
        backgroundColor: Colors.default.COLORS.background_light
    },
    image: {
        width: 30,
        height: 30,
        borderRadius: 100
    }
})
