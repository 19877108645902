import React, { useEffect } from "react";
import {StyleSheet, View, TouchableOpacity, Image, Text} from 'react-native';
import moment from 'moment';

export const ArticleLarge = props => {

    return(
        <TouchableOpacity style={styles.container} onPress={props.press}>
            <View style={styles.containerText}>
                <Text style={styles.title}>{props.title}</Text>
                <Text style={styles.time}>{moment(props.create).format('DD/MM/yyyy')}</Text>
            </View>
            <Image style={styles.image} source={{uri: `https://admin.growpsy.com${props.image}`}}/>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        paddingVertical: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#E9ECEF'
    },
    containerText: {
        justifyContent: 'space-between',
        maxWidth: '60%',
    },
    title: {
        color: '#000000',
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    time: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 10,
        marginTop: 10
    },
    image: {
        width: '35%',
        minHeight: 70,
        height: '100%',
        borderRadius: 6
    }
})
