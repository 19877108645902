import React, {useEffect, useState} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Dimensions} from 'react-native';
import {getCategoriesPost} from '../services/data.service';
import {Channel} from '../components/channel.component';
import {Searcher} from '../components/searcher.component';
import {Loader} from '../components/loader.component';
import {Link} from 'react-router-dom';
import * as Colors from "../styles/Colors";

const {width} = Dimensions.get('window');

const CategoriesScreen = props => {
    const [allCategories, setAllCategories] = useState(null);
    const [categories, setCategories] = useState([]);
    const [search, setSearch] = useState('');

    /*Recoge las categorias de posts al entrara a la vista*/
    useEffect(() => {
        getCategoriesPost().then(res => {
            setAllCategories(res.data);
            setCategories(res.data);
            console.log('Categorias recogidas con exito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger la categorias', err);
        })
    }, []);

    /*Filtra las categorias de posts*/
    const searchCategories = (value) => {
        if (value !== '') {
            let newCategories = categories.filter(category => {
                if (category.title.toLowerCase().includes(value.toLowerCase())) return category;
            })

            if (newCategories.length > 0) setCategories(newCategories);
            else setCategories(allCategories);
        }
        else {
            setCategories(allCategories);
        }
    }

    return(
        allCategories === null ?
            <Loader style={{flex: 1}}/>
            :
            <SafeAreaView style={styles.safeArea}>
                <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                    <View style={styles.container}>
                        <Searcher
                            placeholder={'Busca tu categoría'}
                            value={search}
                            getValue={(value) => {
                                setSearch(value);
                                searchCategories(value);
                            }}
                        />
                        {categories !== null ?
                            categories.map(category => {
                                console.log('categories', category)
                                return(
                                    <Link style={{width: '90%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}} to={`/articles/${category.id}`}>
                                        <Channel title={category.title} image={category.image.url} color={category.color} /*press={() => props.navigation.navigate('Articles', {idCategory: category.id})}*//>
                                    </Link>
                                )
                            })
                            :
                            null
                        }
                    </View>
                </ScrollView>
            </SafeAreaView>
    )
}

export default CategoriesScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
})
