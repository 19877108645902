import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    ScrollView, Keyboard, Platform, Dimensions
} from "react-native";
import * as Colors from '../../styles/Colors';
import {login} from '../../services/user.service';
import loginStore from "../../store/loginStore";
import { Button } from '../../components/button.component';
import {FormikInput} from '../../components/formikInput.component';
import {Toast} from '../../components/toast.component'
import {Loader} from '../../components/loader.component';
import { Formik } from 'formik';
import {useNavigate} from 'react-router-dom';

const {width} = Dimensions.get('window');

const LoginScreen = props => {
    const [toastErrorLogin, setToastErrorLogin] = useState(false);
    const [loader, setLoader] = useState(false);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const setLogin = loginStore(state => state.setLogin);

    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: ''
    }

    /*Gestiona cuando el teclado se abre*/
    Keyboard.addListener('keyboardWillShow', () => {
        setKeyboardOpen(true);
    });

    /*Gestiona cuando el teclado se cierra*/
    Keyboard.addListener('keyboardWillHide', () => {
        setKeyboardOpen(false);
    });

    useEffect(() => {
        setToastErrorLogin(false);
    }, [toastErrorLogin]);

    //Ejecuta el login
    function loginUser(values) {
        setLoader(true)
        /*Elimina cualquier espacio que pueda haber en el campo del email*/
        login(values.email.replace(/\s/g, ''), values.password).then(res => {
            navigate('/home');
            setLogin(true);
            setLoader(false);
        }).catch(err => {
            setLoader(false)
            setToastErrorLogin(true);
            console.log('Error el logear al usuario', err);
        })
    }

    //Devulve la vista del login, si ejecutamos la funcion aparece un loader
    return(
        loader ?
            <Loader style={{flex: 1}}/>
            :
            <SafeAreaView style={styles.safeArea}>
                <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                    <View style={[styles.container, (Platform.OS === 'ios' && keyboardOpen === true) && {paddingBottom: 270}]}>
                        <View style={styles.containerImage}>
                            <Image style={styles.image} resizeMode={'contain'} source={require('../../assets/growpsyRegister.png')}/>
                        </View>
                        <Formik initialValues={initialValues} onSubmit={values => loginUser(values)}>
                            {({handleSubmit}) => {
                                return(
                                    <View style={{width: '100%', alignItems: 'center'}}>
                                        <FormikInput name={'email'} title={'Email'} keyboardType={'email-address'}/>
                                        <FormikInput style={{marginTop: 20}} name={'password'} title={'Contraseña'} secure={true}/>
                                        <TouchableOpacity style={styles.forgotPassword} onPress={() => navigate('/remember')}>
                                            <Text>¿Has olvidado la contraseña?</Text>
                                        </TouchableOpacity>
                                        <Button style={styles.button} primary={true} text={'Iniciar sesión'} press={() => handleSubmit()}/>
                                        <TouchableOpacity style={{marginTop: 20}} onPress={() => navigate('/register')}>
                                            <Text>¿Aún no tienes cuenta? <Text style={styles.colorRegister}>Regístrate</Text></Text>
                                        </TouchableOpacity>
                                    </View>
                                )
                            }}
                        </Formik>
                    </View>
                </ScrollView>
                <Toast message={'Email y/o contraseña incorrectos'} visible={toastErrorLogin}/>
            </SafeAreaView>
    )
}

export default LoginScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 50
    },
    containerImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 200,
        marginBottom: 30
    },
    image: {
        width: width <= 420 ? '90%' : (width <= 820 ? '50%' : '20%'),
        height: 250
    },
    forgotPassword: {
        width: '90%',
        marginTop: 10,
        paddingVertical: 7
    },
    button: {
        width: '90%',
        marginTop: 40
    },
    colorRegister: {
        color: Colors.default.COLORS.primary
    }
})
