import React, { useRef, useState, useCallback } from "react";
import {View, FlatList, Dimensions} from 'react-native';
import Message from "./message.component";
import AudioMessage from "./audioMessage.component";
//import { SheetManager } from "react-native-actions-sheet";
import userStore from '../store/userStore';
import {Loader} from './loader.component';

const {width, height} = Dimensions.get('window');

const FlatChat = props => {
    const [heights, setHeights] = useState([]);
    const [idMessage, setIdMessage] = useState(null);
    let scrollRef = useRef({});
    const user = userStore(state => state.user);
    //props.getRef(scrollRef);

    /*Renderiza los mensajes para que sean visibles en la vista*/
    const renderMessages = (message) => {
        /*Muestra los mensajes en el caso de que no hayan sido eliinados*/
        if (!message.item.hasOwnProperty('deleted') || !message.item.deleted) {
            return (
                /*Muestra los mensajes que no contengan lo propiedad archivo o que no sean de tipo audio*/
                !message.item.hasOwnProperty('file') || message.item.file.type !== 'AUDIO' ?
                    /*Monta los mensajes de cualquier tipo de archivo menos los audio, enviando al componente de mensaje
                    * todos los datos enviandolos desde un sitio u otro segun si existen las propiedades o no*/
                    <Message
                        name={`${message.item.hasOwnProperty('user') ? message.item.user.name : message.item.user_name} ${message.item.hasOwnProperty('user') ? message.item.user.lastName : ''}`}
                        imageUser={message.item.hasOwnProperty('user') ? message.item.user.profileImage : user.user.profile_image}
                        //image={message.item.image}
                        file={(message.item.hasOwnProperty('file') && message.item.hasOwnProperty('user')) ? message.item.file.url : (message.item.image.url !== null ? message.item.image.url : (message.item.video.url !== null ? message.item.video.url : (message.item.file.url !== null ? message.item.file.url : null)))}
                        fileName={(message.item.hasOwnProperty('file') && message.item.hasOwnProperty('user')) ? message.item.file.originalName : message.item.filename}
                        typeData={(message.item.hasOwnProperty('file') && message.item.hasOwnProperty('user')) ? message.item.file.type : (message.item.image.url !== null ? 'IMAGE' : (message.item.video.url !== null ? 'VIDEO' : (message.item.file.url !== null ? 'DOCUMENT' : null)))}
                        text={message.item.hasOwnProperty('message') ? message.item.message : message.item.note}
                        isSender={props.user.user.id === message.item.userId || props.user.user.id === message.item.user_id}
                        time={message.item.hasOwnProperty('createdAt') ? message.item.createdAt : message.item.created_at}
                        //isRead={message.item.isRead}
                        urlVideo={value => props.getUrlVideo(value)}
                        urlImage={value => props.getUrlImage(value)}
                        urlDocument={value => props.getUrlDocument(value)}
                        press={() => {
                            if (props.delete === true && message.item.file.type !== null && message.item.file.type !== undefined && message.item.file.type !== 'AUDIO') {
                                props.getIdMessageRemove(message.item._id);
                                props.getUrlDownloadFile(message.item.file.url);
                                props.getTypeData(message.item.file.type);
                                //SheetManager.show("actionSheet_chatMessage");
                            } else if (props.private === true && message.item.file.url !== '' && message.item.file.url !== null) {
                                props.getUrlDownloadFile(message.item.file.url);
                                props.getTypeData(message.item.file.type);
                                //SheetManager.show("actionSheet_privateChatMessage");
                            }
                        }}
                        typeChat={!message.item.hasOwnProperty('user') && 'notes'}
                        height={(value) => setHeights(heights => [...heights, value])}
                        color={message.item.user.color}
                    />
                    :
                    /*Crea los mensajes de audio*/
                    <AudioMessage
                        name={`${message.item.user.name} ${message.item.user.lastName}`}
                        audio={message.item.file.url}
                        userImage={message.item.user.profileImage}
                        time={message.item.createdAt}
                        isSender={props.user.user.id === message.item.userId}
                        color={message.item.user.color}
                        press={() => {
                            if (props.private === undefined) {
                                props.getIdMessageRemove(message.item._id);
                                //SheetManager.show("actionSheet_chatMessage");
                            }
                        }}
                    />
            )
        }
    }

    return (
        <FlatList
            style={{bottom: 0, height: width <= 420 ? height - 220 :  height - 50, paddingTop: 70, paddingBottom: width <= 420 ? 0 : 75}}
            ref={scrollRef}
            data={[...props.messages].reverse()}
            renderItem={(message, index) => renderMessages(message, index)}
            keyExtractor={(item) => item.messageId }
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={false}
            onEndReachedThreshold={0.5}
            inverted={true}
            initialNumToRender={props.messages.length}
            /*getItemLayout={(_, index) =>
              (heights.length > 0 &&{
              length: heights[index] + 10, //  WIDTH + (MARGIN_HORIZONTAL * 2)
              offset: heights.slice(0, index).reduce((a, c) => a + c, 0),  //  ( WIDTH + (MARGIN_HORIZONTAL*2) ) * (index)
              index,
            })}*/
            /*onContentSizeChange={() => scrollRef.current.scrollToIndex({index: props.messages.length <= 25 ? props.messages.length -1 : (props.isNewMessage === false ? props.messages.length - 1 : props.messages.length - (props.quantityNewMessages))})}
            onScrollToIndexFailed={info =>
                setTimeout(() => {
                    scrollRef.current.scrollToIndex({index: props.messages.length <= 25 ? props.messages.length -1 : (props.isNewMessage === false ? props.messages.length - 1  : props.messages.length - (props.quantityNewMessages))})
                }, 500)
            }
            onScroll={(e) => {
                if (props.lastPage === false && e.nativeEvent.contentOffset.y === 0) {
                    props.isTop(true);
                }
            }}*/
            /*ListHeaderComponentStyle={props.lastPage && {paddingVertical: 20}}
            ListHeaderComponent={() => {
                return(
                    props.lastPage === false && props.typeChat !== 'notes') &&
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <Loader />
                        </View>

            }}*/
        />
    )
}

export default FlatChat;

/*export default React.memo(FlatChat, (prevProps, nextProps) => {
    //return prevProps.messages.length === nextProps.messages.length;
});*/
