import React, {useEffect} from 'react';
import {StyleSheet, View, TouchableOpacity, Text} from 'react-native';
import * as Colors from '../styles/Colors';
import {Button} from './button.component';
import Edit from '../assets/edit.svg';
import Trash from '../assets/trashGrey.svg';
import {ReactComponent as Write} from '../assets/writeButton.svg';
import moment from 'moment';
import 'moment/locale/es';

export const Session = props => {

    let actualDate = new Date();

    const incrementTimeToSessionDate = (sessionDate, minutes) => {
        let session = new Date(sessionDate);
        session.setTime(session.getTime() + minutes * 60 * 1000);
        return session;
    }

    return(
        <View style={styles.container}>
            <View style={styles.containerTop}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 'bold', color: '#FFFFFF'}}>{props.name}</Text>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        {(props.isView && props.typeUser === 'doctor') &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, backgroundColor: '#FFFFFF', borderRadius: 100, cursor: 'pointer', marginRight: 10}} onClick={props.pressEdit}>
                                <img style={{width: 15, height: 15}} src={Edit}/>
                            </div>
                        }
                        {(props.isView && props.typeUser === 'doctor') &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, backgroundColor: '#FFFFFF', borderRadius: 100, cursor: 'pointer'}} onClick={props.pressDelete}>
                                <img style={{width: 20, height: 20}} src={Trash}/>
                            </div>
                        }
                    </div>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500, color: '#FFFFFF'}}>{props.date}</Text>
                </View>
            </View>

            <View style={styles.containerBottom}>
                <View style={styles.containerTextBottom}>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 'bold'}}>Psicólogo</Text>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{props.creator}</Text>
                </View>
                <View style={styles.containerTextBottom}>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 'bold'}}>Canal</Text>
                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{props.channel}</Text>
                </View>
            </View>

            {(props.isView && props.opened) &&
                /*<a
                    style={styles.button}
                    href={`https://videochat.growpsy.com/sfu/${props.idSession}/${props.token}/`}
                    target={'_blank'}
                    onClick={props.press}
                >
                    Iniciar videochat
                </a>*/
                <Button style={styles.button} text={'Iniciar videollamada'} press={props.press}/>
            }{/* :
                <TouchableOpacity style={styles.buttonIcon} activeOpacity={0.7}>
                    <Text style={styles.textButtonIcon}>Ver notas</Text>
                    <Write/>
                </TouchableOpacity>
            }*/}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginVertical: 15,
        paddingBottom: 15,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#E1E1E1',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    containerTop: {
        width: '100%',
        padding: 10,
        borderTopStartRadius: 6,
        borderTopEndRadius: 6,
        backgroundColor: Colors.default.COLORS.primary
    },
    containerBottom: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        padding: 10
    },
    containerTextBottom: {
        width: '50%'
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: 100,
        height: 40,
        backgroundColor: Colors.default.COLORS.primary,
        color: Colors.default.COLORS.text_light
    },
    buttonIcon: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        height: 40,
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: 30,
        borderWidth: 1,
        borderColor: Colors.default.COLORS.primary
    },
    textButtonIcon: {
        marginRight: 7,
        color: Colors.default.COLORS.primary
    }
})
