import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';


const axiosInstance = axios.create({baseURL: 'https://admin.growpsy.com/', responseType: 'json'});

/**
 * Recoge el usuario
 * */
export const getUser = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get('get_my_user.json', {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Registro
 * */
export const register = (profile, name, lastname, lastname2, email, pass) => {
  const data = {
    user: {
      user_type: profile,
      name: name,
      lastname: lastname,
      lastname2: lastname2,
      email: email,
      password: pass,
    }
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('signup.json', data).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * Iniciar sesion
 * */
export const login = (email, pass) => {
  return new Promise((resolve, reject) => {
    axiosInstance.post('login.json', {user: {email: email, password: pass}}).then(async res => {
      await AsyncStorage.setItem('token', res.headers.authorization);
      await AsyncStorage.setItem('USER', JSON.stringify(res.data));
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Cerrar sesion
 * */
export const logout = async () => {
  console.log(await AsyncStorage.getItem('token'))
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get('logout.json', {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Actualiza el usuario
 * */
export const updateUser = async (userObject) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post('update_user.json', {user: userObject}, {headers}).then(async res => {
      await AsyncStorage.setItem('token', res.headers.authorization);
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Recoge las preguntas del formulario de registro
 * */

export const getFormRegister = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get('previous_questions.json', {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Recupera la contraseña del usuario
 * */
export const rememberPassword = async (email) => {
  return new Promise((resolve, reject) => {
    axiosInstance.post('users/password.json', {user: {email: email}}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Elimina una cuenta
 * */
export const deleteAccount = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('delete_account.json', {}, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Recoge los canales a los que estoy suscrito
 * */
export const getMyChannels = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  return new Promise((resolve, reject) => {
    axiosInstance('my_channels.json', {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Unirse a un canal
 * */
export const joinToChannel = async (idChannel) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  const data = {
    channel_id: idChannel
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('join_channel.json', data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Te sales de un canal
 * */
export const disjoinToChannel = async (idChannel) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  const data = {
    channel_id: idChannel
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('exit_channel.json', data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Aceptar una invitacion
 * */
export const acceptInvitation = async (idInvitation) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  const data = {
    invitation_id: idInvitation
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('accept_invitation.json', data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Rechazar unvitacion
 * */
export const rejectInvitation = async (idInvitation) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  const data = {
    invitation_id: idInvitation
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('reject_invitation.json', data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * Registra el dispositiovo dle usuario
 * */
export const RegisterDevice = async (deviceUID, SO, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': await AsyncStorage.getItem('token')
  }
  const data = {
    device_uid: deviceUID,
    so: SO,
    token: token
  }
  return new Promise((resolve, reject) => {
    axiosInstance.post('register_device.json', data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}
