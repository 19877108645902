import React from 'react';
import {StyleSheet, View, ActivityIndicator, Dimensions} from 'react-native';
//import * as Colors from '../styles/Colors';

const {width, height} = Dimensions.get('window');
export const Loader = props => {
  return(
    <View style={[styles.container, props.style]}>
      <ActivityIndicator size={'large'} color={'#99bfe3'}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: width,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
