import React from 'react';
import {StyleSheet, TouchableOpacity, Text, Image} from 'react-native';
import * as Colors from '../styles/Colors';

export const Button = props => {
    return(
        <TouchableOpacity style={[props.style, props.primary ? styles.primaryButton : styles.secundaryButton, props.imageIcon && { flexDirection: props.iconPosition === 'after' ? 'row-reverse' : 'row' }]} activeOpacity={0.7} keyboardShouldPersistTaps onPress={props.press}>
            { props.imageIcon && props.imageIcon }
            <Text style={[props.primary ? styles.textPrimary : styles.textSecundary, props.textStyle && props.textStyle]}>{props.text}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    primaryButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        borderRadius: 30,
        backgroundColor: Colors.default.COLORS.primary,
    },
    textPrimary: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 600
    },
    secundaryButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        backgroundColor: 'transparent',
        borderRadius: 30,
        borderWidth: 1,
        borderColor: Colors.default.COLORS.primary,
    },
    textSecundary: {
        color: Colors.default.COLORS.primary,
        fontFamily: 'Montserrat',
        fontWeight: 600
    }
})
