import React, {useRef, useState, useEffect} from 'react';
import {StyleSheet, View, Text, TouchableOpacity} from 'react-native';
//import ModalSelector from 'react-native-modal-selector';

export const Picker = props => {

    return(
        <View style={styles.container}>
            <Text style={styles.title}>{props.title}</Text>
            <View style={styles.containerPicker}>
                <select style={styles.picker} onChange={value => props.getValue(value.target.value)}>
                    <option selected>{props.value ? props.value.label : 'Selecciona una opción'}</option>
                    {props.values
                        .filter(option => !props.value || option.key !== props.value.key)
                        .map(option => (
                        <option key={option.key} value={option.key}>
                            {option.label}
                        </option>
                        ))}
                </select>
            </View>
            {props.error !== null ? <Text style={styles.error}>{props.error}</Text> : null}
        </View>

    )
}

const styles = StyleSheet.create({
    container: {
        width: '90%',
        marginTop: 10
    },
    title: {
        marginBottom: 5
    },
    containerPicker: {
        alignItems: 'center',
    },
    picker: {
        width: '100%',
        height: 42,
        paddingLeft: 15,
        border: '1px solid #D8DBDF',
        borderRadius: 6,
        outlineStyle: 'none'
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginBottom: 5
    }
})
