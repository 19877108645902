import React from 'react';
import {StyleSheet, View, TouchableOpacity, Text} from 'react-native';
import {ReactComponent as Seassons} from '../assets/writeChannel.svg';
import {ReactComponent as Messages} from '../assets/messaageChannel.svg';
import {ReactComponent as Members} from '../assets/Group.svg';
import {ReactComponent as Write} from '../assets/writeButton.svg';

export const OptionChannel = props => {

    return(
        props.compact ?
            <TouchableOpacity style={styles.container} activeOpacity={0.7} onPress={props.press}>
                <View style={[styles.top, props.type === 'Sesiones' && styles.topBlue, props.type === 'Mensajes' && styles.topYellow, props.type === 'Miembros' && styles.topRed, props.type === 'Diario' && styles.topGreen]}>
                    {props.type === 'Sesiones' && <Seassons/>}
                    {props.type === 'Mensajes' && <Messages/>}
                    {props.type === 'Miembros' && <Members/>}
                    {props.type === 'Diario' && <Write height={25}/>}
                </View>

                <View style={styles.bottom}>
                    <Text style={styles.text}>{props.type}</Text>
                </View>
            </TouchableOpacity>
            :
            <TouchableOpacity style={styles.containerNoCompact} activeOpacity={0.7} onPress={props.press}>
                <View style={[styles.cubeNoCompact, props.type === 'Sesiones' && styles.topBlue, props.type === 'Miembros' && styles.topRed]}>
                    {props.type === 'Sesiones' && <Seassons/>}
                    {props.type === 'Miembros' && <Members/>}
                </View>
                <Text>{props.type}</Text>
            </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%'
    },
    top: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 15
    },
    topBlue: {
        backgroundColor: '#F3F3FF'
    },
    topYellow: {
        backgroundColor: '#FFF6E8'
    },
    topRed: {
        backgroundColor: '#FFEEEF'
    },
    topGreen: {
        backgroundColor: '#EEFFEF'
    },
    bottom:{
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRightWidth: 1,
        borderRightColor: '#E1E1E1',
        borderBottomWidth: 1,
        borderBottomColor: '#E1E1E1',
        borderLeftWidth: 1,
        borderLeftColor: '#E1E1E1'
    },
    text: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    },

    containerNoCompact: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginVertical: 10
    },
    cubeNoCompact: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        borderRadius: 6,
        marginRight: 5
    }
})
