import React from 'react';
import {StyleSheet, View, TouchableOpacity, Image, Text, Dimensions} from 'react-native';
import {Button} from "./button.component";
//import Ionicons from 'react-native-vector-icons/Ionicons';

const {width} = Dimensions.get('window');

export const Course = props => {

    return (
        <TouchableOpacity activeOpacity={.7} style={styles.container} onPress={props.press}>
            <View style={{width: width <= 420 ? '40%' : '100%'}}>
                <Image style={styles.image} resizeMode={'cover'} source={{ uri: `https://admin.growpsy.com${props.image}`}}/>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', width: '60%', paddingLeft: 15, paddingVertical: 6}}>
                <View style={{width: '80%'}}>
                    <Text style={styles.title}>{props.title}</Text>
                    <View style={[styles.containerComplete, props?.completed >= 100 ? styles.completed : styles.inProgress]}>
                        { props.completed >= 100 ?
                            <Text style={[styles.completedTag, styles.textCompleted]}>Completado</Text>
                            :
                            <Text style={[styles.completedTag, styles.textInProgress]}>({props?.completed}%) En curso</Text>
                        }
                    </View>
                </View>
            </View>

            { props.textButton &&
            <Button
                style={{width: '90%', marginTop: 'auto', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto'}}
                text={props.textButton}
                primary
            />
            }
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        minHeight: 135,
        width: width <= 820 ? '100%' : '90%',
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    image: {
        width: '100%',
        height: width <= 420 ? 70 : 150,
        borderRadius: 6
    },
    title: {
        fontSize: 14,
        marginBottom: 8,
        flexWrap: 'wrap',
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    },
    containerComplete: {
        alignSelf: 'flex-start',
        paddingVertical: 3,
        paddingHorizontal: 10,
        borderRadius: 50,
        marginVertical: 10
    },
    completed: {
        backgroundColor: '#C3FFEA',
    },
    inProgress: {
        backgroundColor: '#E8E8FF',
    },
    textCompleted: {
        color: '#015D3D'
    },
    textInProgress: {
        color: '#4148FF'
    },
    completedTag: {
        fontSize: 12,
        fontFamily: 'Montserrat',
        fontWeight: 500,
    }
})
