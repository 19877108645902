import '../App.css';
import { View } from 'react-native';
import React from 'react';
import Router from './routes';
import AuthRouter from './authRoutes';
import { useLocation } from 'react-router-dom';
import { Tabs } from '../components/tabs.component';
import { Header } from '../components/header.component';
import { SideBar } from '../components/sidebar.component';


const AppContent = ({ login, width }) => {
    const location = useLocation();

    const noHeaderPaths = ['/videochat/'];
    const noSidebarPaths = ['/videochat/'];

    const showHeader = !noHeaderPaths.includes(location.pathname);
    const showSidebar = !noSidebarPaths.includes(location.pathname);

    return (
        <>
            {login && showHeader && <Header />}
            {login ? (
                width > 820 ? (
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        {showSidebar && (
                            <View style={{ width: '20%' }}>
                                <SideBar />
                            </View>
                        )}
                        <View style={{ width: showSidebar ? '80%' : '100%', minHeight: '100vh' }}>
                            <Router />
                        </View>
                    </View>
                ) : (
                    <Router />
                )
            ) : (
                <AuthRouter />
            )}
            {/*El tabBar se muestra en el caso de que el login este realizado y el tamaño de la pantalla sea inferior o igual a 820px*/}
            {(login && width <= 820 && showHeader) && <Tabs />}
        </>
    );
}

export default AppContent;