import React from 'react';
import {StyleSheet, View, TextInput, Text} from 'react-native';

export const TextArea = props => {

    return(
        <View style={styles.container}>
            <Text style={styles.title}>{props.title}</Text>
            <TextInput
                style={styles.input}
                placeholder={props.placeholder}
                value={props.value}
                onChangeText={(value) => props.getValue(value)}
                multiline
            />
            {props.error !== null && <Text style={styles.error}>{props.error}</Text>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '90%',
        marginTop: 10
    },
    title: {
        marginBottom: 5
    },
    input: {
        width: '100%',
        height: 86,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderWidth: 1,
        borderColor: '#D8DBDF',
        borderRadius: 6,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        outlineStyle: 'none'
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginBottom: 5
    }
})
