import './App.css';
import { View, Dimensions } from 'react-native';
import React, {useState, useEffect, useRef} from 'react';
import { isLogged } from './services/auth.service';
import loginStore from './store/loginStore';
import { BrowserRouter } from 'react-router-dom';
import AppContent from './routes/AppContent';
import AsyncStorage from "@react-native-async-storage/async-storage";
import io from "socket.io-client";
import RequestInterceptor from './interceptors/axios.interceptor';


const {width, height} = Dimensions.get('window');

const App = () => {
    const socketRef = useRef(null);

    const [checkingLogin, setCheckingLogin] = useState(true);
    const login = loginStore(state => state.login);
    const setLogin = loginStore(state => state.setLogin);

    /*Al inicializar la app*/
    useEffect(() => {
        /*En el caso de que los localstorage esten sin inicializar, los inicializamos a un array vacio para que no
        * se de el caso de si en algun momento pide de ellos no de error al no tener nada*/
        if (localStorage.getItem('channelNotification') == null || localStorage.getItem('channelNotification') == "null"){
            localStorage.setItem('channelNotification', JSON.stringify([]));
        }

        if (localStorage.getItem('privateNotification') == null || localStorage.getItem('privateNotification') == "null"){
            localStorage.setItem('privateNotification', JSON.stringify([]));
        }

        /*Comprueba si el usuario ya ha hecho login en algun momento en el caso de haberlo hecho y tener un token
        * entra a la aplicacion, si no es el caso y no tiene un token guardado te mantendra en el login*/
        isLogged().then(res => {
            setLogin(true);
        }).catch(err => {
            setLogin(false);
        }).finally(() => setCheckingLogin(false));
    }, []);

    /*Recoge el usuario
    * conecta el socket
    * inicializa los eventos del socket*/
    useEffect(() => {
        AsyncStorage.getItem('user').then(user => {
            socketRef.current = io('https://node.growpsy.com');
            if (user) {
                socketEvents(JSON.parse(user).user.id);
            }
        })
    }, []);

    const socketEvents = (idUser) => {
        socketRef?.current?.emit('join', {userId: idUser});

        socketRef?.current?.on('welcome', () => {
            console.log('welcome to socket');
        })

        socketRef?.current?.on('joined', () => {
            console.log('joined to socket');
        })

        /*Gestiona las notificaciones de mensajes sin leer (puntos naranjas que parpadean)
        Al conectarse al socket recibe el evento.
        * te dice la cantidad de mensajes que tienes sin leer en cada canal*/
        socketRef?.current?.on('pending-messages', async (messages) => {
            if (window.location.pathname === '/home') {
                console.log("socket pending-messages fired", messages)

                /*pone los localstorage vacios para no dejar ningun mensaje sin leer que se haya quedado*/
                await AsyncStorage.setItem('channelNotification', JSON.stringify([]));
                await localStorage.setItem('privateNotification',JSON.stringify([]));

                /*LLena los locarlStorage con los mensajes sin leer ya sea conversaciones o grupos*/
                await AsyncStorage.setItem('channelNotification', JSON.stringify(messages.channels));
                await localStorage.setItem('privateNotification',JSON.stringify(messages.conversations));
            }
        })

        /*Gestiona las notificaciones de mensajes sin leer (puntos naranjas que parapdean)
        * al recibir un nuevo mensaje*/
        socketRef?.current?.on('newMessage', async (item) => {
            let channels = [];
            let privates = [];
            /*Si el usuario que ha enviado el mensaje es distinto del usuario con el que la sesion esta iniciada*/
            if (idUser !== item.userId) {
                /*Recoje los valores que ya tenemos guardados de mensajes sin leer*/
                channels = JSON.parse(localStorage.getItem('channelNotification'));
                privates = JSON.parse(localStorage.getItem('privateNotification'));

                /*Comprueba si el nuevo mensaje es de un grupo o de una conversacion privada.
                * comprueba que no estemos dentro de la vista en la que ha llegado el mensaje (ya que al estar dentro de esa vista el mensaje habra sido leido y no estara por leer)
                * en el caso de cumplir estas condiciones añade el grupo o la conversacion a su respectivo array*/
                if (item.channelId.includes('CH') && !window.location.pathname.includes(`${item.channelId.slice(2, item.channelId.length)}/CH`)) {
                    channels.push(item.channelId);
                }
                if (item.channelId.includes('CN') && !window.location.pathname.includes(`${item.channelId.slice(2, item.channelId.length)}/CN`)) {
                    privates.push(item.channelId);
                }

                /*para terminar mete los mensajes dentro de los localstorage para trabajar con ellos en otro punto de la aplicacion*/
                await localStorage.setItem('channelNotification', JSON.stringify(channels));
                await localStorage.setItem('privateNotification', JSON.stringify(privates));
            }
        })
    }

    return (
        <View style={{ height: '100vh', backgroundColor: '#FFFFFF' }}>
            <RequestInterceptor />
            {!checkingLogin &&
                <BrowserRouter>
                    <AppContent login={login} width={width} />
                </BrowserRouter>
            }

        </View>
    );
}

export default App;
