import React, {useEffect, useState} from 'react';
import {StyleSheet, View, Text, Image} from 'react-native';
import io from 'socket.io-client';

import {ReactComponent as HomeFocus} from '../assets/tabBar/homeFocus.svg';
import {ReactComponent as HomeNoFocus} from '../assets/tabBar/homeNoFocus.svg';
import {ReactComponent as ChannelsFocus} from '../assets/tabBar/channelsFocus.svg';
import {ReactComponent as ChannelsNoFocus} from '../assets/tabBar/channelNofocus.svg';
import {ReactComponent as ChatBubbles} from '../assets/tabBar/chatbubbles.svg'
import {ReactComponent as ChatBubblesNoFocus} from '../assets/tabBar/chatbubblesNoFocus.svg'
import {ReactComponent as CoursesFocus} from '../assets/tabBar/coursesFocus.svg';
import {ReactComponent as CoursesNoFocus} from '../assets/tabBar/coursesNoFocus.svg';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {getUser} from "../services/user.service";
import * as Colors from "../styles/Colors";

export const SideBar = () => {
    const [notifGroups, setNotifGroups] = useState(false);
    const [notifPrivate, setNotifPrivate] = useState(false);
    const [isShowingText, setIsShowingText] = useState(true);

    /*Hace que los puntos de las notificaciones de mensajes sin leer parpadeen*/
    useEffect(() => {
        const toggle = setInterval(() => {
            setIsShowingText(!isShowingText);
        }, 700);

        return () => clearInterval(toggle);
    })

    /*Revisa si el los localstorage hay elementos nuevos que marcar con las notificaciones de mensajes sin leer
    * cada medio segundo
    * este solo pone los puntos en el sidebar*/
    useEffect(() => {
        setInterval(() => {
            let channels;

            channels = localStorage.getItem('channelNotification');
            //console.log('CHANNELS SIDEBAR', channels);
            if (JSON.parse(channels) && JSON.parse(channels).length > 0) {
                setNotifGroups(true)
            } else {
                setNotifGroups(false);
            }

            let privates;
            privates = localStorage.getItem('privateNotification');
          //  console.log('PRIVATES SIDEBAR', privates);
            if (JSON.parse(privates) && JSON.parse(privates).length > 0) {
                setNotifPrivate(true)
            } else {
                setNotifPrivate(false);
            }
        }, 500);
    }, []);

    return(
        <View style={styles.container}>

            <a style={{marginTop: 20, marginRight: 'auto', marginLeft: 'auto'}} href={('/home')}>
                <Image style={{width: 170, height: 70}} resizeMode={'contain'} source={require('../assets/logoWhite.png')}/>
            </a>

            <a style={{width: '90%', textDecoration: 'none'}} href={'/home'}>
                <View style={[styles.item, {marginTop: 20}]}>
                    <HomeFocus style={styles.icon} width={30}/>
                    <Text style={styles.text}>Inicio</Text>
                </View>
            </a>
            <a style={{width: '90%', textDecoration: 'none'}} href={'/myChannels'}>
                <View style={styles.item}>
                    <ChannelsFocus style={styles.icon} width={30}/>
                    <Text style={styles.text}>Mis grupos</Text>
                    {notifGroups &&
                        ( isShowingText &&
                            <View style={styles.dotInfo}></View>
                        )
                    }
                </View>
            </a>
            <a style={{width: '90%', textDecoration: 'none'}} href={'/chats'}>
                <View style={styles.item}>
                    <ChatBubbles style={styles.icon} width={25}/>
                    <Text style={styles.textSpecial}>Chats</Text>
                    {notifPrivate &&
                        (isShowingText &&
                            <View style={styles.dotInfo}></View>
                        )
                    }
                </View>
            </a>
            <a style={{width: '90%', textDecoration: 'none'}} href={'/events'}>
                <View style={styles.item}>
                    <HomeFocus style={styles.icon} width={30}/>
                    <Text style={styles.text}>Eventos</Text>
                </View>
            </a>
            <a style={{width: '90%', textDecoration: 'none'}} href={'/courses'}>
                <View style={styles.item}>
                    <CoursesFocus style={styles.icon} width={30}/>
                    <Text style={styles.text}>Cursos</Text>
                </View>
            </a>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        width: '20%',
        height: '100vh',
        backgroundColor: '#01334C',
        borderRightWidth: 1,
        borderColor: '#CCCCCC'
    },
    /*containerItem: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        textDecoration: 'none'
    },*/
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: 10,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderColor: '#FFFFFF'
    },
    icon: {
        marginRight: 10
    },
    text: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    textSpecial: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        marginLeft: 6
    },
    dotInfo: {
        width: 10,
        height: 10,
        marginLeft: 'auto',
        borderRadius: 100,
        backgroundColor: '#d38001',
    }

})
