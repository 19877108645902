import AsyncStorage from '@react-native-async-storage/async-storage';

export const isLogged = () => {
  return new Promise((resolve, reject) => {
    AsyncStorage.getItem('token')
      .then(value => {
        if (value !== null) resolve(value);
        else reject(false);
      })
      .catch(err => {
        reject(err);
      });
  });
};
