import React, { useState, useLayoutEffect, useEffect } from "react";
import {StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity, Text, Image, Dimensions, Alert, Modal} from 'react-native';
import * as Colors from '../styles/Colors';
//import Ionicons from 'react-native-vector-icons/Ionicons';
import {OptionChannel} from '../components/optionChannel.component';
import {Button} from '../components/button.component';
import {getChannel} from '../services/data.service';
import {getChatDataFromId} from '../services/firestore.service';
import {useParams, useLocation, Link, useNavigate} from "react-router-dom";
import typeHeaderStore from '../store/typeHeaderStore';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {joinToChannel, disjoinToChannel} from '../services/user.service';
import {ModalSubscribe} from '../components/modalSubscribe.component';
import {requestJoinChannel} from '../services/data.service';

const {width} = Dimensions.get('window');

const ChannelOption = props => {
    const [user, setUser] = useState(null);
    const [channel, setChannel] = useState(null);
    const [chat, setChat] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [suscribe, setSuscribe] = useState(false);
    const [modalNoSubscribe, setModalNoSubscribe] = useState(false);
    const [toastSuscribe, setToastSuscribe] = useState(false);
    const [toastUnsuscribe, setToastUnsuscribe] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastErrorJoin, setToastErrorJoin] = useState(false);
    const [toastErrorRequest, setToastErrorRequest] = useState(false);
    //const user = userStore(state => state.user);
    const setTypeHeader = typeHeaderStore(state => state.setTypeHeader);
    const openMenu = typeHeaderStore(state => state.openMenu);
    const setOpenMenu = typeHeaderStore(state => state.setOpenMenu);
    const {price} = useLocation();

    const {id} = useParams();
    const navigate = useNavigate();

    /*Al entrar a la vista*/
    useEffect(() => {
        setTypeHeader('menu');
        getuser();
    
        fetchChannelAndChat(id);
    }, [id]);
    

    const fetchChannelAndChat = async (id) => {
        try {
            const res = await getChannel(id);
            setChannel(res.data);
            setSuscribe(res.data.subscribed);
            console.log('Canal recogido con éxito', res);
    
            if (res.data && res.data.id) {
                const chatData = await getChatDataFromId(res.data.id);
                setChat(chatData);
                console.log("DATOS CHAT ", chatData);
            } else {
                console.log("El canal no tiene un id válido");
            }
        } catch (err) {
            console.log('ERROR al recoger el canal', err);
        }
    };
    
    /*Recoge el usuario de Asyncstorage*/
    const getuser = () => {
        AsyncStorage.getItem('user').then(user => {
            setUser((JSON.parse(user)));
        })
    }

    /*Abandona un canal*/
    const disjoinChannel = () => {
        disjoinToChannel(channel.id).then(res => {
            setSuscribe(false);
        }).catch(err => {
            /*Detecta si la peticion a dado un error para abandonar el canal y saca un alert*/
            if (err.response.status === 400) {
                alert('Se a producido un error, intenta abandonar el grupo más tarde.')
            }
            console.log('ERROR al abandonar el canal', err);
        })
    }

    const onNavigateBackNextScreen = (value) => {
        setSuscribe(value)
    }

    /*Une al canal*/
    const joinChannel = () => {
        joinToChannel(channel.id).then(res => {
            setOpenMenu(false);
            setSuscribe(true);
            setModalNoSubscribe(false);
            setToastSuscribe(true);
            console.log('Union al canal realizada con exito', res.data);
        }).catch(err => {
            /*Detecta si ha habido un error al unirse al canal y muestra un alert para informar*/
            if (err.response.data.status === 400) {
                setModalNoSubscribe(false);
                alert(err.response.data.error);
            }
            console.log('ERROR al unirse al canal', err.response.data);
        })
    }

    /*Indica al boton cual va a ser la acción que tiene al clicar sobre el segun el tipo de suscripción que tenga el grupo*/
    const actionToJoinChannel = () => {
        /*Si eres psicologo te une directamente a al canal si la necesidad de compra*/
        if (user.user.user_type === 'doctor') {
            joinChannel();
        } else {
            /*Si necesita de suscripcion*/
            if (channel.show_buy_page === true) {
                setModalNoSubscribe(false);
                /*Te lleva a la pagina para que te suscribas*/
                navigate('/subscription');
            } else if (channel.show_buy_page === false && channel.need_additional_subscription === true) {
                /*Envia una peticion a los administradores de la aplicacion para entrar al canal,
                * los administradores seran los qu ete concedan el acceso al grupo*/
                requestJoinChannel(user.user.id, channel.id).then(res => {
                    setModalNoSubscribe(false);
                    alert('Tu solicitud se ha enviado con éxito, pronto nos pondremos en contacto contigo.');
                    //alertRequestToJoinSendCorrectly();
                }).catch(err => {
                    /*En el caso de dar error sera porque en el grupo no caben más personas*/
                    setModalNoSubscribe(false);
                    alert('Lo sentimos el grupo esta completo en este momento.');
                    setToastErrorRequest(true);
                })
                /*En el caso de no necesitar compra ni suscripcion para el curso te uniras directamente a el*/
            } else if (channel.show_buy_page === false && channel.need_additional_subscription === false) {
                joinChannel();
            }
        }
    }

    /**
     * Muestra el texto del boton en funcion del tipo de suscripción al canal
     * */
    const textButtonBySituation = () => {
        if (user.user.user_type === 'doctor') {
            return 'Suscribirme';
        } else {
            if (channel.show_buy_page === true) {
                return 'Compra tu suscripción';
            } else if (channel.show_buy_page === false && channel.need_additional_subscription === true) {
                return 'Solicitar invitación al canal';
            } else if (channel.show_buy_page === false && channel.need_additional_subscription === false) {
                return 'Suscribirme gratis'
            }
        }
    }

    /**
     * Indica la funcion del boton segun el tipo de suscripción al canal y el usuario
     * */
    const actionButtonBySituation = () => {
        if (user.user.user_type === 'doctor') {
            setModalNoSubscribe(true);
        } else {
            if (channel.show_buy_page === true) {
                navigate('/subscription');
            } else if (channel.show_buy_page === false && channel.need_additional_subscription === true) {
                setModalNoSubscribe(true);
            } else if (channel.show_buy_page === false && channel.need_additional_subscription === false) {
                setModalNoSubscribe(true);
            }
        }
    }

    return(
        channel !== null &&
        <SafeAreaView style={styles.safeArea}>
            {(openMenu) &&
                (suscribe &&
                    <div style={styles.dropdown}>
                        {(user!== null && user.user.id === channel.user_id) && <div style={styles.optionMenu} onClick={() => navigate(`/editChannel/${id}`)}>Editar grupo</div>}
                        <div style={styles.optionMenu} onClick={() => disjoinChannel()}>Abandonar grupo</div>
                    </div>
                )
            }

            {modalNoSubscribe &&
                <ModalSubscribe
                    buttonText={textButtonBySituation()}
                    closeModal={value => setModalNoSubscribe(value)}
                    channelTitle={channel.name}
                    price={channel.price}
                    joinChannel={() => actionToJoinChannel()}/>
            }

            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                <View style={styles.container}>
                    <Image style={{width: '100%', height: 170, resizeMode: "contain"}} source={{uri: 'https://admin.growpsy.com/'+channel.channel_image}}/>

                    <View style={styles.containerContent}>
                        <Text style={styles.title}>{channel.name}</Text>
                        <div style={{marginTop: 30, marginBottom: 30}} dangerouslySetInnerHTML={{__html: channel.description}}>
                        </div>

                        <View style={styles.containerOptions}>
                            <div style={(channel.has_chat && user.user.user_type === 'doctor') ? {width: '22%'} : ((user.user.user_type === 'doctor' || channel.has_chat) ? {width: '30%'} : {width: '45%'})}>
                                <OptionChannel
                                    type={'Sesiones'}
                                    compact
                                    press={() => {
                                        if (suscribe) {
                                            navigate(`/sessions/${id}`, {state: {colors: channel.colors}})
                                        } else {
                                            setModalNoSubscribe(true);
                                        }
                                    }}
                                />
                            </div>

                            {channel.has_chat &&
                                <div style={(channel.has_chat && user.user.user_type === 'doctor') ? {width: '22%'} : ((user.user.user_type === 'doctor' || channel.has_chat) ? {width: '30%'} : {width: '45%'})}>
                                    <OptionChannel
                                        type={'Mensajes'}
                                        compact
                                        press={() => {
                                            if (suscribe) {
                                                navigate(`/chats/${id}/CH`, {state: {colors: channel.colors, chatData: chat}})
                                            } else {
                                                setModalNoSubscribe(true);
                                            }
                                        }}
                                    />
                                </div>
                            }

                            <div style={(channel.has_chat && user.user.user_type === 'doctor') ? {width: '22%'} : ((user.user.user_type === 'doctor' || channel.has_chat) ? {width: '30%'} : {width: '45%'})}>
                                <OptionChannel
                                    type={'Miembros'}
                                    compact
                                    press={() => {
                                        if (suscribe) {
                                            navigate(`/members/${id}`)
                                        } else {
                                            setModalNoSubscribe(true);
                                        }
                                    }}
                                />
                            </div>

                            {user.user.user_type === 'doctor' &&
                                <div style={(channel.has_chat && user.user.user_type === 'doctor') ? {width: '22%'} : ((user.user.user_type === 'doctor' || channel.has_chat) ? {width: '30%'} : {width: '45%'})}>
                                    <OptionChannel
                                        type={'Diario'}
                                        compact
                                        press={() => {
                                            if (suscribe) {
                                                navigate(`/notes/${id}`)
                                            } else {
                                                setModalNoSubscribe(true);
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </View>

                        {suscribe ?
                            <Button
                                style={{marginTop: 30}}
                                text={'Suscrito'}
                            />
                            :
                            <Button
                                style={{marginTop: 30}}
                                text={textButtonBySituation()}
                                primary
                                press={() => {
                                    actionButtonBySituation();
                                    //if (!suscribe) setModalNoSubscribe(true)
                                    //joinChannel();
                                }}
                            />
                        }

                    </View>
                </View>
            </ScrollView>
            {/*<ActionSheet id={'actionSheet_channel'}>
                <View style={styles.containerActionSheet}>
                    {user.user.id === props.route.params.creatorUser &&
                    <TouchableOpacity style={styles.containerSetting} onPress={() => {
                        SheetManager.hide('actionSheet_channel').then(res => {
                            props.navigation.navigate("NewChannel", {
                                idChannel: channel.id,
                                nameChannel: channel.name,
                                description: channel.description,
                                categoryChannel: channel,
                                maxUsers: channel.max_users.toString(),
                                price: channel.price.toString(),
                                chatActive: channel.has_chat,
                                isPrivateChannel: channel.is_private,
                                productName: channel.growpsy_product_name
                            });
                        });
                    }}>
                        <Ionicons style={styles.iconSettings} name={'pencil-outline'} size={20}/>
                        <Text>Editar grupo</Text>
                    </TouchableOpacity>
                    }

                    {suscribe &&
                    <TouchableOpacity style={styles.cancelSuscription} onPress={() => alertDisjointChannel()}>
                        <Text style={styles.textCancelSubscription}>Abandonar grupo</Text>
                    </TouchableOpacity>
                    }
                </View>
            </ActionSheet>
            <Toast message={'Te has suscrito al canal correctamente.'} visible={toastSuscribe}/>
            <Toast message={'Te has dado de baja del canal correctamente.'} visible={toastUnsuscribe}/>
            <Toast message={'No se ha podido enviar la solicitud correctamente, por favor intentalos más tarde.'} visible={toastErrorRequest}/>
            <Toast message={toastMessage} visible={toastErrorJoin}/>*/}
        </SafeAreaView>
    )
}

export default ChannelOption;

const styles = StyleSheet.create({
    anchorButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 10
    },
    safeArea: {
        flex: 1,
        backgroundColor: Colors.default.COLORS.background_light,
        width: '100%',
        paddingTop: 50,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginBottom: 30
    },
    containerContent: {
        width: '90%',
    },
    title: {
        marginTop: 20,
        fontSize: 16,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: Colors.default.COLORS.text_dark
    },
    text: {
        marginTop: 10,
        fontSize: 14,
    },
    containerShowMore: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 10,
        marginTop: 10
    },
    textShowMore: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        marginRight: 7,
        color: Colors.default.COLORS.primary
    },
    containerOptions: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: 20
    },
    containerOptionsNoMessages: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        marginTop: 20
    },
    containerActionSheet: {
        width: '100%',
        padding: 20,
        paddingBottom: 0
    },
    containerSetting: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#E9ECEF',
        paddingBottom: 20
    },
    iconSettings: {
        marginRight: 10
    },
    cancelSuscription: {
        width: '100%',
        padding: 20
    },
    textCancelSubscription: {
        color: Colors.default.COLORS.primary
    },
    dropdown: {
        position: 'absolute',
        zIndex: 9,
        right: '3%',
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: Colors.default.COLORS.background_light,
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
    },
    optionMenu: {
        color: Colors.default.COLORS.primary,
        marginTop: 15,
        marginBottom: 15,
        cursor: "pointer"
    }
})
