import create from 'zustand';

const typeHeaderStore = create((set, get) => ({
    /*Se inicializa el valo del store*/
    typeHeader: 'user',
    /*Se inicializa el valo del store*/
    openMenu: false,
    /*Se actualiza el store*/
    setTypeHeader: typeHeader => set(state => ({typeHeader: typeHeader})),
    /*Se actualiza el store*/
    setOpenMenu: openMenu => set(state => ({openMenu: openMenu})),
}));

export default typeHeaderStore;
