import React, {useState, useEffect} from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity } from "react-native";
import * as Colors from "../styles/Colors";
import moment from 'moment';
import {ReactComponent as Start} from '../assets/startOutline.svg';
import {ReactComponent as Pause} from '../assets/pauseOutline.svg';
import ReactAudioPlayer from 'react-audio-player';

let intervalo = null;

const AudioMessage = props => {
    const date = new Date();

    return(
        <>
            <View style={props.isSender ? styles.containerTimeSender : styles.containerTimeNoSender}>
                {String(moment(date).format('DD')) === String(moment(props.time).format('DD')) ?
                    <Text style={styles.time}>{moment(props.time).format('LT')}</Text>
                    :
                    <Text style={styles.time}>{moment(props.time).format('DD/MM/YY - LT')}</Text>
                }
                {/*{props.isRead ?
                    <IsReadTrue/>
                    :
                    <IsReadFalse/>
                }*/}
            </View>
            <TouchableOpacity style={[styles.container, props.isSender ? styles.containerSender : styles.containerNoSender]} onPress={props.press}>
                <View style={styles.containerImageNameUser}>
                    <Image style={styles.image} resizeMode={'cover'} source={{uri: `${props.userImage}`}}/>
                    <Text style={[styles.name, props.color !== null && {color: props.color}]}>{props.name}</Text>
                </View>
                <View style={styles.containerAudio}>
                    <ReactAudioPlayer
                        src={props.audio}
                        controls
                    />
                </View>
            </TouchableOpacity>
        </>
    )
}

export default React.memo(AudioMessage, () => {
    return true;
})

const styles = StyleSheet.create({
    container: {
        marginTop: 10,
        padding: 10,
        borderRadius: 6
    },
    containerSender: {
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_sender
    },
    containerNoSender: {
        marginRight: 'auto',
        backgroundColor: Colors.default.COLORS.background_light
    },
    containerImageNameUser: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    image: {
        width: 25,
        height: 25,
        borderRadius: 100,
        marginTop: -1,
        marginRight: 5,
        marginBottom: 5
    },
    name: {
        fontSize: 10,
        marginBottom: 5
    },
    containerAudio: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    iconPlayPause: {
        marginRight: 10
    },
    containerTimeSender: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        marginTop: 3
    },
    containerTimeNoSender: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 'auto',
        marginTop: 3
    },
    time: {
        marginRight: 3,
        fontSize: 10,
        color: Colors.default.COLORS.text_darkLight
    }
});
