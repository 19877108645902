import React from 'react';
import {StyleSheet, View, Image, Text, TouchableOpacity} from 'react-native';
import {ReactComponent as Calendar} from '../assets/CalendarMeet.svg';
import {ReactComponent as Hour} from '../assets/hourMeet.svg';
import moment from 'moment';
import 'moment/locale/es';

export const Meet = props => {
    return(
        <TouchableOpacity style={[styles.container, props.style]} activeOpacity={0.7} onPress={props.press}>
            <Image style={styles.image} source={{uri: `https://admin.growpsy.com/${props.image}`}}/>
            <View style={styles.containerText}>
                <Text style={styles.title}>{props.creator}</Text>
                <View style={styles.containerDate}>
                    <Calendar/>
                    <Text style={styles.textMargin}>{props.date}</Text>
                </View>


            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        backgroundColor: '#FAF9F9',
        borderRadius: 6,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    image: {
        width: 50,
        height: 50,
        borderRadius: 100,
        marginRight: 20
    },
    containerText: {
        marginLeft: 10
    },
    title: {
        marginBottom: 5,
        fontSize: 16,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textAlign: 'left'
    },
    containerDate: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    hour: {
        marginTop: 3
    },
    textMargin: {
        marginLeft: 5,
        fontFamily: 'Montserrat',
        fontWeight: 500
    }
})
