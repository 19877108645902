import React from 'react';
import {StyleSheet, View, TextInput} from 'react-native';
import {ReactComponent as Search} from '../assets/search.svg';

export const Searcher = props => {

    return (
        <View style={styles.container}>
            <Search />
            <TextInput
                style={styles.input}
                placeholder={props.placeholder}
                value={props.search}
                onChangeText={async (value) => await props.getValue(value)}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
        height: 42,
        marginVertical: 20,
        paddingHorizontal: 10,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#D8DBDF'
    },
    input: {
        width: '90%',
        paddingLeft: 5,
        fontFamily: 'Montserrat',
        fontWeight: 500
    }
})
