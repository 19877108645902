import { Routes, Route, Navigate } from 'react-router-dom';

import LoginScreen from "../views/auth/LoginScreen";
import RegisterScreen from "../views/auth/RegisterScreen";
import FormRegisterScreen from "../views/auth/FormRegisterScreen";
import RememberPasswordScreen from "../views/auth/RememberPasswordScreen";
import VideochatScreen from '../views/VideochatScreen';


function AuthRouter() {
    console.log("AUTH ROUTER")
    /*Inicializamos las rutas del auth (Login, registro...)*/
    return (
        <Routes>
            <Route path='/' element={<LoginScreen />} />
            <Route path='/register' element={<RegisterScreen />} />
            <Route path='/formregister' element={<FormRegisterScreen />} />
            <Route path='/remember' element={<RememberPasswordScreen />} />
            <Route path='/videochat' element={<VideochatScreen />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    )
}

export default AuthRouter;
