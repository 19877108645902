import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
    StyleSheet,
    SafeAreaView,
    ScrollView,
    View,
    Text,
    TouchableOpacity,
    Image,
    Alert,
    Dimensions,
    Platform,
} from "react-native";
import * as Colors from "../styles/Colors";
import { AccordionList, CollapseHeader, CollapseBody } from 'accordion-collapse-react-native';
import {Button} from '../components/button.component';
import {Loader} from '../components/loader.component';
import {Toast} from '../components/toast.component';
import {getCourse, joinCourse, downloadCertificate, saveBuy, buyStripe} from '../services/data.service';
import courseStore from '../store/courseStore';
import userStore from '../store/userStore';
import {useParams} from 'react-router-dom';
import {ReactComponent as ChevronUp} from '../assets/chevronUp.svg';
import {ReactComponent as ChevronDown} from '../assets/chevronDown.svg';
import {ReactComponent as Checkmark} from '../assets/checkmark.svg';
import {Link, useNavigate} from 'react-router-dom';
import {getUser} from "../services/user.service";

const {width} = Dimensions.get('window');

const CourseScreen = ({navigation, route}) => {
    const [showMore, setShowMore] = useState(false);
    const [lengthMore, setLengthMore] = useState(false);
    const [course, setCourse] = useState(null);
    const [toastStartCourseCorrect, setToastStartCourseCorrect] = useState(false);
    const [toastBuy, setToastBuy] = useState(false);
    const [isBuy, setIsBuy] = useState(false);
    const setCourseId = courseStore(state => state.setWorkingCourse);
    const user = userStore(state => state.user);
    const setUser = userStore(state => state.setUser);

    const {id} = useParams();
    const navigate = useNavigate();

    const itemSkus = [
        'elearning_10'
    ];


    const onTextLayout = useCallback(e => {
        setLengthMore(e.nativeEvent.lines.length >= 3);
    },[]);

    useEffect(() => {
        setToastStartCourseCorrect(false);
        setToastBuy(false);
    }, [toastStartCourseCorrect, toastBuy])

    /*LLama a las 2 funciones al entrara a la vista*/
    useEffect(() => {
        obtainUser();
        obtainCourse();
    }, []);

    /*Recoge el usuario*/
    const obtainUser = () => {
        getUser().then(res => {
            setUser(res.data);
        }).catch(err => {
            console.log('ERROR al recoger el usuario', err);
        });
    }

    /*Recoge un corso por id*/
    const obtainCourse = async () => {
        //await setCourseId(route.params.id);
        await getCourse(id).then(res => {
            console.log('CURSO RECOGIDO', res);
            setCourse(res);
        }).catch(err => {
            console.log('ERROR al recoger el curso', err);
        });
    }

    /*Muestra los titulos de los desplegable*/
    const accordionHead = (item, i) => {
        return (
            <CollapseHeader>
                <View style={[styles.collapseHeader, i > 0 && styles.collapseHeaderBorder]} >
                    <Text style={styles.collapseTitle}>{item.name}</Text>
                    <ChevronDown width={20} height={20}/>
                </View>
            </CollapseHeader>
        )
    }

    /*Al desplegar la cabecera de los desplegables aparece el cuerpo, que lo forma esta función*/
    const accordionBody = (item, block) => {
        return (
            <CollapseBody style={{marginLeft: 80}}>
                {
                    item.map((lesson, index) => (
                        <TouchableOpacity activeOpacity={.7} style={styles.lesson} onPress={() => navigate(`/lesson/${lesson.id}`, {state: {block: block}})}>
                            <Image style={styles.lessonImage} source={{uri: `https://admin.growpsy.com${lesson.image.url}`}}/>
                            <View>
                                <Text style={styles.lessonTitle}>{lesson.name}</Text>
                                <Text style={styles.lessonTime}>{lesson.contents.length} {lesson.contents.length > 1 || lesson.contents.length === 0 ? 'contenidos' : 'contenido'}</Text>
                            </View>
                            {lesson.completed === true && <Checkmark style={{marginLeft: 'auto'}} width={40}/>}
                        </TouchableOpacity>
                    ))
                }
            </CollapseBody>
        )
    }

    // Muestra el texto del boton de compra segun el tipo de compra que tenga el curso
    const textButtonBuy = () => {
        if (course.hasOwnProperty('show_buy_page') && course.show_buy_page === true) {
            return 'Compra tu suscripción';
        } else if (course.hasOwnProperty('show_buy_course') && course.show_buy_course === true) {
            return `Comprar curso por ${course.price}€`;
        } else {
            return 'Empezar curso';
        }
    }

    /*Le da la funcion al boton segun el tipo de copra que tenga el curso*/
    const actionButtonBuy = () => {
        if (course.hasOwnProperty('show_buy_page') && course.show_buy_page === true) {
            /*Navega a la pagina de suscripcion ya que es necessaria para el curso*/
            navigate('/subscription');
        } else if (course.hasOwnProperty('show_buy_course') && course.show_buy_course === true) {
            /*Compras el curso*/
            buyCourse();
        } else {
            /*Te une el curso*/
            joinCourse(user.user.id, id).then(res => {
                console.log('Te has unido al curso con exito', res);
                setToastStartCourseCorrect(true);
                course.purchased = true;
                setTimeout(() => {
                    /*Navegas a la pagina de mis cursos*/
                    navigation.navigate('My courses');
                }, 3000);
            }).catch(err => {
                console.log('ERROR al unirte al curso', err);
            })
        }
    }

    /*Descraga el dimploma que te dan al finalizar el curso*/
    const downloadDiploma = () => {
        window.open(`https://admin.growpsy.com/generate_certificate.pdf?course_id=${id}&user_id=${user.user.id}`);
    }

    /*Compra el curso*/
    const buyCourse = () => {
        /*Hace la peticion con el tipo de compra que vas a hacer y te devuelve la ruta donde debs ir a comprarlo*/
        buyStripe('elearning_10', id).then(res => {
            /*Navegas a la ruta en la que vas a comprar el curso*/
            window.location.href = res.data.payment_link;
            console.log('Suscripcion realizada con exito', res);
        }).catch(err => {
            console.log('Error al realizar la suscripcion', err);
        })
    }

    return(
        <>
            {course === null ?
                <Loader style={{flex: 1, height: 500}}/>
                :
                <SafeAreaView style={styles.safeArea}>
                    <AccordionList
                        ListHeaderComponent={
                            <View style={styles.container}>
                                <Image style={{ width: "100%", height: 170 }}
                                       source={course?.course_image ? { uri: `https://admin.growpsy.com/${course?.course_image}` } : require("../assets/growpsyApaisado.png")} />

                                <View style={styles.containerContent}>
                                    <View style={styles.courseCategoryContainer}>
                                        <Text style={styles.courseCategory}>{course?.course_category_name}</Text>
                                    </View>
                                    <Text style={styles.title}>{course?.name}</Text>
                                    <View>
                                        <Text onTextLayout={onTextLayout} numberOfLines={showMore ? undefined : 3}
                                              style={{ color: "#5C5F62", lineHeight: 21, fontFamily: 'Montserrat', fontWeight: 500}}>
                                            {course?.description}
                                        </Text>
                                    </View>

                                    {lengthMore &&
                                    <TouchableOpacity style={styles.containerShowMore} activeOpacity={.7}
                                                      onPress={() => setShowMore(!showMore)}>
                                        {showMore ?
                                            <Text style={styles.textShowMore}>Ver menos</Text>
                                            :
                                            <Text style={styles.textShowMore}>Ver más</Text>
                                        }
                                        {showMore ?
                                            <ChevronUp width={20} height={20}/>
                                            :
                                            <ChevronDown width={20} height={20}/>
                                        }
                                    </TouchableOpacity>
                                    }

                                    {course.blocks.length > 0 && <Text style={[styles.title, {marginTop: !lengthMore ? 30 : 0}]}>Temarios</Text>}
                                </View>
                            </View>
                        }
                        list={course.blocks}
                        header={(item, i) => accordionHead(item, i)}
                        body={(item) => accordionBody(item.lessons)}
                        keyExtractor={item => `${item.id}`}
                    />

                    {!course.purchased &&
                    <Button
                        style={styles.buttonBuy}
                        text={textButtonBuy()}
                        primary
                        press={() => actionButtonBuy()}
                    />
                    }

                    {(course.purchased && course.completed >= 100) &&
                    <Button
                        style={styles.buttonBuy}
                        text={"Descargar diploma"}
                        primary
                        press={() => downloadDiploma()}
                    />}
                    <Toast message={'Has empezado el curso correctamente.'} visible={toastStartCourseCorrect}/>
                    <Toast message={'Curso comprado correctamente.'} visible={toastBuy}/>
                </SafeAreaView>
            }
        </>
    )
}

export default CourseScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    containerContent: {
        width: '90%',
    },
    title: {
        fontSize: 18,
        marginTop: 12,
        marginBottom: 10,
        color: Colors.default.COLORS.text_dark,
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    },
    containerShowMore: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 10,
        marginTop: 10
    },
    textShowMore: {
        marginRight: 7,
        color: Colors.default.COLORS.primary,
        fontFamily: 'Montserrat'
    },
    collapse: {
        borderBottomWidth: 1,
        borderBottomColor: '#E1E1E1'
    },
    collapseHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        paddingVertical: 18,
        width: '90%'
    },
    collapseHeaderBorder: {
        borderTopColor: '#E9ECEF',
        borderTopWidth: 1
    },
    collapseTitle: {
        fontSize: 16,
        fontFamily: 'Montserrat',

    },
    courseCategoryContainer: {
        backgroundColor: Colors.default.COLORS.primary,
        alignSelf: 'flex-start',
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 5,
        marginTop: 15
    },
    courseCategory: {
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    lesson: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
        paddingBottom: 15,
        paddingRight: 20,
        fontFamily: 'Montserrat'
    },
    lessonImage: {
        width: 120,
        height: 65,
        borderRadius: 6,
        marginRight: 10
    },
    lessonTitle: {
        marginBottom: 8,
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    },
    lessonTime: {
        color: Colors.default.COLORS.text_darkLight,
        fontSize: 12,
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    buttonBuy: {
        bottom: 50,
        width: '90%',
        marginRight: 'auto',
        marginBottom: 30,
        marginLeft: 'auto',
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
})
