import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD2TCyH-NxluRWGXIMm3quSYVq5697b45E",
  authDomain: "growpsy-cbac4.firebaseapp.com",
  projectId: "growpsy-cbac4",
  storageBucket: "growpsy-cbac4.firebasestorage.app",
  messagingSenderId: "56883279149",
  appId: "1:56883279149:web:eebfe55145472c92f48fa4",
  measurementId: "G-YWJM14SGV8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export {app, analytics, db};