import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {
    StyleSheet,
    SafeAreaView,
    ScrollView,
    TouchableOpacity,
    View,
    Text,
    Switch,
    Image,
    Alert,
    Modal,
    Keyboard,
    Platform,
    BackHandler, Dimensions
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Colors from '../styles/Colors';
import {getUser,
    logout,
    updateUser,
    deleteAccount} from '../services/user.service';
import {Input} from '../components/input.component';
import {Button} from '../components/button.component';
import {Loader} from '../components/loader.component';
import loginStore from '../store/loginStore';
import userStore from '../store/userStore';
import {ReactComponent as CameraIcon} from '../assets/camera.svg';
import {ReactComponent as Back} from "../assets/back.svg";
import {translateTypeUser} from '../utils/translateTypeUser';
import {useNavigate} from 'react-router-dom';

const {width} = Dimensions.get('window');

const ProfileScreen = props => {
    let userStorage = {};

    const [userScreen, setUserScreen] = useState(null);
    const [chooseCamera, setChooseCamera] = useState(false);
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [lastname2, setLastname2] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [notifications, setNotifications] = useState(false);
    const [chatPermisson, setChatPermisson] = useState(false);
    const [keyboardOpen, setKeyboardOpen] = useState(false);

    const [errorMessageName, setErrorMessageName] = useState(null);
    const [errorMessageLastname, setErrorMessageLastname] = useState(null);
    const [errorMessageLastname2, setErrorMessageLastname2] = useState(null);
    const [errorMessageEmail, setErrorMessageEmail] = useState(null);
    const [errorMessagePassword, setErrorMessagePassword] = useState(null);
    const [errorMessageRepeatPassword, setErrorMessageRepeatPassword] = useState(null);
    const setLogin = loginStore(state => state.setLogin);
    const getUserStore = userStore(state => state.user);
    const setUserStore = userStore(state => state.setUser);
    const inputHidden = useRef(null);
    const navigate = useNavigate();

    /*Recoge el usuario al entrara a la vista*/
    useEffect(() => {
        getUser().then(res => {
            /*Edita los estado con los datos*/
            setImage('https://admin.growpsy.com'+res.data.user.profile_image);
            setUserScreen(res.data.user);
            setName(res.data.user.name);
            setLastname(res.data.user.lastname);
            setLastname2(res.data.user.lastname2);
            setEmail(res.data.user.email);
            setNotifications(res.data.user.notifications_permission);
            setChatPermisson(res.data.user.chat_permission);
            console.log('Usuario cogido con exito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger el usuario', err);
        })
    }, []);

    const getUserStorage = () => {
        AsyncStorage.getItem('user').then(user => {
            userStorage = JSON.parse(user);
        })
    }

    /*Actualiza el usuario*/
    const buttonUpdateUser = async () => {
        /*Comprueba que todos los campos estan correctos*/
        if (validateName() === false && validateLastname() === false && validateLastname2() === false && validateEmail() === false && validatePassword() === false) {
            updateUser(userScreen).then(res => {
                userStorage.user = res.data;
                AsyncStorage.setItem('user', userStorage);
                AsyncStorage.setItem('token', res.headers.authorization);
                navigate('/home');
            }).catch(err => {
                console.log('ERROR al actualizar el usuario', err);
            });
        }
    }

    /*Valida el nombre dle usuario*/
    const validateName = () => {
        if (name !== userScreen.name || name === '' || name === null) {
            if (name === '') {
                setErrorMessageName('Campo obligatorio.');
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /*Valida el apellido del usuario*/
    const validateLastname = () => {
        if (lastname !== userScreen.lastname || lastname === '' || lastname === null) {
            if (lastname === '') {
                setErrorMessageLastname('Campo obligatorio.');
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /*Valida el segundo apellido del usuario*/
    const validateLastname2 = () => {
        if (lastname2 !== userScreen.lastname2 || lastname2 === '' || lastname2 === null) {
            if (lastname === '') {
                console.log('entra aqui')
                setErrorMessageLastname('Campo obligatorio.');
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /*Valida el correo del usuario*/
    const validateEmail = () => {
        const regexEmail =  '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';
        const reg = new RegExp(regexEmail);
        if (email !== userScreen.email || email === null || !reg.test(email)) {
            if (email === '') {
                setErrorMessageEmail('Campo obligatorio.');
                return true;
            } else if (!reg.test(email)) {
                setErrorMessageEmail('Email invalido.');
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    }

    /*Valida la contraseña de usuario*/
    const validatePassword = () => {
        if (password !== '' || passwordRepeat !== '') {
            if (password !== passwordRepeat) {
                setErrorMessagePassword('Las contraseñas deben ser iguales.');
                setErrorMessageRepeatPassword('Las contraseñas deben ser iguales.');
                return true;
            } else if (password.length < 8) {
                setErrorMessagePassword('La contraseña debe tener más de 8 caracteres.');
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    /*Muestra un alert de confirmacion de si quieres cerrar la sesion*/
    const alertLogout = () => {
        if (window.confirm('¿Desea cerrar sesión?')) {
            logoutUser();
        } else {
            console.log('Cancelar funcion no disponible')
        }
    }

    /*Cierra la sesion*/
    const logoutUser = () => {
        logout().then(res => {
            AsyncStorage.clear();
            navigate('/');
            setLogin(false);
        }).catch(err => {
            console.log('ERROR al realizar el logout', err);
        })
    }

    /*Muestra un alert de confirmacion para si quieres elimianr la cuenta*/
    const alertDeleteAccount = () => {
        if (window.confirm('¿Desea eliminar la cuenta?')) {
            deleteAccountUser();
        } else {
            console.log('Cancelar funcion no disponible')
        }
    }

    /*Elimina la cuenta*/
    const deleteAccountUser = async () => {
        await deleteAccount().then(res => {
            AsyncStorage.clear();
            setLogin(false);
            navigate('/');
        }).catch(err => {
            console.log('ERROR al eliminar la cuenta', err);
        })
    }

    const openImagePicker = () => {
        inputHidden.current.click();
    }

    const uploadImage = (event) => {
        console.log('IMAGE', event.target.value);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            userScreen.image = reader.result;
            setImage(reader.result);
            console.log(reader.result);
        }
    }

    return(
        <>

            {userScreen === null ?
                <Loader style={{ flex: 1 }} />
                :
                <SafeAreaView style={styles.safeArea}>
                    <input ref={inputHidden} style={{display: 'none'}} type={'file'} onChange={(e) => uploadImage(e)}/>

                    <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                        <View style={[styles.container, (Platform.OS === 'ios' && keyboardOpen === true) && {paddingBottom: 320}]}>
                            {image === null ?
                                <TouchableOpacity style={styles.camera} activeOpacity={0.7} onPress={() => openImagePicker()}>
                                    <CameraIcon />
                                </TouchableOpacity>
                                :
                                <TouchableOpacity activeOpacity={0.7} onPress={() => openImagePicker()}>
                                    <Image style={styles.imageProfile} source={{ uri: image }} />
                                </TouchableOpacity>
                            }

                            <Text style={styles.typeUser}>{translateTypeUser(userScreen.user_type)}</Text>
                            <Text style={{marginTop: 10}}>{email}</Text>

                            <View style={styles.containerTitle}>
                                <Text style={styles.title}>Datos personales</Text>
                            </View>
                            <Input style={styles.input} title={'Nombre'} value={name} error={errorMessageName} getValue={(value) => {
                                setErrorMessageName(null);
                                setName(userScreen.name = value);
                            }} />
                            <Input style={styles.input} title={'Apellido'} value={lastname} error={errorMessageLastname}
                                   getValue={(value) => {
                                       setErrorMessageLastname(null);
                                       setLastname(userScreen.lastname = value);
                                   }} />
                            <Input style={styles.input} title={'Segundo apellido'} value={lastname2} error={errorMessageLastname2}
                                   getValue={(value) => {
                                       setErrorMessageLastname2(null);
                                       setLastname2(userScreen.lastname2 = value);
                                   }} />

                               <View style={styles.containerTitle}>
                                   <Text style={styles.title}>Cambiar contraseña</Text>
                               </View>
                                <Input
                                    style={styles.input}
                                    title={'Nueva contraseña'}
                                    placeholder={'Minimo 8 caracteres'}
                                    value={password}
                                    error={errorMessagePassword}
                                    getValue={(value) => {
                                        setErrorMessagePassword(null);
                                        setPassword(userScreen.password = value);
                                    }}
                                    secure
                                />
                                <Input
                                    style={styles.input}
                                    title={'Repetir contraseña'}
                                    placeholder={'Minimo 8 caracteres'}
                                    value={passwordRepeat}
                                    error={errorMessageRepeatPassword}
                                    getValue={(value) => {
                                        if (value === password) {
                                            setErrorMessagePassword(null);
                                            setErrorMessageRepeatPassword(null)
                                        }
                                        setPasswordRepeat(userScreen.password_confirmation = value);
                                    }}
                                    secure
                                />


                            <View style={styles.containerTitle}>
                                <Text style={styles.title}>Notificaciones</Text>
                                <View style={styles.containerOnOff}>
                                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Permitir notificaciones</Text>
                                    <Switch
                                        trackColor={{ true: Colors.default.COLORS.primary, false: 'grey' }}
                                        thumbColor={'#e1e1e1'}
                                        value={notifications}
                                        onValueChange={(value) => setNotifications(userScreen.notifications_permission = value)}
                                    />
                                </View>

                                <Text style={styles.title}>Chats</Text>
                                <View style={styles.containerOnOff}>
                                    <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Permitir conversaciones privadas</Text>
                                    <Switch
                                        trackColor={{ true: Colors.default.COLORS.primary, false: 'grey' }}
                                        thumbColor={'#e1e1e1'}
                                        value={chatPermisson}
                                        onValueChange={(value) => setChatPermisson(userScreen.chat_permission = value)}
                                    />
                                </View>

                                <Button style={styles.button} text={'Guardar configuración'} primary press={() => buttonUpdateUser()}/>
                                <Button style={styles.button} text={'Mis suscripciones'} primary press={() => navigate('/subscription')}/>
                                <Button style={styles.button} text={'Cerrar sesión'} press={() => alertLogout()} />
                                <TouchableOpacity style={styles.removeAccount} onPress={() => alertDeleteAccount()}>
                                    <Text style={{color: Colors.default.COLORS.primary, fontFamily: 'Montserrat', fontWeight: 500}}>Eliminar cuenta</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </ScrollView>
                </SafeAreaView>
            }
        </>
    )
}

export default ProfileScreen;

const styles = StyleSheet.create({
    anchorButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingHorizontal: 10,
    },
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: Colors.default.COLORS.background_light,
        paddingTop: 50,
        paddingBottom: 50
    },
    safeAreaBlack: {
        flex: 1,
        backgroundColor: '#000000'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 30,
        paddingBottom: 50
    },
    camera: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 100,
        height: 100,
        borderRadius: 100,
        backgroundColor: '#BEC0FF'
    },
    imageProfile: {
        width: 100,
        height: 100,
        borderRadius: 100,
    },
    typeUser: {
        marginTop: 10,
        fontWeight: 'bold',
        fontSize: 20,
        color: Colors.default.COLORS.primary
    },
    containerTitle: {
        width: '90%',
        marginTop: 20
    },
    title: {
        color: '#2D2E2F',
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 20,
        textAlign: 'left',
        fontFamily: 'Montserrat',
    },
    input: {
        marginTop: 20
    },
    containerPassword: {
        justifyContent: 'center',
        width: '100%',
        marginTop: 40,
        borderTopWidth: 1,
        borderTopColor: '#E1E1E1'
    },
    containerOnOff: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10
    },
    button: {
        marginTop: 40
    },
    removeAccount: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 30
    },
    modal: {
        borderRadius: 6
    }
})
