import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    SafeAreaView,
    ScrollView,
    View,
    Text,
    Switch,
    Modal,
    TextInput,
    Keyboard,
    Platform,
    Dimensions
} from "react-native";
import * as Colors from '../styles/Colors';
import {Input} from '../components/input.component';
import {TextArea} from '../components/textArea.component';
import {Button} from '../components/button.component';
import {Toast} from '../components/toast.component';
import {Loader} from '../components/loader.component';
//import {PickerComponent} from '../components/picker.component';
import {createChannel,
    updateChannel,
    getChannels} from '../services/data.service';
import {joinToChannel} from '../services/user.service';
import {Picker} from '../components/picker.component';
import {getPricesChannel, getChannel} from '../services/data.service';
import {useNavigate, useParams} from 'react-router-dom';

const {width} = Dimensions.get('window');

const NewChannelScreen = props => {
    const [nameChannel, setNameChannel] = useState('');
    const [description, setDescription] = useState('');
    const [typeCategory, setTypeCategory] = useState(null)
    const [people, setPeople] = useState(null);
    const [priceValues, setPriceValues] = useState(null);
    const [price, setPrice] = useState(null);
    const [chatActive, setChatActive] = useState(false);
    const [privateChannel, setPrivateChannel] = useState(false);
    const [channels, setChannels] = useState([]);
    const [toastUpdateCorrect, setToastUpdateCorrect] = useState(false);
    const [loader, setLoader] = useState(false);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [freeSessions, setFreeSessions] = useState(false);
    const regex = /(<([^>]+)>)/ig;

    const [errorMessageName, setErrorMessageName] = useState(null);
    const [errorMessageDescription, setErrorMessageDescription] = useState(null);
    const [errorMessageTypeCategory, setErrorMessageTypeCategory] = useState(null);
    const [errorMessagePeople, setErrorMessagePeople] = useState(null);
    const [errorMessagePrice, setErrorMessagePrice] = useState(null);

    const navigate = useNavigate();
    const {id} = useParams();

    const priceValue = [
        {label: 0, key: 0},
        {label: 5, key: 5},
        {label: 10, key: 10},
        {label: 15, key: 15},
        {label: 20, key: 20},
    ];

    Keyboard.addListener('keyboardWillShow', () => {
        setKeyboardOpen(true);
    });

    Keyboard.addListener('keyboardWillHide', () => {
        setKeyboardOpen(false);
    });

    useEffect(() => {
        console.log('PARAMS', id);
        setToastUpdateCorrect(false);
    }, [toastUpdateCorrect]);

    /*Al entrar a la vista*/
    useEffect(() => {
        /*Comprueba que existe el id para confirmar que es una edicion del grupo*/
        if (id !== undefined) {
            /*Recoge el grupo y edita los estado para poder editarlos*/
            getChannel(id).then(res => {
                console.log('Canal recogido con exito', res.data);
                setNameChannel(res.data.name);
                setDescription(res.data.description.replace(regex, ''));
                setTypeCategory({label: res.data.channel_category.name, key: res.data.channel_category.id});
                setPeople(res.data.max_users);
                setPrice({label: res.data.growpsy_product_name, key: res.data.growpsy_product_id});
                setChatActive(res.data.has_chat);
                setPrivateChannel(res.data.is_private)
                setFreeSessions(res.data.free_sessions)
            }).catch(err => {
                console.log('ERROR al recoger el canal', err);
            })
        }
    }, []);

    /*Al entrar a la vista recoge las categorias de canales*/
    useEffect(() => {
        getChannels().then(res => {
            /*Los recorre y los guarda de manera que el select pueda trabajar con ellos*/
            res.data.map(channel => {
                setChannels(channels => [...channels, {label: channel.name, key: channel.id}])
            })
        }).catch(err => {
            console.log('ERROR al recoger las categorias', err);
        })
    }, []);

    /*Al entrar a la vista recoge los precios que puede tener el grupo*/
    useEffect(() => {
        let prices = [];
        getPricesChannel().then(res => {
            /*Recorre los precios para guardarlos de manera que el select pueda trabajar con ellos*/
            res.data.map(price => {
                prices.push({label: price.name, key: price.id});
            })
            setPriceValues(prices);
            console.log('Precios recogidos con exito', res.data);
        }).catch(err => {
            console.log('ERROR al recoger los precios del canal', err);
        })
    }, []);

    /*Crea o actualiza un grupo*/
    const newChannel = async () => {
        /*Comprueba que todos los campos cumplan con las condiciones para crear o editar el grupo*/
        if (validateName() === false && validateDescription() === false && validateTypeCategory() === false && validatePeople() === false && validatePrice() === false) {
            /*En el caso de no exitir id, se crea un grupo*/
            if (id === undefined) {
                setLoader(true);
                /*Crea el canal*/
                await createChannel(nameChannel, description, people, price, chatActive, typeCategory, privateChannel, freeSessions).then(async channelCreate => {
                    console.log('createChannel', channelCreate.data)
                    /*Une al psicologo en el grupo que acaba de crear*/
                    await joinToChannel(channelCreate.data.id).then(res => {
                        /*Navega a la vista del grupo que acaba de crear*/
                        navigate(`/channel/${channelCreate.data.id}`);
                        setLoader(false);
                    }).catch(err => {
                        console.log('ERROR al unir al psicologo en el canal', err)
                    })
                }).catch(err => {
                    /*Si se produce un error al crear el canal*/
                    if (err.response.status === 400) {
                        /*Muestra un alert informativo de que ha habido un error*/
                        if (window.confirm('Se a producido un error, intenta volver a crear el grupo más tarde.')) {
                            /*Navega a la pagina de mis grupos*/
                            navigate('/myChannels');
                        }
                    }
                    console.log('ERROR al crear el nuevo canal', err);
                })
            } else {
                /*Actualiz ael grupo*/
                updateChannel(id, nameChannel, description, people, price, chatActive, typeCategory, privateChannel, freeSessions).then(res => {
                    /*Navega a la pagina del grupo que acabas de actualizar*/
                    navigate(`/channel/${id}`);
                    setToastUpdateCorrect(true);
                    console.log('Canal actualizado con exito', res.data);
                }).catch(err => {
                    console.log('ERROR al actualizar un canal', err);
                })
            }
        }
    }

    /*Valida el nombre dle grupo*/
    const validateName = () => {
        if (nameChannel === '') {
            setErrorMessageName('Campo obligatorio.');
            return true;
        } else {
            return false;
        }
    }

    /*Valida la descripcion del grupo*/
    const validateDescription = () => {
        if (description === '') {
            setErrorMessageDescription('Campo obligatorio.');
            return true;
        } else {
            return false;
        }
    }

    /*Valida la categoria dle grupo*/
    const validateTypeCategory = () => {
        if (typeCategory === null) {
            setErrorMessageTypeCategory('Selecciona una categoría.')
            return true;
        } else {
            return false;
        }
    }

    /*Valida la cantidad de gente que entra en el grupo*/
    const validatePeople = () => {
        if (people === null) {
            setErrorMessagePeople('Campo obligatorio.');
            return true;
        } else if (people === '0' || people === '1' || people === '2') {
            setErrorMessagePeople('El número de personas debe de ser 3 mínimo.');
        } else {
            return false;
        }
    }

    /*Valida el precio del grupo*/
    const validatePrice = () => {
        if (price === null) {
            setErrorMessagePrice('Selecciona un precio.')
            return true;
        } else {
            return false;
        }
    }

    return(
        loader ?
            <Loader style={{flex: 1}}/>
            :
            <SafeAreaView style={styles.safeArea}>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={[styles.container, (Platform.OS === 'ios' && keyboardOpen === true) && {paddingBottom: 200}]}>
                        <View style={styles.containerTitle}>
                            <Text style={styles.title}>Datos del grupo</Text>
                        </View>
                        <Input
                            title={'Nombre del grupo'}
                            value={nameChannel}
                            error={errorMessageName}
                            getValue={(value) => {
                                setErrorMessageName(null);
                                setNameChannel(value);
                            }}
                        />
                        <TextArea
                            title={'Descripción'}
                            value={description}
                            error={errorMessageDescription}
                            getValue={(value) => {
                                setErrorMessageDescription(null);
                                setDescription(value);
                            }}
                        />

                        <Picker
                            title={'Categoria'}
                            initValue={typeCategory === null ? 'Seleccione una categoria' : typeCategory.label}
                            values={channels}
                            value={typeCategory}
                            error={errorMessageTypeCategory}
                            getValue={value => {
                                setErrorMessageTypeCategory(null);
                                setTypeCategory(value);
                            }}
                        />

                        <Input
                            title={'Número máximo de personas'}
                            value={people}
                            keyboardType={'number-pad'}
                            error={errorMessagePeople}
                            getValue={(value) => {
                                setErrorMessagePeople(null);
                                setPeople(value);
                            }}
                        />

                        <Picker
                            title={'Precio'}
                            initValue={price === null ? 'Selecciona un precio' : price.label}
                            values={priceValues === null ? priceValue : priceValues}
                            value={price}
                            error={errorMessagePrice}
                            getValue={value => {
                                console.log('VALUE', value)
                                setErrorMessagePrice(null);
                                setPrice(value);
                            }}
                        />

                        <View style={styles.containerSwitch}>
                            <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Sesiones de video gratuitas</Text>
                            <Switch
                                trackColor={{ true: Colors.default.COLORS.primary, false: 'grey' }}
                                thumbColor={'#e1e1e1'}
                                value={freeSessions}
                                onValueChange={(value) => setFreeSessions(value)}
                            />
                        </View>

                        <View style={styles.containerSwitch}>
                            <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Chat activo</Text>
                            <Switch
                                trackColor={{ true: Colors.default.COLORS.primary, false: 'grey' }}
                                thumbColor={'#e1e1e1'}
                                value={chatActive}
                                onValueChange={(value) => setChatActive(value)}
                            />
                        </View>

                        <View style={styles.containerSwitch}>
                            <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>Canal privado</Text>
                            <Switch
                                trackColor={{ true: Colors.default.COLORS.primary, false: 'grey' }}
                                thumbColor={'#e1e1e1'}
                                value={privateChannel}
                                onValueChange={(value) => setPrivateChannel(value)}
                            />
                        </View>
                        <Button style={styles.button} text={id !== undefined ? 'Guardar' : 'Crear'} primary press={() => newChannel()}/>
                    </View>
                </ScrollView>
                <Toast message={'Canal actualizado con exito.'} visible={toastUpdateCorrect}/>
            </SafeAreaView>
    )
}

export default NewChannelScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginBottom: 30,
    },
    containerTitle: {
        width: '90%'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginVertical: 20
    },
    button: {
        width: '90%',
        marginTop: 20
    },
    containerSwitch: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginVertical: 20
    }
})
