import create from 'zustand';

/*Se utiliza para guardar los datos del usuario*/
const userStore = create((set, get) => ({
  /*Se inicializa el valo del store*/
  user: null,
  /*Se actualiza el store*/
  setUser: user => set(state => ({user: user})),
}));

export default userStore;

