import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Dimensions } from "react-native";
import { Link } from 'react-router-dom';
import * as Colors from '../styles/Colors';

import { ReactComponent as HomeFocus } from '../assets/tabBar/homeFocus.svg';
import { ReactComponent as ChannelsFocus } from '../assets/tabBar/channelsFocus.svg';
import { ReactComponent as ChatBubbles } from '../assets/tabBar/chatbubbles.svg';
import { ReactComponent as CoursesFocus } from '../assets/tabBar/coursesFocus.svg';
import AsyncStorage from "@react-native-async-storage/async-storage";

const { width } = Dimensions.get('window');

export const Tabs = () => {
    const [notifGroups, setNotifGroups] = useState(false);
    const [notifPrivate, setNotifPrivate] = useState(false);
    const [isShowingText, setIsShowingText] = useState(true);

    useEffect(() => {
        const toggle = setInterval(() => {
            setIsShowingText(!isShowingText);
        }, 700);

        return () => clearInterval(toggle);
    }, [isShowingText]);

    useEffect(() => {
        const interval = setInterval(() => {
            AsyncStorage.getItem('notifications').then(notifications => {
                const parsedNotifications = JSON.parse(notifications);
                if (parsedNotifications !== null) {
                    setNotifGroups(parsedNotifications.group);
                    setNotifPrivate(parsedNotifications.conversation);
                }
            });
        }, 100);
        return () => clearInterval(interval);
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.containerOptions}>
                <Link style={{ textDecoration: 'none' }} to='/home'>
                    <View style={styles.link}>
                        <HomeFocus />
                        <Text style={styles.text}>Inicio</Text>
                    </View>
                </Link>

                <Link style={{ textDecoration: 'none' }} to='/myChannels'>
                    <View style={styles.link}>
                        {notifGroups && isShowingText && <View style={styles.dotInfo}></View>}
                        <ChannelsFocus />
                        <Text style={styles.text}>Mis grupos</Text>
                    </View>
                </Link>

                <Link style={{ textDecoration: 'none' }} to='/chats'>
                    <View style={styles.link}>
                        {notifPrivate && isShowingText && <View style={styles.dotInfo}></View>}
                        <ChatBubbles width={20} />
                        <Text style={styles.text}>Chats</Text>
                    </View>
                </Link>

                <Link style={{ textDecoration: 'none' }} to='/events'>
                    <View style={styles.link}>
                        <HomeFocus />
                        <Text style={styles.text}>Eventos</Text>
                    </View>
                </Link>

                <Link style={{ textDecoration: 'none' }} to='/courses'>
                    <View style={styles.link}>
                        <CoursesFocus />
                        <Text style={styles.text}>Cursos</Text>
                    </View>
                </Link>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        paddingVertical: 15,
        paddingHorizontal: width <= 420 ? 20 : 50,
        backgroundColor: Colors.default.COLORS.background_light,
        shadowRadius: 2,
        shadowOffset: {
            width: 0,
            height: -2,
        },
        shadowColor: '#cccccc',
        elevation: 2,
    },
    link: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontFamily: 'Montserrat',
        fontWeight: 500
    },

    containerOptions: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: width <= 820 ? '100%' : 1200,
        height: '100%'
    },
    dotInfo: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 10,
        height: 10,
        marginLeft: 'auto',
        borderRadius: 100,
        backgroundColor: '#d38001',
    }
});
