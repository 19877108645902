import React from 'react';
import {StyleSheet, View, TouchableOpacity, Image, Text, Dimensions} from 'react-native';
import {Button} from './button.component';
import {ReactComponent as Close} from '../assets/close.svg';
import * as Colors from '../styles/Colors';

const {width} = Dimensions.get('window');

export const ModalSubscribe = props => {

    return(
        <View style={styles.containerAbsolute}>
            <TouchableOpacity style={styles.containerClose} activeOpacity={0.7} onPress={() => props.closeModal(false)}>
                <Close width={20}/>
            </TouchableOpacity>
            <View style={styles.container}>
                <Image style={styles.image} source={require('../assets/growpsyApaisado.png')}/>
                <Text style={styles.intro}>Suscríbete al canal</Text>
                <Text style={styles.title}>{props.channelTitle}</Text>
                <Button style={styles.button} text={props.buttonText} press={props.joinChannel} primary/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    containerModal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },

    containerAbsolute: {
        zIndex: 9,
        position: 'absolute',
        flex: 1,
        width: '100%',
        marginTop: '20%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerClose: {
        zIndex: 10,
        position: 'absolute',
        top: -10,
        right: width <= 420 ? '7%' : '24%',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 100,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        alignItems: 'center',
        width: width <= 420 ? '80%' : '50%',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    image: {
        width: '70%',
        height: width > 1270 ? 100 : 50,
        marginTop: 20
    },
    intro: {
        fontSize: 15,
        marginTop: 10
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10
    },
    button: {
        width: '90%',
        marginVertical: 30
    }
})
