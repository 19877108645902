import React, {useEffect, useState} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity, Text, Dimensions} from 'react-native';
import * as Colors from "../styles/Colors";
import {Course} from '../components/course.component'
import {Button} from '../components/button.component';
import {Loader} from '../components/loader.component';
import {getMyCourses} from '../services/data.service';
import {Link} from 'react-router-dom';

const {width} = Dimensions.get('window');

const CoursesScreen = props => {
    const [activeCourses, setActiveCourses] = useState(false);
    const [courses, setCourses] = useState(null);
    const [coursesNoCompleted, setCoursesNoCompleted] = useState(null);
    const [coursesCompleted, setCoursesCompleted] = useState(null);

    /*LLama a la funcion al entrar a la vista*/
    useEffect(() => {
        getAllCourses();
    }, []);

    /*Recoge los cursos que ya estas suscrito*/
    const getAllCourses = () => {
        getMyCourses().then(res => {
            console.log('Mis cursos recogidos con exito', res.data.courses);
            setCourses(res.data.courses);
        }).catch(err => {
            console.log('ERROR al recoger los cursos', err);
        })
    }

    /*Renderiza los cursos para verlos en la vista*/
    const renderCourses = (courseItem) => {
        return (
            <Link style={{width: width <= 420 ? '100%' : '33.3%', display: 'flex', justifyContent: 'center', marginBottom: 15, textDecoration: 'none'}} to={`/course/${courseItem.id}`} key={courseItem.id}>
                <Course
                    title={courseItem.name}
                    completed={courseItem.completed}
                    image={courseItem.course_image}
                    press={() => props.navigation.navigate('Course', { id: courseItem.id, name: courseItem.name })}
                    textButton={'Ver curso'}
                />
            </Link>
        )
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.container}>
                <View style={styles.containerTypes}>
                    <TouchableOpacity activeOpacity={.7} style={activeCourses === false ? styles.type : styles.typeNoSelect}
                                      onPress={() => setActiveCourses(false)}>
                        <Text style={activeCourses === false ? styles.typeText : styles.typeTextNoSelect}>
                            En marcha
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={.7} style={activeCourses === false ? styles.typeNoSelect : styles.type}
                                      onPress={() => setActiveCourses(true)}>
                        <Text style={activeCourses === false ? styles.typeTextNoSelect : styles.typeText}>
                            Completados
                        </Text>
                    </TouchableOpacity>
                </View>

                <ScrollView style={styles.scrollView}>
                    <View style={[{paddingBottom: 70, width: '100%'}, width > 420 && {flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}]}>
                        {courses === null ?
                            <Loader style={{flex: 1, height: 500}}/>
                            :
                            courses.length > 0 ?
                                activeCourses ?
                                    courses.map((course) => (course.completed >= 100) && renderCourses(course))
                                    :
                                    courses.map((course) => (course.completed < 100) && renderCourses(course))
                                :
                                <View style={{width: '90%'}}>
                                    <Text style={{textAlign: 'center'}}>No tienes cursos</Text>
                                    <Link style={{width: '100%',display: 'flex', justifyContent: 'center', marginTop: 20, textDecoration: 'none'}} to={'/searchCourses'}>
                                        <Button style={{width: '90%'}} text={'Buscar más cursos'} primary/>
                                    </Link>
                                </View>
                        }
                    </View>
                </ScrollView>

                {(courses !== null && courses.length > 0) && <Link style={{width: '100%', display: 'flex', justifyContent: 'center', textDecoration: 'none'}}
                       to={'/searchCourses'}>
                    <Button style={styles.btnSearchCourses} text={'Buscar más cursos'} primary/>
                </Link>}
            </View>

        </SafeAreaView>
    )
}

export default CoursesScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
    },
    containerTypes: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 50,
        backgroundColor: Colors.default.COLORS.background_light,
        shadowColor: "gray",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 10
    },
    type: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
        borderBottomWidth: 2,
        borderBottomColor: Colors.default.COLORS.primary
    },
    typeNoSelect: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
    },
    typeText: {
        color: Colors.default.COLORS.primary,
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    typeTextNoSelect: {
        color: Colors.default.COLORS.text_darkLight,
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    scrollView: {
        width: '100%',
        padding: 18,
    },
    containerFloatButton: {
        position: 'absolute',
        bottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'transparent'
    },
    btnSearchCourses: {
        //position: 'absolute',
        bottom: 70,
        width: '90%'
    }
})
